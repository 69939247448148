import UserSettingsForm from "pages/SettingsPage/UserSettingsForm";
import React from "react";
import { useTranslation } from "react-i18next";
import { PageContainer } from "styles/SharedStyle.styled";
import TabsComponent from "components/Tab/Tabs";
import ChangePassword from "./ChangePassword";
import { useUserProfileQuery } from "features/user/userApiSlice";

const ProfilePage = () => {
  const { t } = useTranslation();
  const { data: userData, isLoading: isLoadingUserData } =
    useUserProfileQuery();
  const tabs = [
    {
      label: t("profile.tabs.profileBasicInfo"),
      component: <UserSettingsForm userData={userData} isLoadingUserData={isLoadingUserData} />,
    },
    {
      label: t("profile.tabs.changePassword"),
      component: <ChangePassword userData={userData} isLoadingUserData={isLoadingUserData} />,
    },
  ];
  return (
    <PageContainer>
      <TabsComponent tabs={tabs} />
    </PageContainer>
  );
};

export default ProfilePage;
