import { useFormik } from "formik";
import agencySettingsInitialValues from "initialValues/agencySettingsInitialValues";
import React, { useEffect, useState } from "react";
import agencySettingsValidation from "validations/ptpAgencySettingsValidation";
import AgencyForm from "components/Forms/AgencyForm/AgencyForm";
import {
  useAddLogoMutation,
  useAddSignatureMutation,
  useAgencyDetailsQuery,
  useDeleteAgencyLogoMutation,
  useDeleteSignatureMutation,
  useEditAgencyMutation,
  useGetAgencyLogoQuery,
  useGetAgencySignatureQuery,
} from "features/agency/agencyApiSlice";
import { ContentContainer } from "styles/SharedStyle.styled";
import { convertDateToISO } from "util/dateHelpers";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import { useTranslation } from "react-i18next";
import { PTP_TYPE } from "constants/agencyType";

// eslint-disable-next-line react/prop-types
const AgencySettingsForm = ({ id }) => {
  const { t } = useTranslation();
  const [agencyType, setAgencyType] = useState(null);

  const { data, isLoading } = useAgencyDetailsQuery({ id });
  const { data: agencyLogo, isLoading: isLoadingAgencyLogo } =
    useGetAgencyLogoQuery({ agencyId: id });
  const { data: agencySignature, isLoading: isLoadingSignature } =
    useGetAgencySignatureQuery({ agencyId: id });
  const [editAgency, { isLoading: isLoadingUpdateAgency }] =
    useEditAgencyMutation();
  const [addLogo, {isLoading: isLoadingAddLogo}] = useAddLogoMutation();
  const [addSignature, {isLoading: isLoadingAddSignature}] = useAddSignatureMutation();
  const [deleteLogo, { isLoading: isLoadingDeleteLogo }] =
    useDeleteAgencyLogoMutation();
  const [deleteSignature, { isLoading: isLoadingDeleteSignature }] =
    useDeleteSignatureMutation();

  const [agencyLogoImage, setAgencyLogoImage] = useState(
    agencyLogo?.data !== null ? [agencyLogo?.data] : null
  );

  const [agencySignatureImage, setAgencySignatureImage] = useState(
    agencySignature?.data !== null ? [agencySignature?.data] : null
  );

  useEffect(() => {
    if (!isLoadingAgencyLogo) {
      setAgencyLogoImage(agencyLogo?.data !== null ? [agencyLogo?.data] : null);
    }
  }, [isLoadingAgencyLogo]);

  useEffect(() => {
    if (!isLoadingSignature) {
      setAgencySignatureImage(agencySignature?.data !== null ? [agencySignature?.data] : null);
    }
  }, [isLoadingSignature]);

  const handleAgencyLogoImage = (image) => {
    setAgencyLogoImage(image);
    addLogo({ id, data: image[0] })
      .unwrap()
      .then(() => {
        makeToastMessage(t("agency.editLogoSuccessMessage"));
      })
      .catch(() => makeErrorToastMessage(t("agency.editLogoErrorMessage")));
  };

  const handleSignatureImage = (image) => {
    setAgencySignatureImage(image);
    addSignature({ id, data: image[0] })
      .unwrap()
      .then(() => {
        makeToastMessage(t("agency.editSignatureSuccessMessage"));
      })
      .catch(() => makeErrorToastMessage(t("agency.editSignatureErrorMessage")));
  }

  const handleDeleteLogo = () => {
    deleteLogo({ agencyId: id })
      .unwrap()
      .then(() => {
        if (!isLoadingDeleteLogo) {
          makeToastMessage(t("agency.deleteLogoSuccessMessage"));
        }
      })
      .catch(() => makeErrorToastMessage(t("agency.deleteLogoErrorMessage")));
    setAgencyLogoImage(null);
  };

  const handleDeleteSignature = () => {
    deleteSignature({ agencyId: id })
      .unwrap()
      .then(() => {
        if (!isLoadingDeleteSignature) {
          makeToastMessage(t("agency.deleteSignatureSuccessMessage"));
        }
      })
      .catch(() => makeErrorToastMessage(t("agency.deleteSignatureErrorMessage")));
    setAgencySignatureImage(null);
  }

  const handleSubmit = async (values) => {
    editAgency({
      ...values,
      licenceNumberExpirationDate:
        agencyType === PTP_TYPE
          ? null
          : convertDateToISO(values.licenceNumberExpirationDate),
      licenceNumber: agencyType === PTP_TYPE ? null : values.licenceNumber,
    })
      .unwrap()
      .then(() => {
        if (!isLoadingUpdateAgency) {
          makeToastMessage(t("agency.editSuccessMessage"));
        }
      })
      .catch(() => makeErrorToastMessage(t("agency.editErrorMessage")));
  };

  const formik = useFormik({
    initialValues: isLoading ? agencySettingsInitialValues : data,
    validationSchema: agencySettingsValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    setAgencyType(formik.values.agencyTypeId);
  }, [formik]);

  return (
    <ContentContainer component="form" onSubmit={formik.handleSubmit}>
      <AgencyForm
        formik={formik}
        setAgencyLogoImage={handleAgencyLogoImage}
        setSignatureImage={handleSignatureImage}
        agencyLogoImage={agencyLogoImage}
        signatureImage={agencySignatureImage}
        isLoadingSignature={isLoadingSignature || isLoadingDeleteSignature || isLoadingAddSignature}
        isLoadingLogo={isLoadingAgencyLogo || isLoadingDeleteLogo || isLoadingAddLogo}
        handleDeleteLogo={handleDeleteLogo}
        handleDeleteSignature={handleDeleteSignature}
      />
    </ContentContainer>
  );
};

export default AgencySettingsForm;
