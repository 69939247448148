/* eslint-disable react/prop-types */
import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import CustomDialog from "../CustomDialog/CustomDialog";
import PasswordInput from "components/Inputs/PasswordInput";

const ResetPasswordModal = ({ open, handleClose, formik, handleCloseBtn }) => {
  const { t } = useTranslation();
  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      handleCloseBtn={handleCloseBtn}
      title="Izmena lozinke"
    >
      <Box component="form">
        <RowContainer>
          <PasswordInput
            name="password"
            label={t("field.passwordPlaceholder")}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            fullWidth
          />
        </RowContainer>

        <RowContainer>
          <PasswordInput
            name="confirmPassword"
            label={t("field.confirmPasswordPlaceholder")}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            fullWidth
          />
        </RowContainer>

        <RowContainer style={{ marginBottom: 0, justifyContent: "center" }}>
          <Button
            variant="outlined"
            onClick={handleCloseBtn}
            style={{ marginRight: "24px" }}
            color="primary"
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!formik.dirty}
            onClick={formik.handleSubmit}
          >
            {t("common.save")}
          </Button>
        </RowContainer>
      </Box>
    </CustomDialog>
  );
};

export default ResetPasswordModal;
