import React from "react";
import PropTypes from "prop-types";
import { HeaderProfileBarContainer } from "./HeaderProfileBar.styled";
import HeaderProfile from "./HeaderProfile/HeaderProfile";
import { selectCurrentToken } from "features/auth/authSlice";
import { useSelector } from "react-redux";

const HeaderProfileBar = () => {
  const token = useSelector(selectCurrentToken);
  
  return (
    <HeaderProfileBarContainer>
      {token && (
        <>
          <HeaderProfile />
        </>
      )}
    </HeaderProfileBarContainer>
  );
};

HeaderProfileBar.propTypes = {
  children: PropTypes.node,
};

export default HeaderProfileBar;