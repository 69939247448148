import { apiSlice } from "features/api/apiSlice";

export const serviceApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Service", "ServiceSingle"],
  endpoints: (builder) => ({
    serviceDropdown: builder.query({
      query: () => ({
        url: "/service/dropdown",
      }),
      providesTags: ["Service"],
    }),
    serviceList: builder.query({
      query: (args) => {
        const { page, rowsPerPage, search, sort } = args;
        return {
          url:
            "/service/list?" +
            new URLSearchParams({
              PageNumber: page + 1,
              RowsPerPage: rowsPerPage,
              Active: true,
              // Conditionally add to params object if keys exist
              ...(sort && { SortPropertyName: sort.name, Ascending: sort.direction === 'asc' ? true : false }),
              ...(search && { Name: search }),
              //   ...(sortDirection && { orderBy: sortDirection }),
            }),
        };
      },
      providesTags: ["Service"],
    }),
    serviceDetails: builder.query({
      query: ({ id }) => ({
        url: `/service/${id}`,
      }),
      providesTags: ["ServiceSingle"],
    }),
    addService: builder.mutation({
      query: (data) => ({
        url: "/service/add",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["Service"],
    }),
    editService: builder.mutation({
      query: (data) => ({
        url: "/service/edit",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["Service", "ServiceSingle"],
    }),
    deleteService: builder.mutation({
      query: (data) => ({
        url: "/service",
        method: "DELETE",
        body: { ...data },
      }),
      invalidatesTags: ["Service"],
    }),
  }),
});

export const {
  useServiceDropdownQuery,
  useServiceListQuery,
  useServiceDetailsQuery,
  useAddServiceMutation,
  useEditServiceMutation,
  useDeleteServiceMutation,
} = serviceApiSlice;
