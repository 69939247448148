/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import SelectInput from "components/Inputs/SelectInput";
import { useAccommodationDropdownQuery } from "features/accommodation/accommodationApiSlice";
import { useCategoryDropdownQuery } from "features/category/categoryApiSlice";
import { useOfferTypeDropdownQuery } from "features/offerType/offerTypeApiSlice";
import { useServiceDropdownQuery } from "features/service/serviceApiSlice";
import { useTransportationTypeDropdownQuery } from "features/transportationType/transportationTypeApiSlice";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";

const OfferFilter = ({ filterForm }) => {
  const { data: offerTypes } = useOfferTypeDropdownQuery();
  const { data: accommodations } = useAccommodationDropdownQuery();
  const { data: transportationTypes } = useTransportationTypeDropdownQuery();
  const { data: services } = useServiceDropdownQuery();
  const { data: categories } = useCategoryDropdownQuery();
  const { t } = useTranslation();

  const offerStatuses = [
    { value: true, text: t("dropdown.activeOffer") },
    { value: false, text: t("dropdown.inactiveOffer") },
  ];
  return (
    <Box component="form">
      <RowContainer>
        <SelectInput
          name="OfferTypeId"
          label={t("field.offerTypePlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.OfferTypeId}
          fullWidth
          style={{ marginRight: "24px" }}
          items={offerTypes}
        />
        <SelectInput
          name="AccommodationId"
          label={t("field.accommodationIdPlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.AccommodationId}
          fullWidth
          items={accommodations}
        />
      </RowContainer>
      <RowContainer>
        <SelectInput
          name="TransportationTypeId"
          label={t("field.transportationTypePlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.TransportationTypeId}
          fullWidth
          style={{ marginRight: "24px" }}
          items={transportationTypes}
        />
        <SelectInput
          name="ServiceId"
          label={t("field.serviceIdPlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.ServiceId}
          fullWidth
          items={services}
        />
      </RowContainer>
      <RowContainer>
        <SelectInput
          name="CategoryId"
          label={t("field.categoryIdPlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.CategoryId}
          fullWidth
          style={{ marginRight: "24px" }}
          items={categories}
        />
        <SelectInput
          name="Active"
          label={t("field.offerStatusPlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.Active}
          fullWidth
          items={offerStatuses}
          InputLabelProps={{
            shrink: filterForm.values.Active !== "",
          }}
        />
      </RowContainer>
    </Box>
  );
};

export default OfferFilter;
