/* eslint-disable react/prop-types */
import { Button, Dialog, Typography } from "@mui/material";
import React from "react";
import {
  DialogActionsContainer,
  DialogContainer,
  DialogMessageContainer,
} from "./ConfirmCloseDialog.styled";
import { useTranslation } from "react-i18next";

const ConfirmCloseDialog = ({
  open,
  handleClose,
  handleCancel,
  description,
}) => {
  const {t} = useTranslation()
  return (
    <Dialog
      PaperProps={{ sx: { mt: "-300px", verticalAlign: "top" } }}
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "653px",
          },
        },
      }}
    >
      <DialogContainer>
        <DialogMessageContainer>
          <Typography>{description}</Typography>
        </DialogMessageContainer>
        <DialogActionsContainer>
          <Button
            variant="contained"
            onClick={handleClose}
            style={{ marginRight: "24px" }}
          >
            {t('common.confirm')}
          </Button>
          <Button variant="outlined" onClick={handleCancel}>
            {t('common.cancel')}
          </Button>
        </DialogActionsContainer>
      </DialogContainer>
    </Dialog>
  );
};

export default ConfirmCloseDialog;
