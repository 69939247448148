/* eslint-disable react/prop-types */
import styled from "@emotion/styled";
import { TextField } from "@mui/material";
import React from "react";

const TextInputContainer = styled(TextField)`
  @media (max-width: 600px) {
    margin-bottom: 24px;
  }
`

const TextInput = ({
  name,
  value,
  onChange,
  label,
  type = "text",
  ...props
}) => {
  return (
    <TextInputContainer
      type={type}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      InputLabelProps={{
        shrink: value,
      }}
      {...props}
    />
  );
};

export default TextInput;
