import * as Yup from "yup";
import i18next from "i18next";

export default Yup.object().shape({
  name: Yup.string().required(i18next.t("field.agencyNameRequired")),
  agencyTypeId: Yup.number().required(i18next.t("field.agencyTypeIdRequired")),
  address: Yup.string().required(i18next.t("field.addressRequired")),
  locationId: Yup.string().required(i18next.t("field.locationIdRequired")),
  countryId: Yup.string().required(i18next.t("field.countryIdRequired")),
  email: Yup.string()
    .email(i18next.t("field.emailFormat"))
    .required(i18next.t("field.emailRequired")),
  // phoneNumber: Yup.string().required(i18next.t("field.phoneNumberRequired")),
});
