import { Button } from "@mui/material";
import Codebooks from "components/Codebooks/Codebooks";
import CustomTable from "components/CustomTable/CustomTable";
import Search from "components/Search/Search";
import Title from "components/Title/Title";
import {
  useAddCountryMutation,
  useCountryListQuery,
  useDeleteCountryMutation,
  useEditCountryMutation,
} from "features/country/countryApiSlice";
import { useFormik } from "formik";
import useCodebookTable from "hooks/tables/useCodebookTable";
import useDebounce from "hooks/useDebounceHook";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PageHeader, PageContainer } from "styles/SharedStyle.styled";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import * as Yup from "yup";

const CountryPage = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({name: 'name', direction: 'asc'})
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [editData, setEditData] = useState(null);
  const { columns } = useCodebookTable({
    setOpenDelete,
    setOpenEdit,
    setEditData,
    page,
    rowsPerPage,
  });

  const debouncedSearch = useDebounce(search);

  const { data: countries, isLoading } = useCountryListQuery({
    page,
    rowsPerPage,
    search: debouncedSearch,
    sort
  });
  const [addCountry, { isLoading: isLoadingAddCountry }] =
    useAddCountryMutation();

  const [editCountry, { isLoading: isLoadingEditCountry }] =
    useEditCountryMutation();

  const [deleteCountry, { isLoading: isLoadingDeleteCountry }] =
    useDeleteCountryMutation();

  const handleSubmit = (val) => {
    if (openAdd) {
      const { name } = val;
      addCountry({ name })
        .unwrap()
        .then(() => {
          if (!isLoadingAddCountry) {
            formik.resetForm();
            setOpenAdd(false);
            makeToastMessage(t("country.addSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("country.addErrorMessage")));
    }

    if (openEdit) {
      const { id, name } = val;

      editCountry({ name, id })
        .unwrap()
        .then(() => {
          if (!isLoadingEditCountry) {
            formik.resetForm();
            setOpenEdit(false);
            setEditData(null);
            makeToastMessage(t("country.editSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("country.editErrorMessage")));
    }
  };

  const handleConfirmDelete = () => {
    if (openDelete) {
      deleteCountry({ id: editData.id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteCountry) {
            setEditData(null);
            setOpenDelete(false);
            makeToastMessage(t("country.deleteSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("country.deleteErrorMessage")));
    }
  };

  const formik = useFormik({
    initialValues: openEdit ? editData : { name: "" },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("field.countryRequired")),
    }),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <PageContainer>
      <Codebooks
        formik={formik}
        editTitle={t("country.editTitle")}
        addTitle={t("country.addTitle")}
        labelTitle={t("field.countryNamePlaceholder")}
        confirmCloseDescription={t("country.confirmClose")}
        confirmDeleteDescription={t("country.confirmDelete")}
        setEditData={setEditData}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        openAdd={openAdd}
        setOpenAdd={setOpenAdd}
        handleConfirmDelete={handleConfirmDelete}
      />
      <Title title={t("country.pageTitle")} />
      <PageHeader>
        <Search label={t('country.searchPlaceholder')} handleChange={handleSearch} search={search} />
        <Button variant="contained" onClick={handleOpenAdd}>
          {t("country.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={countries?.data}
        isLoading={isLoading}
        count={countries?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsName={t("country.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default CountryPage;
