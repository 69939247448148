import * as Yup from "yup";
import i18next from "i18next";

export default Yup.object().shape({
  name: Yup.string().required(i18next.t("field.nameRequired")),
  contractNumber: Yup.string().required(i18next.t("field.contractNumberRequired")),
  description: Yup.string().required(i18next.t("field.descriptionRequired")),
  cashAmount: Yup.string(),
  cardAmount: Yup.string(),
  checkAmount: Yup.string(),
  nbsCourse: Yup.string().required(i18next.t("field.nbsCourseRequired")),
  nbsCurrency: Yup.string().required(i18next.t("field.nbsCurrencyRequired")),
  // restAmount: Yup.string().required(i18next.t("field.restAmountRequired")),
  date: Yup.date()
    .required(i18next.t("field.dateOfTermRequired"))
    .typeError(i18next.t("field.dateFormatTypeError")),
}).test(
  'at-least-one-amount',
  i18next.t("field.atLeastOneAmountRequired"),
  function(values) {
    if (values.cashAmount || values.cardAmount || values.checkAmount) {
      return true;
    }
    return this.createError({
      path: 'cashAmount', // You can set this to any of the three fields
      message: i18next.t("field.atLeastOneAmountRequired")
    });
  }
);
