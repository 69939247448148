/* eslint-disable react/prop-types */
import { Box, Button, IconButton } from "@mui/material";
import DatePick from "components/Inputs/DatePick";
import LocationSelect from "components/Inputs/LocationSelect";
import TextInput from "components/Inputs/TextInput";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {
  useClientDetailsQuery,
  useClientDropdownQuery,
} from "features/client/clientApiSlice";
import { useLocationDropdownQuery } from "features/location/locationApiSlice";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import PassengerSelect from "components/Inputs/PassengerSelect";

const PassengerForm = ({ formik }) => {
  const { t } = useTranslation();
  const [passengerId, setPassengerId] = useState(null);
  const [passengerIndex, setPassengerIndex] = useState(null);
  const [locations, setLocations] = useState([]);
  const { data: clients } = useClientDropdownQuery();

  const { data: passengersData } = useClientDetailsQuery(
    { id: passengerId },
    { skip: !passengerId }
  );

  const { data: passengerLocations, isLoading: isLoadingPassengersLocations } =
    useLocationDropdownQuery(
      { id: formik.values?.passengers?.[passengerIndex]?.passengerCountryId },
      {
        skip: !formik.values?.passengers?.[passengerIndex]?.passengerCountryId,
      }
    );

  useEffect(() => {
    if (passengersData || passengerIndex) {
      formik.setFieldValue(
        `passengers[${passengerIndex}].passengerAddress`,
        passengersData?.address
      );
      formik.setFieldValue(
        `passengers[${passengerIndex}].passengerCountryId`,
        passengersData?.countryId
      );
      formik.setFieldValue(
        `passengers[${passengerIndex}].passengerLocationId`,
        passengersData?.locationId
      );
      formik.setFieldValue(
        `passengers[${passengerIndex}].passengerPhoneNumber`,
        passengersData?.phoneNumber
      );
      formik.setFieldValue(
        `passengers[${passengerIndex}].passengerDateOfBirth`,
        passengersData?.dateOfBirth
      );
      formik.setFieldValue(
        `passengers[${passengerIndex}].passengerPassportNumber`,
        passengersData?.passportNumber
      );
    }
  }, [passengersData, passengerIndex]);

  useEffect(() => {
    if (passengerLocations && !isLoadingPassengersLocations) {
      setLocations((prev) => {
        if (!prev) {
          return passengerLocations;
        }
        return prev.concat(passengerLocations);
      });
    }
  }, [passengerLocations, isLoadingPassengersLocations]);
  return (
    <>
      {formik.values.passengers &&
        formik.values.passengers?.map((item, index) => (
          <RowContainer
            key={index}
            sx={{ display: "flex", alignItems: "flex-start" }}
          >
            <TextInput
              fullWidth
              label="Redni broj"
              value={index + 1}
              style={{ marginRight: "24px" }}
              disabled
            />
            <PassengerSelect
              fullWidth
              name="passengerName"
              label={t("field.passengerNamePlaceholderRequired")}
              items={clients}
              value={formik.values.passengers[index].passengerName}
              onChange={(e) => {
                formik.setFieldValue(
                  `passengers[${index}].passengerName`,
                  e.target.value
                );
                setPassengerId(e.target.value);
                setPassengerIndex(index);
              }}
              error={
                formik.touched.passengers?.[index]?.passengerName &&
                Boolean(formik.errors.passengers?.[index]?.passengerName)
              }
              helperText={
                formik.touched.passengers?.[index]?.passengerName &&
                formik.errors.passengers?.[index]?.passengerName
              }
              style={{ marginRight: "24px" }}
            />
            <TextInput
              fullWidth
              name="passengerAddress"
              label={t("field.addressPlaceholder")}
              value={formik.values.passengers[index].passengerAddress}
              style={{ marginRight: "24px" }}
              disabled
            />
            <LocationSelect
              fullWidth
              name="passengerLocationId"
              label={t("field.locationIdPlaceholder")}
              value={formik.values?.passengers[index]?.passengerLocationId}
              items={locations}
              countryId={
                formik.values.passengers[index]?.passengerCountryId || ""
              }
              disabled
              style={{ marginRight: "24px" }}
            />
            <TextInput
              fullWidth
              name="passengerPhoneNumber"
              label={t("field.phoneNumberPlaceholder")}
              value={
                formik?.values.passengers[index]?.passengerPhoneNumber || ""
              }
              style={{ marginRight: "24px" }}
              disabled
            />
            <DatePick
              name="passengerDateOfBirth"
              label={t("field.dateOfBirthPlaceholder")}
              value={
                new Date(
                  formik?.values.passengers[index]?.passengerDateOfBirth
                ) || null
              }
              onChange={(value) =>
                formik.setFieldValue("dateOfBirth", value, true)
              }
              disabled
              error={
                formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)
              }
              helperText={
                formik.touched.dateOfBirth && formik.errors.dateOfBirth
              }
              style={{ marginRight: "24px" }}
            />
            <TextInput
              fullWidth
              name="passengerPassportNumber"
              label={t("field.passportNumberPlaceholder")}
              value={
                formik?.values.passengers[index]?.passengerPassportNumber || ""
              }
              style={{ marginRight: "24px" }}
              disabled
            />
            <TextInput
              fullWidth
              name="departurePlace"
              label={t("field.departurePlacePlaceholder")}
              onChange={(e) => {
                formik.setFieldValue(
                  `passengers[${index}].departurePlace`,
                  e.target.value
                );
              }}
              value={formik?.values.passengers[index]?.departurePlace || ""}
              style={{ marginRight: "24px" }}
              error={
                formik.touched.passengers?.[index]?.departurePlace &&
                Boolean(formik.errors.passengers?.[index]?.departurePlace)
              }
              helperText={
                formik.touched.passengers?.[index]?.departurePlace &&
                formik.errors.passengers?.[index]?.departurePlace
              }
            />
            <IconButton
              color="error"
              onClick={() => {
                if (formik.values.passengers.length > 1) {
                  formik.setFieldValue(
                    "passengers",
                    formik.values.passengers.filter((_, i) => i !== index) // Remove the item
                  );
                } else {
                  formik.setFieldValue("passengers", [
                    {
                      passengerName: "",
                      passengerAddress: "",
                      passengerCountryId: "",
                      passengerLocationId: "",
                      passengerPhoneNumber: "",
                      passengerDateOfBirth: "",
                      passengerPassportNumber: "",
                      departurePlace: "",
                    },
                  ]);
                }
              }}
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
          </RowContainer>
        ))}
      <Box style={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() =>
            formik.setFieldValue("passengers", [
              ...formik.values.passengers,
              {
                passengerName: "",
                passengerAddress: "",
                passengerCountryId: "",
                passengerLocationId: "",
                passengerPhoneNumber: "",
                passengerDateOfBirth: "",
                passengerPassportNumber: "",
                departurePlace: "",
              },
            ])
          }
        >
          {t("singleContract.addPassenger")}
        </Button>
      </Box>
    </>
  );
};

export default PassengerForm;
