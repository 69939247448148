import { apiSlice } from "features/api/apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["User", "UserSingle", "Profile"],
  endpoints: (builder) => ({
    userDetails: builder.query({
      query: ({ id }) => ({
        url: `/user/${id}`,
      }),
      providesTags: ["UserSingle"],
    }),
    userBasicProfile: builder.query({
      query: () => ({
        url: `/user/get-basic-profile`,
      }),
      providesTags: ["Profile"],
    }),
    userProfile: builder.query({
      query: () => ({
        url: `/user/get-profile`,
      }),
      providesTags: ["Profile"],
    }),
    userList: builder.query({
      query: (args) => {
        const { page, rowsPerPage, agencyId, sort, filter } = args;
        return {
          url:
            "/user/list?" +
            new URLSearchParams({
              AgencyId: agencyId,
              PageNumber: page + 1,
              RowsPerPage: rowsPerPage,
              ...(filter && filter),
              // Conditionally add to params object if keys exist
                 ...(sort && { SortPropertyName: sort.name, Ascending: sort.direction === 'asc' ? true : false }),
              //   ...(sortDirection && { orderBy: sortDirection }),
              //...(search && { Name: search }),
            }),
        };
      },
      providesTags: ["User"],
    }),
    addUser: builder.mutation({
      query: (data) => ({
        url: "/user/add",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ['User']
    }),
    editUser: builder.mutation({
      query: (data) => ({
        url: "/user/edit",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ['User', 'UserSingle', "Profile"]
    }),
    userRoles: builder.query({
      query: () => ({
        url: `/user/roles`,
      }),
    }),
    editPassword: builder.mutation({
      query: (data) => ({
        url: "/user/edit-password",
        method: "PUT",
        body: data,
      })
    }),
    toggleStatusUser: builder.mutation({
      query: (data) => ({
        url: "/user/toggle-status",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ['User']
    }),
    deleteUser: builder.mutation({
      query: (data) => ({
        url: "/user",
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ['User']
    }),
  }),
});

export const {
  useUserDetailsQuery,
  useUserBasicProfileQuery,
  useUserProfileQuery,
  useUserListQuery,
  useAddUserMutation,
  useEditUserMutation,
  useUserRolesQuery,
  useEditPasswordMutation,
  useToggleStatusUserMutation,
  useDeleteUserMutation
} = userApiSlice;
