/* eslint-disable react/prop-types */
import TextInput from "components/Inputs/TextInput";
import React from "react";
import { RowContainer } from "styles/SharedStyle.styled";

const CalculationForm = ({ formik }) => {
  return (
    <>
      <RowContainer>
        <TextInput
          fullWidth
          label="R. broj*"
          value={1}
          style={{ marginRight: "24px" }}
        />
        <TextInput
          fullWidth
          name={"calculationService"}
          onChange={formik.handleChange}
          label={"Vrsta usluge*"}
          value={formik.values?.calculationService}
          style={{ marginRight: "24px" }}
          error={
            formik.touched.calculationService &&
            Boolean(formik.errors.calculationService)
          }
          helperText={
            formik.touched.calculationService &&
            formik.errors.calculationService
          }
        />
        <TextInput
          fullWidth
          name={"calculationQuantity"}
          type="number"
          onChange={formik.handleChange}
          label={"Količina*"}
          value={formik.values.calculationQuantity}
          style={{ marginRight: "24px" }}
          error={
            formik.touched.calculationQuantity &&
            Boolean(formik.errors.calculationQuantity)
          }
          helperText={
            formik.touched.calculationQuantity &&
            formik.errors.calculationQuantity
          }
        />
        <TextInput
          fullWidth
          type="number"
          step="any"
          name={"calculationPriceDin"}
          onChange={formik.handleChange}
          label={"Cena dinarski deo*"}
          value={formik.values.calculationPriceDin}
          style={{ marginRight: "24px" }}
          error={
            formik.touched.calculationPriceDin &&
            Boolean(formik.errors.calculationPriceDin)
          }
          helperText={
            formik.touched.calculationPriceDin &&
            formik.errors.calculationPriceDin
          }
        />
        <TextInput
          fullWidth
          name={"calculationPriceEur"}
          type="number"
          step="any"
          onChange={formik.handleChange}
          label={"Cena devizni deo*"}
          value={formik.values.calculationPriceEur}
          style={{ marginRight: "24px" }}
          error={
            formik.touched.calculationPriceEur &&
            Boolean(formik.errors.calculationPriceEur)
          }
          helperText={
            formik.touched.calculationPriceEur &&
            formik.errors.calculationPriceEur
          }
        />
        <TextInput
          fullWidth
          name={"calculationSubTotal"}
          type="number"
          step="any"
          onChange={formik.handleChange}
          label={"Ukupno*"}
          value={formik.values.calculationSubTotal}
          style={{ marginRight: "24px" }}
          error={
            formik.touched.calculationSubTotal &&
            Boolean(formik.errors.calculationSubTotal)
          }
          helperText={
            formik.touched.calculationSubTotal &&
            formik.errors.calculationSubTotal
          }
        />
      </RowContainer>
      <RowContainer>
        <TextInput
          hiddenLabel
          fullWidth
          type="hidden"
          style={{
            marginLeft: "24px",
            visibility: "hidden",
            height: "100%",
            width: "100%",
          }}
        />
        <TextInput
          hiddenLabel
          fullWidth
          type="hidden"
          style={{
            marginLeft: "24px",
            visibility: "hidden",
            height: "100%",
            width: "100%",
          }}
        />
        <TextInput
          hiddenLabel
          fullWidth
          type="hidden"
          style={{
            marginLeft: "24px",
            visibility: "hidden",
            height: "100%",
            width: "100%",
          }}
        />
        <TextInput
          fullWidth
          name={"calculationSaleDin"}
          type="number"
          step="any"
          onChange={formik.handleChange}
          label={"Popust na dinarski deo*"}
          value={formik.values.calculationSaleDin}
          style={{ marginRight: "24px" }}
          error={
            formik.touched.calculationSaleDin &&
            Boolean(formik.errors.calculationSaleDin)
          }
          helperText={
            formik.touched.calculationSaleDin &&
            formik.errors.calculationSaleDin
          }
        />
        <TextInput
          fullWidth
          name={"calculationSaleEur"}
          type="number"
          step="any"
          onChange={formik.handleChange}
          label={"Popust na devizni deo*"}
          value={formik.values.calculationSaleEur}
          style={{ marginRight: "24px" }}
          error={
            formik.touched.calculationSaleEur &&
            Boolean(formik.errors.calculationSaleEur)
          }
          helperText={
            formik.touched.calculationSaleEur &&
            formik.errors.calculationSaleEur
          }
        />
        <TextInput
          fullWidth
          name={"calculationTotal"}
          type="number"
          step="any"
          onChange={formik.handleChange}
          label={"Iznos za uplatu*"}
          value={formik.values.calculationTotal}
          style={{ marginRight: "24px" }}
          error={
            formik.touched.calculationTotal &&
            Boolean(formik.errors.calculationTotal)
          }
          helperText={
            formik.touched.calculationTotal && formik.errors.calculationTotal
          }
        />
      </RowContainer>
    </>
  );
};

export default CalculationForm;
