import * as Yup from "yup";
import i18next from "i18next";

export default Yup.object().shape({
  accommodationId: Yup.string().required(
    i18next.t("field.accommodationIdRequired")
  ),
  categoryId: Yup.string().required(i18next.t("field.categoryIdRequired")),
  serviceId: Yup.string().required(i18next.t("field.serviceIdRequired")),
  locationId: Yup.string().required(i18next.t("field.locationIdRequired")),
  countryId: Yup.string().required(i18next.t("field.countryIdRequired")),
  offerTypeId: Yup.string().required(i18next.t("field.offerTypeIdRequired")),
  name: Yup.string().required(i18next.t("field.offerNameRequired")),
  number: Yup.string().required(i18next.t("field.offerNumberRequired")),
  accommodationTypeId: Yup.string().required(
    i18next.t("field.accommodationTypeIdRequired")
  ),
  accommodationUnitTypeId: Yup.string().required(
    i18next.t("field.accommodationUnitTypeIdRequired")
  ),
});
