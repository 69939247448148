import { Box } from "@mui/material";
import styled from "styled-components";
// Replace with styled.img when there is logo
export const MainContainer = styled(Box)`
 background-color: #FCFCFD;
 display: flex;
`;

export const ContentContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: all 0.5s ease;
`
