/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";
import { useAgencyTypeQuery } from "features/agency-type/agencyTypeApiSlice";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";

const PartnerFilter = ({ filterForm }) => {
  const { t } = useTranslation();
  const { data: agencyTypes } =
    useAgencyTypeQuery();
  return (
    <Box component="form">
      <RowContainer>
        <TextInput
          fullWidth
          name="Name"
          label={t("field.partnerPlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.Name}
        />
      </RowContainer>
      <RowContainer>
        <SelectInput
          fullWidth
          name="AgencyTypeId"
          label={t("field.partnerTypePlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.AgencyTypeId}
          items={agencyTypes}
        />
      </RowContainer>
    </Box>
  );
};

export default PartnerFilter;
