import { apiSlice } from "features/api/apiSlice";

export const offerTypeApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["OfferType", "OfferTypeSingle"],
  endpoints: (builder) => ({
    offerTypeDropdown: builder.query({
      query: () => ({
        url: "/offer-type/dropdown",
      }),
      providesTags: ["OfferType"],
    }),
    offerTypeDetailsLevelDropdown: builder.query({
      query: () => ({
        url: "/offer-type/details-level",
      }),
    }),
    offerTypeList: builder.query({
      query: (args) => {
        const { page, rowsPerPage, search, sort } = args;
        return {
          url:
            "/offer-type/list?" +
            new URLSearchParams({
              PageNumber: page + 1,
              RowsPerPage: rowsPerPage,
              Active: true,
              // Conditionally add to params object if keys exist
              ...(sort && { SortPropertyName: sort.name, Ascending: sort.direction === 'asc' ? true : false }),
              ...(search && { Name: search }),
              //   ...(sortDirection && { orderBy: sortDirection }),
            }),
        };
      },
      providesTags: ["OfferType"],
    }),
    offerTypeDetails: builder.query({
      query: ({ id }) => ({
        url: `/offer-type/${id}`,
      }),
      providesTags: ["OfferTypeSingle"],
    }),
    addOfferType: builder.mutation({
      query: (data) => ({
        url: "/offer-type/add",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["OfferType"],
    }),
    editOfferType: builder.mutation({
      query: (data) => ({
        url: "/offer-type/edit",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["OfferType", "OfferTypeSingle"],
    }),
    deleteOfferType: builder.mutation({
      query: (data) => ({
        url: "/offer-type",
        method: "DELETE",
        body: { ...data },
      }),
      invalidatesTags: ["OfferType"],
    }),
  }),
});

export const {
  useOfferTypeDropdownQuery,
  useOfferTypeDetailsLevelDropdownQuery,
  useOfferTypeListQuery,
  useOfferTypeDetailsQuery,
  useAddOfferTypeMutation,
  useEditOfferTypeMutation,
  useDeleteOfferTypeMutation,
} = offerTypeApiSlice;
