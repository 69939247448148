/* eslint-disable react/prop-types */
import { Box, Button, Divider } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomDialog from "../CustomDialog/CustomDialog";
import { RowContainer } from "styles/SharedStyle.styled";
import TextInput from "components/Inputs/TextInput";
import SelectInput from "components/Inputs/SelectInput";
import { useCategoryDropdownQuery } from "features/category/categoryApiSlice";
import { useOfferTypeDropdownQuery } from "features/offerType/offerTypeApiSlice";
import { useAccommodationTypeDropdownQuery } from "features/accommodationType/accommodationTypeApiSlice";
import { useAccommodationDropdownQuery } from "features/accommodation/accommodationApiSlice";
import CountrySelect from "components/Inputs/CountrySelect";
import LocationSelect from "components/Inputs/LocationSelect";
import { useCountryDropdownQuery } from "features/country/countryApiSlice";
import { useLocationDropdownQuery } from "features/location/locationApiSlice";
import { useServiceDropdownQuery } from "features/service/serviceApiSlice";
import { useTransportationTypeDropdownQuery } from "features/transportationType/transportationTypeApiSlice";
import { useAccommodationUnitTypeDropdownQuery } from "features/accommodationUnitType/accommodationUnitTypeApiSlice";
import { useAgencyDropdownQuery } from "features/agency/agencyApiSlice";
import {
  ACCOMMODATION,
  ARRANGEMENT,
  TRANSPORT,
} from "constants/offerDetailsLevel";

const AddOfferModal = ({
  open,
  handleClose,
  formik,
  handleCloseBtn,
  title,
  offerDetailsLevelId,
}) => {
  const { t } = useTranslation();

  const { data: categories, isLoading: isLoadingCategories } =
    useCategoryDropdownQuery({}, { skip: !open });
  const { data: offerTypes, isLoading: isLoadingOfferTypes } =
    useOfferTypeDropdownQuery({}, { skip: !open });
  const { data: accommodationTypes, isLoading: isLoadingAccommodationTypes } =
    useAccommodationTypeDropdownQuery({}, { skip: !open });
  const { data: accommodations, isLoading: isLoadingAccommodations } =
    useAccommodationDropdownQuery({}, { skip: !open });
  const { data: countries } = useCountryDropdownQuery({}, { skip: !open });
  const { data: locations } = useLocationDropdownQuery(
    { id: formik.values.countryId },
    { skip: !open || !formik.values?.countryId }
  );
  const { data: services } = useServiceDropdownQuery({}, { skip: !open });
  const { data: transportationTypes } = useTransportationTypeDropdownQuery(
    {},
    { skip: !open }
  );
  const { data: accommodationUnitTypes } =
    useAccommodationUnitTypeDropdownQuery({}, { skip: !open });
  const { data: agencies } = useAgencyDropdownQuery({}, { skip: !open });

  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      handleCloseBtn={handleCloseBtn}
      title={title}
      maxWidth="lg"
    >
      <Box component="form">
        <RowContainer>
          <TextInput
            fullWidth
            name="name"
            label={t("field.offerNamePlaceholderRequired")}
            value={formik?.values?.name || ""}
            onChange={formik?.handleChange}
            error={formik?.touched?.name && Boolean(formik?.errors?.name)}
            helperText={formik?.touched?.name && formik?.errors?.name}
            style={{ marginRight: "24px" }}
          />
          <TextInput
            fullWidth
            name="number"
            label={t("field.offerNumberPlaceholderRequired")}
            value={formik.values?.number || ""}
            onChange={formik.handleChange}
            error={formik.touched.number && Boolean(formik.errors.number)}
            helperText={formik.touched.number && formik.errors.number}
          />
        </RowContainer>
        <RowContainer>
          <SelectInput
            fullWidth
            name="travelOrganizerId"
            label={t("field.travelOrganizerPlaceholderRequired")}
            value={formik.values?.travelOrganizerId}
            onChange={formik.handleChange}
            error={
              formik.touched.travelOrganizerId &&
              Boolean(formik.errors.travelOrganizerId)
            }
            helperText={
              formik.touched.travelOrganizerId &&
              formik.errors.travelOrganizerId
            }
            style={{ marginRight: "24px" }}
            items={agencies}
            disabled
          />
          <TextInput
            fullWidth
            name="jid"
            label={t("field.jidPlaceholder")}
            value={formik.values?.jid || ""}
            onChange={formik.handleChange}
            error={formik.touched.jid && Boolean(formik.errors.jid)}
            helperText={formik.touched.jid && formik.errors.jid}
          />
        </RowContainer>

        <Divider style={{ marginBottom: 24 }} />

        <RowContainer>
          <SelectInput
            fullWidth
            name="categoryId"
            label={t("field.categoryPlaceholderRequired")}
            onChange={formik.handleChange}
            value={formik.values?.categoryId || ""}
            items={!isLoadingCategories && categories}
            style={{ marginRight: "24px" }}
            error={
              formik.touched.categoryId && Boolean(formik.errors.categoryId)
            }
            helperText={formik.touched.categoryId && formik.errors.categoryId}
            notched={Boolean(formik.values?.categoryId)}
          />

          <SelectInput
            fullWidth
            name="offerTypeId"
            label={t("field.offerTypePlaceholderRequired")}
            onChange={formik.handleChange}
            value={formik.values?.offerTypeId || ""}
            items={!isLoadingOfferTypes && offerTypes}
            error={
              formik.touched.offerTypeId && Boolean(formik.errors.offerTypeId)
            }
            helperText={formik.touched.offerTypeId && formik.errors.offerTypeId}
            notched={Boolean(formik.values?.offerTypeId)}
          />
        </RowContainer>

        <RowContainer>
          <CountrySelect
            fullWidth
            name="countryId"
            label={t("field.countryIdPlaceholder")}
            onChange={formik.handleChange}
            value={formik.values?.countryId}
            error={formik.touched.countryId && Boolean(formik.errors.countryId)}
            helperText={formik.touched.countryId && formik.errors.countryId}
            style={{ marginRight: "24px" }}
            items={countries}
          />
          <LocationSelect
            fullWidth
            name="locationId"
            disabled={!formik.values?.countryId}
            label={t("field.locationIdPlaceholder")}
            onChange={formik.handleChange}
            value={formik.values?.locationId}
            error={
              formik.touched.locationId && Boolean(formik.errors.locationId)
            }
            helperText={formik.touched.locationId && formik.errors.locationId}
            items={locations}
            countryId={formik.values.countryId}
          />
        </RowContainer>

        {(offerDetailsLevelId === ARRANGEMENT ||
          offerDetailsLevelId === ACCOMMODATION) && (
          <RowContainer>
            <SelectInput
              fullWidth
              name="accommodationTypeId"
              label={t("field.accommodationTypeIdPlaceholder")}
              onChange={formik.handleChange}
              value={formik.values?.accommodationTypeId || ""}
              items={!isLoadingAccommodationTypes && accommodationTypes}
              error={
                formik.touched.accommodationTypeId &&
                Boolean(formik.errors.accommodationTypeId)
              }
              helperText={
                formik.touched.accommodationTypeId &&
                formik.errors.accommodationTypeId
              }
              style={{ marginRight: "24px" }}
            />
            <SelectInput
              fullWidth
              name="accommodationId"
              label={t("field.accommodationIdPlaceholderRequired")}
              onChange={formik.handleChange}
              value={formik.values?.accommodationId || ""}
              items={!isLoadingAccommodations && accommodations}
              error={
                formik.touched.accommodationId &&
                Boolean(formik.errors.accommodationId)
              }
              helperText={
                formik.touched.accommodationId && formik.errors.accommodationId
              }
            />
          </RowContainer>
        )}

        {(offerDetailsLevelId === ARRANGEMENT ||
          offerDetailsLevelId === ACCOMMODATION) && (
          <RowContainer>
            <SelectInput
              fullWidth
              name="accommodationUnitTypeId"
              label={t("field.accommodationUnitTypeRequiredPlaceholder")}
              onChange={formik.handleChange}
              value={formik.values?.accommodationUnitTypeId || ""}
              items={accommodationUnitTypes}
              error={
                formik.touched.accommodationUnitTypeId &&
                Boolean(formik.errors.accommodationUnitTypeId)
              }
              helperText={
                formik.touched.accommodationUnitTypeId &&
                formik.errors.accommodationUnitTypeId
              }
              style={{ marginRight: "24px" }}
            />
            <SelectInput
              fullWidth
              name="serviceId"
              label={t("field.serviceIdPlaceholderRequired")}
              onChange={formik.handleChange}
              value={formik.values?.serviceId || ""}
              items={services}
              error={
                formik.touched.serviceId && Boolean(formik.errors.serviceId)
              }
              helperText={formik.touched.serviceId && formik.errors.serviceId}
            />
            
          </RowContainer>
        )}

        {(offerDetailsLevelId === ARRANGEMENT ||
          offerDetailsLevelId === TRANSPORT) && (
          <RowContainer>
            <SelectInput
              fullWidth
              name="transportationTypeId"
              label={t("field.transportatopnTypeIdPlaceholderRequired")}
              onChange={formik.handleChange}
              value={formik.values?.transportationTypeId || ""}
              items={transportationTypes}
              error={
                formik.touched.transportationTypeId &&
                Boolean(formik.errors.transportationTypeId)
              }
              helperText={
                formik.touched.transportationTypeId &&
                formik.errors.transportationTypeId
              }
              style={{ marginRight: "24px" }}
            />
            <Box style={{width: '100%'}}></Box>
          </RowContainer>
        )}

        <Divider style={{ marginBottom: 24 }} />

        <RowContainer>
          <SelectInput
            fullWidth
            name="priceId"
            label={"Cenovnik*"}
            onChange={formik.handleChange}
            value={formik.values?.accommodationUnitTypeId || ""}
            //items={accommodationUnitTypes}
          />
        </RowContainer>

        <Divider style={{ marginBottom: 24 }} />

        <RowContainer>
          <TextInput
            fullWidth
            multiline
            minRows={4}
            maxRows={8}
            name="travelProgram"
            label={t("field.travelProgramPlaceholder")}
            value={formik?.values?.travelProgram || ""}
            onChange={formik.handleChange}
            style={{ marginRight: "24px" }}
          />

          <TextInput
            fullWidth
            multiline
            minRows={4}
            maxRows={8}
            name="description"
            label={t("field.descriptionPlaceholder")}
            value={formik?.values?.description || ""}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />
        </RowContainer>

        <RowContainer style={{ marginBottom: 0, justifyContent: "center" }}>
          <Button
            variant="outlined"
            onClick={handleCloseBtn}
            style={{ marginRight: "24px" }}
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!formik.dirty}
            onClick={formik.handleSubmit}
          >
            {t("common.save")}
          </Button>
        </RowContainer>
      </Box>
    </CustomDialog>
  );
};

export default AddOfferModal;
