/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";
import { useUserRolesQuery } from "features/user/userApiSlice";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";

const UserFilter = ({ filterForm }) => {
  const { data: userRoles } = useUserRolesQuery();
  const { t } = useTranslation();

  const userActive = [
    {
      value: true,
      text: t("common.userActive"),
    },
    {
      value: false,
      text: t("common.userInactive"),
    },
  ];

  return (
    <Box component="form">
      <RowContainer>
        <TextInput
          fullWidth
          name="Name"
          label={t("field.name")}
          style={{ marginRight: 24 }}
          onChange={filterForm.handleChange}
          value={filterForm.values.Name}
        />
        <TextInput
          fullWidth
          name="Surname"
          label={t("field.surname")}
          onChange={filterForm.handleChange}
          value={filterForm.values.Surname}
        />
      </RowContainer>
      <RowContainer>
        <SelectInput
          fullWidth
          name="Active"
          style={{ marginRight: 24 }}
          label={t("field.userStatus")}
          onChange={filterForm.handleChange}
          value={filterForm.values.Active}
          items={userActive}
          InputLabelProps={{
            shrink: filterForm.values.Active !== "",
          }}
        />
        <SelectInput
          fullWidth
          name="RoleId"
          label={t("field.roleIdPlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.UserRole}
          items={userRoles}
        />
      </RowContainer>
    </Box>
  );
};

export default UserFilter;
