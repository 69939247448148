import React, { useEffect, useState } from "react";
import CustomTable from "components/CustomTable/CustomTable";
import { Button } from "@mui/material";
import ConfirmCloseDialog from "components/Modals/ConfirmCloseDialog/ConfirmCloseDialog";
import AddAgencyFormModal from "components/Modals/AddAgencyModal/AddAgencyModal";
import { useFormik } from "formik";
import agencySettingsInitialValues from "initialValues/agencySettingsInitialValues";
import useAgencyTable from "hooks/tables/useAgencyTable";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import { useTranslation } from "react-i18next";
import {
  useAddAgencyMutation,
  useAgenciesListQuery,
  useToggleStatusAgencyMutation,
} from "features/agency/agencyApiSlice";
import { PageContainer, PageHeader } from "styles/SharedStyle.styled";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import { formatToIsoDate } from "util/dateHelpers";
import ptpAgencySettingsValidation from "validations/ptpAgencySettingsValidation";
import otpAgencyValidation from "validations/otpAgencyValidation";
import Filter from "components/Filter/Filter";
import AgencyFilter from "components/Filter/AgencyFilter/AgencyFilter";
import { getNonEmptyProps } from "util/helpers/objectHelpers";
import { FilterSearchContainer } from "./AdminPage.styled";

const AdminPage = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState(null);
  const [openAddAgency, setOpenAddAgency] = useState(false);
  const [closeConfirmOpen, setCloseConfirmOpen] = useState(false);
  const [agencyType, setAgencyType] = useState(null);
  const [filter, setFilter] = useState(null);

  const [agencyStatus, setAgencyStatus] = useState(null);

  const [openActivateDeactivateAgency, setOpenActivateDeactivateAgency] =
    useState(false);

  const { columns } = useAgencyTable({
    setOpenActivateDeactivateAgency,
    setAgencyStatus,
    page,
    rowsPerPage,
  });

  const [addAgency, { isLoading: isLoadingAddAgency }] = useAddAgencyMutation();

  const [toggleStatusAgency, { isLoading: isLoadingToggleStatus }] =
    useToggleStatusAgencyMutation();

  const { data: agencies, isLoading } = useAgenciesListQuery({
    page,
    rowsPerPage,
    sort,
    filter,
  });

  let newData = agencies?.data.map((obj) => ({
    ...obj,
    agencyActive: obj.agencyActive
      ? t("tableCols.agency.agencyStatusActive")
      : t("tableCols.agency.agencyStatusInactive"),
  }));

  const handleSubmit = (val) => {
    addAgency({
      ...val,
      licenceNumberExpirationDate:
        agencyType === 1
          ? null
          : formatToIsoDate(val.licenceNumberExpirationDate),
      licenceNumber: agencyType === 1 ? null : val.licenceNumber,
    })
      .unwrap()
      .then(() => {
        if (!isLoadingAddAgency) {
          formik.resetForm();
          setOpenAddAgency(false);
          makeToastMessage(t("agency.addSuccessMessage"));
        }
      })
      .catch(() => makeErrorToastMessage("agency.addErrorMessage"));
  };

  const handleFilter = (values) => {
    setPage(0);
    setFilter(getNonEmptyProps(values));
  };

  const formik = useFormik({
    initialValues: agencySettingsInitialValues,
    validationSchema:
      agencyType === 1 ? ptpAgencySettingsValidation : otpAgencyValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const filterForm = useFormik({
    initialValues: { Active: "", AgencyTypeId: "", Name: "" },
    onSubmit: handleFilter,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    setAgencyType(formik.values.agencyTypeId);
  }, [formik]);

  const handleCloseAddAgency = (_event, action) => {
    if (action === "backdropClick" || action === "escapeKeyDown") {
      if (formik.dirty) {
        setCloseConfirmOpen(true);
      } else {
        setOpenAddAgency(false);
      }
    }
  };

  const handleCloseConfirmDialog = () => {
    setOpenAddAgency(false);
    setCloseConfirmOpen(false);
    formik.resetForm();
  };

  const handleOpenAddAgency = () => {
    setOpenAddAgency(true);
  };

  const handleCancel = () => {
    setCloseConfirmOpen(false);
  };

  const handleCloseBtn = () => {
    if (formik.dirty) {
      setCloseConfirmOpen(true);
    } else {
      setOpenAddAgency(false);
    }
  };

  const handleCancelConfirm = () => {
    setOpenActivateDeactivateAgency(false);
    setAgencyStatus(null);
  };

  const handleConfirm = () => {
    if (openActivateDeactivateAgency) {
      toggleStatusAgency({
        id: agencyStatus.id,
      })
        .unwrap()
        .then(() => {
          if (!isLoadingToggleStatus) {
            setAgencyStatus(null);
            setOpenActivateDeactivateAgency(false);
            makeToastMessage(t("agency.toggleSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("agency.toggleErrorMessage")));
    }
  };

  return (
    <PageContainer>
      <ConfirmCloseDialog
        open={closeConfirmOpen}
        handleClose={handleCloseConfirmDialog}
        handleCancel={handleCancel}
        description={t("agency.confirmClose")}
      />
      <AddAgencyFormModal
        open={openAddAgency}
        handleClose={handleCloseAddAgency}
        formik={formik}
        handleCloseBtn={handleCloseBtn}
      />
      <ConfirmModal
        open={openActivateDeactivateAgency}
        handleConfirm={handleConfirm}
        handleCancel={handleCancelConfirm}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        btnColor={openActivateDeactivateAgency && agencyStatus.agencyActive ===
              t("tableCols.agency.agencyStatusActive") ? "error" : 'primary'}
        description={t("agency.confirmAgencyStatus", {
          status:
            openActivateDeactivateAgency &&
            agencyStatus.agencyActive ===
              t("tableCols.agency.agencyStatusActive")
              ? t("agency.deactivateAgency")
              : t("agency.activateAgency"),
        })}
      />
      <PageHeader>
        <FilterSearchContainer>
          <Filter
            filterForm={filterForm}
            handleReset={() => {
              setFilter(null);
              filterForm.resetForm();
            }}
          >
            <AgencyFilter filterForm={filterForm} />
          </Filter>
        </FilterSearchContainer>
        <Button variant="contained" onClick={handleOpenAddAgency}>
          {t("agency.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={newData}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setSort={setSort}
        rowsName={t("agency.rowsName")}
        isLoading={isLoading}
        count={agencies?.totalCount}
      />
    </PageContainer>
  );
};

export default AdminPage;
