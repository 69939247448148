import { apiSlice } from "features/api/apiSlice";

export const receiptApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["receipt", "receiptSingle"],
  endpoints: (builder) => ({
    receiptDropdown: builder.query({
      query: () => ({
        url: "/receipt/dropdown",
      }),
      providesTags: ["receipt"],
    }),
    receiptList: builder.query({
      query: (args) => {
        const { page, rowsPerPage, search, sort, filter } = args;
        return {
          url:
            "/receipt/list?" +
            new URLSearchParams({
              PageNumber: page + 1,
              RowsPerPage: rowsPerPage,
              Active: true,
              // Conditionally add to params object if keys exist
              ...(filter && filter),
              ...(search && { Name: search }),
              ...(sort && { SortPropertyName: sort.name, Ascending: sort.direction === 'asc' ? true : false }),
            }),
        };
      },
      providesTags: ["receipt"],
    }),
    receiptDetails: builder.query({
      query: ({ id }) => ({
        url: `/receipt/${id}`,
      }),
      providesTags: ["receiptSingle"],
    }),
    addReceipt: builder.mutation({
      query: (data) => ({
        url: "/receipt/add",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["receipt"],
    }),
    editReceipt: builder.mutation({
      query: (data) => ({
        url: "/receipt/edit",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["receipt", "receiptSingle"],
    }),
    deleteReceipt: builder.mutation({
      query: (data) => ({
        url: "/receipt",
        method: "DELETE",
        body: { ...data },
      }),
      invalidatesTags: ["receipt"],
    }),
  }),
});

export const {
  useReceiptDropdownQuery,
  useReceiptListQuery,
  useReceiptDetailsQuery,
  useAddReceiptMutation,
  useEditReceiptMutation,
  useDeleteReceiptMutation,
} = receiptApiSlice;
