import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { arrayToObject } from "util/helpers/arrayToObject";
import CreateIcon from "@mui/icons-material/Create";
import LockResetIcon from "@mui/icons-material/LockReset";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CheckIcon from "@mui/icons-material/Check";
import useAuth from "hooks/useAuth";
import { format } from "date-fns";

const useUserTable = ({
  setEditData,
  setEditUserId,
  setOpenBlockUser,
  setOpenDeleteUser,
  setOpenEditUser,
  setOpenResetPassword,
  page,
  rowsPerPage,
}) => {
  const { isSuperAdmin, isManager } = useAuth();
  const { t } = useTranslation();
  const columns = [
    {
      name: "",
      label: t("tableCols.agency.ordinalNumberLabel"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { width: "100px" }}),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMetadata, updateValue) => {
          return tableMetadata.rowIndex + 1 + page * rowsPerPage;
        },
      },
    },
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "surname",
      label: t("tableCols.user.surnameLabel"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "name",
      label: t("tableCols.user.nameLabel"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "role",
      label: t("tableCols.user.roleLabel"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: t("tableCols.user.emailLabel"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "phoneNumber",
      label: t("tableCols.user.phoneNumberLabel"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "office",
      label: t("tableCols.user.agencyNameLabel"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "userActive",
      label: t("tableCols.user.statusLabel"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "updatedOn",
      label: t("tableCols.user.lastChangesLabel"),
      options: {
        filter: false,
        sort: false,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return format(new Date(rowData.updatedOn), "dd.MM.yyyy.");
        },
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        print: false,
        display: isSuperAdmin || isManager,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta, updateValue) => {
          const user = arrayToObject(tableMeta.rowData, columns);
          return (
            <Box style={{ display: "flex" }}>
              <Tooltip
                title={t("tableCols.user.tooltip.change")}
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    // eslint-disable-next-line no-unused-vars
                    const { id } = user;
                    setEditData(user);
                    setEditUserId(id);
                    setOpenEditUser(true);
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={t("tableCols.user.tooltip.resetPassword")}
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    const { id } = user;
                    setEditUserId(id);
                    setOpenResetPassword(true);
                  }}
                >
                  <LockResetIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  user?.userActive === t("tableCols.user.userStatusActive")
                    ? t("tableCols.user.tooltip.blockUser")
                    : t("tableCols.user.tooltip.activateUser")
                }
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    setEditData(user);
                    setOpenBlockUser(true);
                  }}
                >
                  {user?.userActive === t("tableCols.user.userStatusActive") ? <RemoveCircleIcon /> : <CheckIcon />}
                </IconButton>
              </Tooltip>
              <Tooltip
                title={t("tableCols.user.tooltip.deleteUser")}
                placement="top"
              >
                <IconButton
                  onClick={() => {
                    const { id } = user;
                    setEditUserId(id);
                    setOpenDeleteUser(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];
  return { columns };
};

export default useUserTable;
