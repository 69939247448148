import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      const { modalType, modalProps } = action.payload;
      state[modalType] = {
        open: true,
        props: modalProps,
      };
    },
    closeModal: (state, action) => {
      const { modalType } = action.payload;
      state[modalType] = {
        open: false,
        props: {},
      };
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;