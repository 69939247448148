/* eslint-disable no-unused-vars */
import { Typography } from "@mui/material";
import CustomTable from "components/CustomTable/CustomTable";
import ContractFilter from "components/Filter/ContractFilter/ContractFilter";
import Filter from "components/Filter/Filter";
import { useFormik } from "formik";
import useContractTable from "hooks/tables/useContractTable";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PageContainer, PageHeader } from "styles/SharedStyle.styled";
import { convertDateToISO } from "util/dateHelpers";
import { getNonEmptyProps } from "util/helpers/objectHelpers";

const ContractsPage = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState(null);
  const [filter, setFilter] = useState(null);
  const { columns } = useContractTable({
    page,
    rowsPerPage,
  });

  const handleFilter = (values) => {
    setPage(0);
    setFilter(getNonEmptyProps(values));
  };

  const contracts = [
    {
      id: 2044,
      number: 2433,
      date: "2024-09-25T01:00:00",
      saleType: "Prodaja",
      client: "Lazar Kostic",
      travelOrganizer: "Tremium",
      country: "Srbija",
      location: "Surdulica",
      departureDate: "2024-09-25T01:00:00",
    },
  ];

  const filterForm = useFormik({
    initialValues: {
      Number: "",
      TravelOrganizerId: "",
      OnDate: null,
      ClientId: "",
    },
    onSubmit: handleFilter,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <PageContainer>
      <Typography variant="h4" color="" style={{ marginBottom: 24 }}>
        {t("contract.pageTitle")}
      </Typography>
      <PageHeader>
        <Filter
          filterForm={filterForm}
          handleReset={() => {
            setFilter(null);
            filterForm.resetForm();
          }}
        >
          <ContractFilter filterForm={filterForm} />
        </Filter>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={contracts}
        isLoading={false}
        count={contracts.length}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsName={t("contract.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default ContractsPage;
