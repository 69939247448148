/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";
import { useCountryDropdownQuery } from "features/country/countryApiSlice";
import { useLocationDropdownQuery } from "features/location/locationApiSlice";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";

const ClientFilter = ({ filterForm }) => {
  const { t } = useTranslation();
  const { data: countries } = useCountryDropdownQuery();
  const { data: locations } = useLocationDropdownQuery(
    { id: filterForm.values.CountryId },
    { skip: !filterForm.values.CountryId }
  );

  return (
    <Box component="form">
      <RowContainer>
        <TextInput
          fullWidth
          name="Name"
          label={t("field.name")}
          style={{ marginRight: 24 }}
          onChange={filterForm.handleChange}
          value={filterForm.values.Name}
        />
        <TextInput
          fullWidth
          name="Surname"
          label={t("field.surname")}
          onChange={filterForm.handleChange}
          value={filterForm.values.Surname}
        />
      </RowContainer>
      <RowContainer>
      <SelectInput
            name="CountryId"
            label={t("field.countryPlaceholder")}
            onChange={filterForm.handleChange}
            value={filterForm.values.CountryId}
            fullWidth
            style={{ marginRight: "24px" }}
            error={filterForm.touched.CountryId && Boolean(filterForm.errors.CountryId)}
            helperText={filterForm.touched.CountryId && filterForm.errors.CountryId}
            items={countries}
            selectPlaceholder={t("dropdown.country")}
          />
          <SelectInput
            name="LocationId"
            label={t("field.locationPlaceholder")}
            onChange={filterForm.handleChange}
            fullWidth
            value={filterForm.values.LocationId}
            error={
              filterForm.touched.LocationId && Boolean(filterForm.errors.LocationId)
            }
            helperText={filterForm.touched.LocationId && filterForm.errors.LocationId}
            disabled={!filterForm.values.CountryId}
            items={locations}
            selectPlaceholder={t("dropdown.location")}
          />
      </RowContainer>
    </Box>
  );
};

export default ClientFilter;
