/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import TextInput from "components/Inputs/TextInput";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import DatePick from "components/Inputs/DatePick";

const ReceiptsFilter = ({ filterForm }) => {
  const { t } = useTranslation();

  return (
    <Box component="form">
      <RowContainer>
        <TextInput
          fullWidth
          name="ReceiptNumber"
          label={t("field.receiptNumber")}
          style={{ marginRight: 24 }}
          onChange={filterForm.handleChange}
          value={filterForm.values.ReceiptNumber}
        />
        <TextInput
          fullWidth
          name="ContractNumber"
          label={t("field.contractNumber")}
          onChange={filterForm.handleChange}
          value={filterForm.values.ContractNumber}
        />
      </RowContainer>
      <RowContainer>
       <Box style={{ marginRight: 24 }}>
        <DatePick
           name="DateFrom"
           label={t("field.dateFrom")}
           value={filterForm.values.DateFrom}
           onChange={(value) => filterForm.setFieldValue("DateFrom", value)}
          />
       </Box>
       <Box>
        <DatePick
          name="DateTo"
          label={t("field.dateTo")}
          value={filterForm.values.DateTo}
          onChange={(value) => filterForm.setFieldValue("DateTo", value)}
        />
       </Box>
      </RowContainer>
      <RowContainer>
        <TextInput
          fullWidth
          name="Client"
          label={t("field.client")}
          onChange={filterForm.handleChange}
          value={filterForm.values.Client}
        />
      </RowContainer>
    </Box>
  );
};

export default ReceiptsFilter;
