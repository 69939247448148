import { apiSlice } from "features/api/apiSlice";

export const agencyPartnerApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["AgencyPartner", "AgencyPartnerSingle"],
  endpoints: (builder) => ({
    agencyPartnerDropdown: builder.query({
      query: () => ({
        url: "/agency-partner/dropdown",
      }),
      providesTags: ["AgencyPartner"],
    }),
    agencyPartnerList: builder.query({
      query: (args) => {
        const { page, rowsPerPage, sort, filter } = args;
        return {
          url:
            "/agency-partner/list?" +
            new URLSearchParams({
              PageNumber: page + 1,
              RowsPerPage: rowsPerPage,
              Active: true,
              // Conditionally add to params object if keys exist
              ...(sort && { SortPropertyName: sort.name, Ascending: sort.direction === 'asc' ? true : false }),
              //   ...(sortDirection && { orderBy: sortDirection }),
              ...(filter && filter)
            }),
        };
      },
      providesTags: ["AgencyPartner"],
    }),
    agencyPartnerDetails: builder.query({
      query: ({ id }) => ({
        url: `/agency-partner/${id}`,
      }),
      providesTags: ["AgencyPartnerSingle"],
    }),
    createExistingPartner: builder.mutation({
      query: (data) => ({
        url: "/agency-partner/create-existing",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["AgencyPartner"],
    }),
    createExternalPartner: builder.mutation({
        query: (data) => ({
          url: "/agency-partner/create-external",
          method: "POST",
          body: { ...data },
        }),
        invalidatesTags: ["AgencyPartner"],
      }),
    edit: builder.mutation({
      query: (data) => ({
        url: "/agency-partner/edit",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["AgencyPartner", "AgencyPartnerSingle"],
    }),
    deleteExisting: builder.mutation({
      query: (data) => ({
        url: "/agency-partner/delete-existing",
        method: "DELETE",
        body: { ...data },
      }),
      invalidatesTags: ["AgencyPartner"],
    }),
    deleteExternal: builder.mutation({
        query: (data) => ({
          url: "/agency-partner/delete-external",
          method: "DELETE",
          body: { ...data },
        }),
        invalidatesTags: ["AgencyPartner"],
      }),
  }),
});

export const {
  useAgencyPartnerDropdownQuery,
  useAgencyPartnerListQuery,
  useAgencyPartnerDetailsQuery,
  useCreateExistingPartnerMutation,
  useCreateExternalPartnerMutation,
  useEditMutation,
  useDeleteExistingMutation,
  useDeleteExternalMutation
} = agencyPartnerApiSlice;
