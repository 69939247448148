import React from "react";

import { ReactComponent as MainIconSvg } from "../../../assets/images/svg/MasterOfTravelIcon.svg";

const MainIcon = (props) => {
  return <MainIconSvg {...props} />;
};

MainIcon.propTypes = {};

export default MainIcon;
