import React from "react";

const PaymentPage = () => {
  return (
    <>
      <p>Payment page</p>
    </>
  );
};

export default PaymentPage;