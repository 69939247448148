/* eslint-disable react/prop-types */
import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomDialog from "../CustomDialog/CustomDialog";
import { RowContainer } from "styles/SharedStyle.styled";
import { useLicenceStatusDropdownQuery } from "features/licenceStatus/licenceStatusApiSlice";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";
import DatePick from "components/Inputs/DatePick";

const AddLicenceFormModal = ({
  open,
  handleClose,
  formik,
  handleCloseBtn,
  edit = false,
  title,
}) => {
  const { t } = useTranslation();
  const { data: licenceStatuses } = useLicenceStatusDropdownQuery(
    {},
    { skip: !open }
  );

  const formatedValues = {
    startDate: edit ? new Date(formik.values.startDate) : null,
    endDate: edit ? new Date(formik.values.endDate) : null,
  };

  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      handleCloseBtn={handleCloseBtn}
      title={title}
    >
      <Box component="form">
        {edit && (
          <RowContainer>
            <TextInput
              fullWidth
              disabled
              name="number"
              // label={t("field.systemLicenceNumberPlaceholder")}
              //onChange={formik.handleChange}
              value={formik.values.number}
            />
          </RowContainer>
        )}
        <RowContainer>
          <DatePick
            name="startDate"
            label={t("field.systemLicenceStartDatePlaceholder")}
            value={formatedValues.startDate}
            onChange={(value) => formik.setFieldValue("startDate", value, true)}
            error={formik.touched.startDate && Boolean(formik.errors.startDate)}
            helperText={formik.touched.startDate && formik.errors.startDate}
          />
        </RowContainer>
        <RowContainer>
          <DatePick
            name="endDate"
            label={t("field.systemLicenceEndDatePlaceholder")}
            value={formatedValues.endDate}
            onChange={(value) => formik.setFieldValue("endDate", value, true)}
            // minDate={
            //   edit ? subYears(new Date(formatedValues?.endDate), 1) : new Date()
            // }
            // maxDate={
            //   edit
            //     ? addYears(new Date(formatedValues?.endDate), 2)
            //     : addYears(new Date(), 2)
            // }

            error={formik.touched.endDate && Boolean(formik.errors.endDate)}
            helperText={formik.touched.endDate && formik.errors.endDate}
          />
        </RowContainer>
        <RowContainer>
          <SelectInput
            fullWidth
            name="licenceStatusId"
            label={t("field.systemLicenceStatusIdPlaceholder")}
            onChange={formik.handleChange}
            value={formik.values.licenceStatusId}
            error={
              formik.touched.licenceStatusId &&
              Boolean(formik.errors.licenceStatusId)
            }
            helperText={
              formik.touched.licenceStatusId && formik.errors.licenceStatusId
            }
            items={licenceStatuses}
          />
        </RowContainer>

        <RowContainer style={{ marginBottom: 0, justifyContent: "center" }}>
          <Button
            variant="outlined"
            onClick={handleCloseBtn}
            style={{ marginRight: "24px" }}
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!formik.dirty}
            onClick={formik.handleSubmit}
          >
            {t("common.save")}
          </Button>
        </RowContainer>
      </Box>
    </CustomDialog>
  );
};

export default AddLicenceFormModal;
