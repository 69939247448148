export default {
  name: "",
  surname: "",
  email: "",
  password: "",
  confirmPassword: "",
  phoneNumber: "",
  office: "",
  roleId: null
};
