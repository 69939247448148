import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: {},
    role: null,
    agency: null,
    agencyType: null,
  },
  reducers: {
    setToken: (state, action) => {
      const { jwt } = action.payload;
      state.token.jwt = jwt;
    },
    logOut: (state) => {
      state.user = null;
      state.token.jwt = null;
      state.role = null;
      state.agency = null
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setAgency: (state, action) => {
      state.agency = action.payload;
    },
    setAgencyType: (state, action) => {
      state.agencyType = action.payload;
    },
  },
});

export const { setToken, logOut, setRole, setAgency, setAgencyType } = authSlice.actions;
export default authSlice.reducer;

export const authSelector = (state) => state.auth;
export const selectCurrentUser = createSelector(
  authSelector,
  (state) => state.user
);
export const selectCurrentToken = createSelector(
  authSelector,
  (state) => state.token.jwt
);

export const selectCurrentRole = createSelector(
  authSelector,
  (state) => state.role
);

export const selectCurrentAgencyType = createSelector(
  authSelector,
  (state) => state.agencyType
);

export const selectCurrentAgency = createSelector(
  authSelector,
  (state) => state.agency
);
