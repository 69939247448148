import React from "react";

const SupplierPaymentPage = () => {
  return (
    <>
      <p>Supplier payment page</p>
    </>
  );
};

export default SupplierPaymentPage;
