import React from "react";

const ExcerptsPage = () => {
  return (
    <>
      <p>Excerpts page</p>
    </>
  );
};

export default ExcerptsPage;