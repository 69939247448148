import { apiSlice } from "features/api/apiSlice";

export const unitPropertyApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["UnitProperty", "UnitPropertySingle"],
  endpoints: (builder) => ({
    unitPropertyList: builder.query({
      query: (args) => {
        const { page, rowsPerPage, search, sort } = args;
        return {
          url:
            "/unit-property/list?" +
            new URLSearchParams({
              PageNumber: page + 1,
              RowsPerPage: rowsPerPage,
              Active: true,
              // Conditionally add to params object if keys exist
              ...(sort && { SortPropertyName: sort.name, Ascending: sort.direction === 'asc' ? true : false }),
              ...(search && { Name: search }),
              //   ...(sortDirection && { orderBy: sortDirection }),
            }),
        };
      },
      providesTags: ["UnitProperty"],
    }),
    unitPropertyDropdown: builder.query({
      query: () => ({
        url: `/unit-property/dropdown`,
      }),
      providesTags: ["UnitPropertySingle"],
    }),
    unitPropertyDetails: builder.query({
      query: ({ id }) => ({
        url: `/unit-property/${id}`,
      }),
      providesTags: ["UnitPropertySingle"],
    }),
    addUnitProperty: builder.mutation({
      query: (data) => ({
        url: "/unit-property/add",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["UnitProperty"],
    }),
    editUnitProperty: builder.mutation({
      query: (data) => ({
        url: "/unit-property/edit",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["UnitProperty", "UnitPropertySingle"],
    }),
    deleteUnitProperty: builder.mutation({
        query: (data) => ({
          url: "/unit-property",
          method: "DELETE",
          body: { ...data },
        }),
        invalidatesTags: ["UnitProperty"],
      }),
  }),
});

export const {
  useUnitPropertyListQuery,
  useUnitPropertyDropdownQuery,
  useUnitPropertyDetailsQuery,
  useAddUnitPropertyMutation,
  useEditUnitPropertyMutation,
  useDeleteUnitPropertyMutation
} = unitPropertyApiSlice;
