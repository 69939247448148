import styled from "@emotion/styled";
import { Box, Container } from "@mui/material";


export const LoginPageContainer = styled(Container)`
  display: flex;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(to bottom, #7EBBE3, #FFFFFF);
`

export const LoginPageBox = styled(Box)`
   width: 547px;
   height: 529px;
   border-radius: 12px;
   background-color: white;
   box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.15), 0px 2px 4px rgba(16, 24, 40, 0.08);
`;

export const LogoBox = styled(Box)`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  margin-top: 32px;
`;
