/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { phoneNumberChars } from "util/helpers/preventNonNumericChars";
import { RowContainer } from "styles/SharedStyle.styled";
import CustomDialog from "../CustomDialog/CustomDialog";
import { useUserRolesQuery } from "features/user/userApiSlice";
import useAuth from "hooks/useAuth";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";

const AddEditUserFormModal = ({
  open,
  handleClose,
  formik,
  handleCloseBtn,
  edit = false,
  title,
}) => {
  const { t } = useTranslation();
  const { isSuperAdmin } = useAuth();

  const { data: roles } = useUserRolesQuery({}, { skip: !open });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleChange = (e) => {
    e.preventDefault();
  };

  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      handleCloseBtn={handleCloseBtn}
      title={title}
    >
      <Box component="form">
        <RowContainer>
          <TextInput
            fullWidth
            name="surname"
            label={t("field.surnamePlaceholder")}
            value={formik.values.surname}
            onChange={formik.handleChange}
            error={formik.touched.surname && Boolean(formik.errors.surname)}
            helperText={formik.touched.surname && formik.errors.surname}
            style={{ marginRight: "24px" }}
          />
          <TextInput
            fullWidth
            name="email"
            disabled={!isSuperAdmin && edit}
            label={t("field.emailPlaceholder")}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </RowContainer>

        <RowContainer>
          <TextInput
            fullWidth
            name="name"
            label={t("field.namePlaceholder")}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            style={{ marginRight: !edit && "24px" }}
          />
          {!edit && (
            <TextInput
              name="password"
              label={t("field.passwordPlaceholder")}
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              fullWidth
              onCut={handleChange}
              onCopy={handleChange}
              onPaste={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </RowContainer>

        <RowContainer>
          <TextInput
            name="phoneNumber"
            fullWidth
            label={t("field.phoneNumberPlaceholder")}
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
            }
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            onKeyDown={phoneNumberChars}
            style={{ marginRight: !edit && "24px" }}
          />
          {!edit && (
            <TextInput
              name="confirmPassword"
              label={t("field.confirmPasswordPlaceholder")}
              type={showConfirmPassword ? "text" : "password"}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              fullWidth
              onCut={handleChange}
              onCopy={handleChange}
              onPaste={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </RowContainer>

        <RowContainer>
          <TextInput
            fullWidth
            name="office"
            label={t("field.officePlaceholder")}
            value={formik.values.office}
            onChange={formik.handleChange}
            style={{ marginRight: "24px", borderWidth: "12px" }}
          />
          <SelectInput
            fullWidth
            name="roleId"
            label={t("field.roleIdRequiredPlaceholder")}
            onChange={formik.handleChange}
            value={formik.values.roleId}
            error={formik.touched.roleId && Boolean(formik.errors.roleId)}
            helperText={formik.touched.roleId && formik.errors.roleId}
            items={roles}
          />
        </RowContainer>

        <RowContainer style={{ marginBottom: 0, justifyContent: "center" }}>
          <Button
            variant="outlined"
            onClick={handleCloseBtn}
            style={{ marginRight: "24px" }}
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            disabled={!formik.dirty}
            type="submit"
            onClick={formik.handleSubmit}
          >
            {t("common.save")}
          </Button>
        </RowContainer>
      </Box>
    </CustomDialog>
  );
};

export default AddEditUserFormModal;
