import * as Yup from "yup";
import i18next from "i18next";

export default Yup.object().shape({
  currencyId: Yup.string().required(i18next.t("field.nbsCurrencyRequired")),
  rate: Yup.string().required(i18next.t("field.middleCourseRequired")),
  date: Yup.date()
    .required(i18next.t("field.currencyDateRequired"))
    .typeError(i18next.t("field.dateFormatTypeError")),
});
