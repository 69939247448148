import { apiSlice } from "features/api/apiSlice";

export const countryApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Country", "CountrySingle"],
  endpoints: (builder) => ({
    countryDropdown: builder.query({
      query: () => ({
        url: "/country/dropdown",
      }),
      providesTags: ["Country"],
    }),
    countryList: builder.query({
      query: (args) => {
        const { page, rowsPerPage, search, sort } = args;
        return {
          url:
            "/country/list?" +
            new URLSearchParams({
              PageNumber: page + 1,
              RowsPerPage: rowsPerPage,
              Active: true,
              // Conditionally add to params object if keys exist
              ...(sort && { SortPropertyName: sort.name, Ascending: sort.direction === 'asc' ? true : false }),
              ...(search && { Name: search }),
              //   ...(sortDirection && { orderBy: sortDirection }),
            }),
        };
      },
      providesTags: ["Country"],
    }),
    countryDetails: builder.query({
      query: ({ id }) => ({
        url: `/country/${id}`,
      }),
      providesTags: ["CountrySingle"],
    }),
    addCountry: builder.mutation({
      query: (data) => ({
        url: "/country/add",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["Country"],
    }),
    editCountry: builder.mutation({
      query: (data) => ({
        url: "/country/edit",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["Country", "CountrySingle"],
    }),
    deleteCountry: builder.mutation({
      query: (data) => ({
        url: "/country",
        method: "DELETE",
        body: { ...data },
      }),
      invalidatesTags: ["Country"],
    }),
  }),
});

export const {
  useCountryDropdownQuery,
  useCountryListQuery,
  useCountryDetailsQuery,
  useAddCountryMutation,
  useEditCountryMutation,
  useDeleteCountryMutation,
} = countryApiSlice;
