import * as Yup from "yup";
import i18next from "i18next";

export default Yup.object().shape({
  surname: Yup.string().required(
    i18next.t("field.surnameRequired")
  ),
  name: Yup.string().required(
    i18next.t("field.nameRequired")
  ),
});
