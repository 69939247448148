/* eslint-disable react/prop-types */
import { Box, Button } from "@mui/material";
import { useFormik } from "formik";
import userSettingsInitialValues from "initialValues/userSettingsInitialValues";
import React from "react";
import { useTranslation } from "react-i18next";
import { phoneNumberChars } from "util/helpers/preventNonNumericChars";
import userSettingsValidation from "validations/userSettingsValidation";
import { RowContainer } from "./AgencySettingsForm.styled";
import { useEditUserMutation } from "features/user/userApiSlice";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import TextInput from "components/Inputs/TextInput";
import { ContentContainer, FormContainer, InformationContainer, InformationMainText } from "components/Forms/AgencyForm/AgencyForm.styled";

const UserSettingsForm = ({ userData, isLoadingUserData }) => {
  const { t } = useTranslation();

  const [editUser, { isLoading: isLoadingEditUser }] = useEditUserMutation();

  const handleSubmit = async (values) => {
    editUser(values)
      .unwrap()
      .then(() => {
        if (!isLoadingEditUser) {
          makeToastMessage(t("users.editSuccessMessage"));
        }
      })
      .catch(() => makeErrorToastMessage(t("users.editErrorMessage")));
  };

  const formik = useFormik({
    initialValues: isLoadingUserData ? userSettingsInitialValues : userData,
    validationSchema: userSettingsValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  return (
    <Box style={{marginTop: 32}} component="form" onSubmit={formik.handleSubmit}>
      <FormContainer style={{marginBottom: 24}}>
        <InformationContainer>
          <InformationMainText>
            {t("users.section.personalInfo")}
          </InformationMainText>
        </InformationContainer>
        <RowContainer style={{ marginBottom: 24 }}>
          <TextInput
            fullWidth
            name="surname"
            label={t("field.surnamePlaceholder")}
            value={formik.values.surname}
            onChange={formik.handleChange}
            error={formik.touched.surname && Boolean(formik.errors.surname)}
            helperText={formik.touched.surname && formik.errors.surname}
            style={{ marginRight: "24px" }}
          />
          <TextInput
            fullWidth
            name="name"
            label={t("field.namePlaceholder")}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </RowContainer>
        <RowContainer>
          <TextInput
            name="phoneNumber"
            fullWidth
            label={t("field.phoneNumberPlaceholder")}
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
            }
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            onKeyDown={phoneNumberChars}
            style={{ marginRight: "24px" }}
          />
          <TextInput
            fullWidth
            disabled
            name="email"
            label={t("field.emailPlaceholder")}
            value={formik.values.email}
          />
        </RowContainer>
      </FormContainer>
      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {t("users.section.agencyInfo")}
          </InformationMainText>
        </InformationContainer>
        <RowContainer>
          <TextInput
            fullWidth
            disabled
            name="office"
            label={t("field.officePlaceholder")}
            value={formik.values.office}
            style={{ marginRight: "24px" }}
          />
          <TextInput
            fullWidth
            disabled
            name="role"
            label={t("field.roleIdPlaceholder")}
            value={formik.values.role}
            onChange={formik.handleChange}
          />
        </RowContainer>
      </FormContainer>
      <ContentContainer>
        <InformationContainer />
        <RowContainer style={{ marginTop: 24, justifyContent: "center" }}>
          <Button
            onClick={() => formik.resetForm()}
            variant="outlined"
            style={{ marginRight: "24px" }}
          >
            {t("common.cancel")}
          </Button>
          <Button type="submit" variant="contained" disabled={!formik.dirty}>
            {t("common.save")}
          </Button>
        </RowContainer>
      </ContentContainer>
    </Box>
  );
};

export default UserSettingsForm;
