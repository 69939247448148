/* eslint-disable react/prop-types */
import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomDialog from "../CustomDialog/CustomDialog";
import { RowContainer } from "styles/SharedStyle.styled";
import { useAgencyTypeQuery } from "features/agency-type/agencyTypeApiSlice";
import { useCountryDropdownQuery } from "features/country/countryApiSlice";
import { useLocationDropdownQuery } from "features/location/locationApiSlice";
import TextInput from "components/Inputs/TextInput";
import SelectInput from "components/Inputs/SelectInput";
import { phoneNumberChars } from "util/helpers/preventNonNumericChars";
import CountrySelect from "components/Inputs/CountrySelect";
import LocationSelect from "components/Inputs/LocationSelect";

const AddPartnerModal = ({ open, handleClose, formik, handleCloseBtn, title }) => {
  const { t } = useTranslation();

  const { data: agencyTypes } =
    useAgencyTypeQuery({}, { skip: !open });

  const { data: countries } =
    useCountryDropdownQuery({}, { skip: !open });
  const { data: locations } =
    useLocationDropdownQuery(
      { id: formik.values.countryId },
      { skip: !open || !formik.values.countryId }
    );

  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      handleCloseBtn={handleCloseBtn}
      title={title}
    >
      <Box component="form">
        <RowContainer>
          <TextInput
            fullWidth
            name="name"
            label={t("field.partnerNamePlaceholder")}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            style={{ marginRight: "24px" }}
          />
          <SelectInput
            fullWidth
            name="agencyTypeId"
            label={t("field.partnerTypeIdPlaceholder")}
            onChange={formik.handleChange}
            value={formik.values.agencyTypeId}
            error={
              formik.touched.agencyTypeId && Boolean(formik.errors.agencyTypeId)
            }
            helperText={
              formik.touched.agencyTypeId && formik.errors.agencyTypeId
            }
            style={{ marginRight: "24px" }}
            items={agencyTypes}
          />
          <TextInput
            fullWidth
            name="email"
            label={t("field.emailPlaceholder")}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </RowContainer>

        <RowContainer>
          <TextInput
            fullWidth
            name="address"
            label={t("field.addressPlaceholder")}
            value={formik.values.address}
            onChange={formik.handleChange}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
            style={{ marginRight: "24px" }}
          />

          <CountrySelect
            fullWidth
            name="countryId"
            label={t("field.countryIdPlaceholder")}
            onChange={formik.handleChange}
            value={formik.values.countryId}
            error={formik.touched.countryId && Boolean(formik.errors.countryId)}
            helperText={formik.touched.countryId && formik.errors.countryId}
            style={{ marginRight: "24px" }}
            items={countries}
          />
          <LocationSelect
            fullWidth
            name="locationId"
            disabled={!formik.values.countryId}
            label={t("field.locationIdPlaceholder")}
            onChange={formik.handleChange}
            value={formik.values.locationId}
            error={
              formik.touched.locationId && Boolean(formik.errors.locationId)
            }
            helperText={formik.touched.locationId && formik.errors.locationId}
            items={locations}
            countryId={formik.values.countryId}
          />
        </RowContainer>
        <RowContainer>
        <TextInput
            name="phoneNumber"
            fullWidth
            label={t("field.phoneNumberPlaceholder")}
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
            }
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            onKeyDown={phoneNumberChars}
          />
        </RowContainer>

        <RowContainer style={{ marginBottom: 0, justifyContent: "center" }}>
          <Button
            variant="outlined"
            onClick={handleCloseBtn}
            style={{ marginRight: "24px" }}
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!formik.dirty}
            onClick={formik.handleSubmit}
          >
            {t("common.save")}
          </Button>
        </RowContainer>
      </Box>
    </CustomDialog>
  );
};

export default AddPartnerModal;
