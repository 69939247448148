import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const appSlice = createSlice({
  name: "app",
  initialState: {
    isSidebarCollapsed: false,
  },
  reducers: {
    setIsSidebarCollapsed: (state, action) => {
      state.isSidebarCollapsed = action.payload;
    }
  },
});

export const { setIsSidebarCollapsed } = appSlice.actions;
export default appSlice.reducer;

const appSelector = (state) => state.app;

export const selectIsSidebarCollapsed = createSelector(
  appSelector,
  (state) => state.isSidebarCollapsed
);