/* eslint-disable no-unused-vars */
import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { arrayToObject } from "util/helpers/arrayToObject";
import CreateIcon from "@mui/icons-material/Create";
import { useNavigate } from "react-router-dom";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

const useAgencyTable = ({
  setOpenActivateDeactivateAgency,
  setAgencyStatus,
  page,
  rowsPerPage,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const columns = [
    {
      name: "",
      label: t("tableCols.agency.ordinalNumberLabel"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { width: "100px" }}),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMetadata, updateValue) => {
          return tableMetadata.rowIndex + 1 + page * rowsPerPage;
        },
      },
    },
    {
      name: "name",
      label: t("tableCols.agency.agencyNameLabel"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "agencyType",
      label: t("tableCols.agency.agencyTypeLabel"),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "address",
      label: t("tableCols.agency.addressLabel"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "location",
      label: t("tableCols.agency.cityLabel"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "country",
      label: t("tableCols.agency.countryLabel"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "email",
      label: t("tableCols.agency.emailLabel"),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "agencyActive",
      label: "Status agencije",
      option: {
        filter: false,
      },
    },
    {
      name: "updatedOn",
      label: t("tableCols.agency.lastChangesLabel"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return format(new Date(rowData.updatedOn), "dd.MM.yyyy.");
        },
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        print: false,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMeta, updateValue) => {
          const agency = arrayToObject(tableMeta.rowData, columns);
          return (
            <Box style={{ display: "flex" }}>
              <Tooltip
                title={t("tableCols.agency.tooltip.details")}
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    navigate(`/agency/${agency?.id}`);
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  agency?.agencyActive === t("tableCols.agency.agencyStatusActive")
                    ? t("tableCols.agency.tooltip.deactivate")
                    : t("tableCols.agency.tooltip.activate")
                }
                placement="top"
              >
                <IconButton
                  onClick={() => {
                    setOpenActivateDeactivateAgency(true);
                    setAgencyStatus(agency);
                  }}
                >
                  {agency?.agencyActive === t("tableCols.agency.agencyStatusActive") ? (
                    <RemoveCircleIcon />
                  ) : (
                    <CheckIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];
  return { columns };
};

export default useAgencyTable;
