import { apiSlice } from "features/api/apiSlice";

export const licenceApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["LicenceList", "LicenceSingle"],
  endpoints: (builder) => ({
    licenceList: builder.query({
      query: (args) => {
        const { page, rowsPerPage, agencyId, sort } = args;
        return {
          url:
            "/licence/list?" +
            new URLSearchParams({
              AgencyId: agencyId,
              PageNumber: page + 1,
              RowsPerPage: rowsPerPage,
              // Conditionally add to params object if keys exist
              //   ...(sort && { sortBy: sort }),
              ...(sort && { SortPropertyName: sort.name, Ascending: sort.direction === 'asc' ? true : false }),
              //   ...(sortDirection && { orderBy: sortDirection }),
            }),
        };
      },
      providesTags: ["LicenceList"],
    }),
    licenceDetails: builder.query({
      query: ({ id }) => ({
        url: `/licence/${id}`,
      }),
      providesTags: ["LicenceSingle"]
    }),
    addLicence: builder.mutation({
      query: (data) => ({
        url: "/licence/add",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["LicenceList"],
    }),
    editLicence: builder.mutation({
      query: (data) => ({
        url: "/licence/edit",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["LicenceSingle", "LicenceList"],
    }),
  }),
});

export const {
  useLicenceListQuery,
  useAddLicenceMutation,
  useEditLicenceMutation,
  useLicenceDetailsQuery,
} = licenceApiSlice;
