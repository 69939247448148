import React from "react";

const ChargePage = () => {
  return (
    <>
      <p>Charge page</p>
    </>
  );
};

export default ChargePage;
