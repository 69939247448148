/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useFormik } from "formik";
import ConfirmCloseDialog from "components/Modals/ConfirmCloseDialog/ConfirmCloseDialog";
import { Button } from "@mui/material";
import CustomTable from "components/CustomTable/CustomTable";
import AddLicenceFormModal from "components/Modals/AddLicenceModal/AddLicenceModal";
import systemLicenceInitialValues from "initialValues/systemLicenceInitialValues";
import systemLicenceValidation from "validations/systemLicenceValidation";
import useLicenceTable from "hooks/tables/useLicenceTable";
import { useTranslation } from "react-i18next";
import { convertDateToISO } from "util/dateHelpers";
import {
  useLicenceDetailsQuery,
  useLicenceListQuery,
  useEditLicenceMutation,
  useAddLicenceMutation,
} from "features/licence/licenceApiSlice";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import { ButtonContainer, ContentContainer } from "styles/SharedStyle.styled";
import useAuth from "hooks/useAuth";

const ListAddLicence = ({ id }) => {
  const { isSuperAdmin } = useAuth();

  const [addLicence] = useAddLicenceMutation();
  const [editLicence] = useEditLicenceMutation();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // eslint-disable-next-line no-unused-vars
  const [sort, setSort] = useState(null);
  const [openEditLicence, setOpenEditLicence] = useState(false);
  const [editData, setEditData] = useState(null);

  const [openAddLicence, setOpenAddLicence] = useState(false);
  const [closeConfirmOpen, setCloseConfirmOpen] = useState(false);

  const { data: listLicences, isLoading: isLoadingLicenceList } =
    useLicenceListQuery({ agencyId: id, page, rowsPerPage, sort });

  const { data: licenceDetails, isLoading } = useLicenceDetailsQuery(
    { id: editData?.id },
    { skip: !openEditLicence }
  );

  const { columns } = useLicenceTable({
    setOpenEditLicence,
    setEditData,
    page,
    rowsPerPage,
  });

  const handleSubmit = async (values) => {
    if (openAddLicence) {
      addLicence({
        agencyId: Number(id),
        licenceStatusId: Number(values.licenceStatusId),
        startDate: convertDateToISO(values.startDate),
        endDate: convertDateToISO(values.endDate),
      })
        .unwrap()
        .then(() => {
          makeToastMessage(t("licence.addSuccessMessage"));
          formik.resetForm();
          setOpenAddLicence();
        })
        .catch(() => makeErrorToastMessage(t("licence.addErrorMessage")));
    }

    if (openEditLicence) {
      editLicence({
        agencyId: Number(id),
        id: editData.id,
        licenceStatusId: Number(values.licenceStatusId),
        startDate: convertDateToISO(values.startDate),
        endDate: convertDateToISO(values.endDate),
      })
        .unwrap()
        .then(() => {
          makeToastMessage(t("licence.editSuccessMessage"));
          formik.resetForm();
          setOpenEditLicence();
        })
        .catch(() => makeErrorToastMessage(t("licence.editErrorMessage")));
    }
  };

  const formik = useFormik({
    initialValues: openEditLicence
      ? isLoading
        ? systemLicenceInitialValues
        : licenceDetails
      : systemLicenceInitialValues,
    validationSchema: systemLicenceValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleCloseModal = (_event, action) => {
    if (action === "backdropClick" || action === "escapeKeyDown") {
      if (formik.dirty) {
        setCloseConfirmOpen(true);
      } else {
        if (openAddLicence) {
          setOpenAddLicence(false);
          formik.resetForm();
        } else {
          setOpenEditLicence(false);
          formik.resetForm();
          setEditData(null);
        }
      }
    }
  };

  const handleCloseConfirmDialogAdd = () => {
    setOpenAddLicence(false);
    setCloseConfirmOpen(false);
    formik.resetForm();
  };

  const handleCloseConfirmDialogEdit = () => {
    setOpenEditLicence(false);
    setCloseConfirmOpen(false);
    formik.resetForm();
    setEditData(null);
  };

  const handleOpenAddLicence = () => {
    setOpenAddLicence(true);
  };

  const handleCancel = () => {
    setCloseConfirmOpen(false);
  };

  const handleCloseBtn = () => {
    if (formik.dirty) {
      setCloseConfirmOpen(true);
    } else {
      if (openAddLicence) {
        setOpenAddLicence(false);
        formik.resetForm();
      } else {
        setOpenEditLicence(false);
        setEditData(null);
      }
    }
  };

  return (
    <ContentContainer>
      <AddLicenceFormModal
        open={openEditLicence ? openEditLicence : openAddLicence}
        handleClose={handleCloseModal}
        formik={formik}
        handleCloseBtn={handleCloseBtn}
        edit={openEditLicence}
        title={
          openEditLicence
            ? t("licence.editTitle")
            : openAddLicence
            ? t("licence.addTitle")
            : ""
        }
      />
      <ConfirmCloseDialog
        open={closeConfirmOpen}
        handleClose={
          openEditLicence
            ? handleCloseConfirmDialogEdit
            : handleCloseConfirmDialogAdd
        }
        handleCancel={handleCancel}
        description={t("licence.cancelConfirm")}
      />
      {isSuperAdmin && (
        <ButtonContainer>
          <Button variant="contained" onClick={handleOpenAddLicence}>
            {t("licence.addButton")}
          </Button>
        </ButtonContainer>
      )}
      <CustomTable
        columns={columns}
        data={listLicences?.data}
        isLoading={isLoadingLicenceList}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setSort={setSort}
        rowsName={t("licence.rowsName")}
        count={listLicences?.totalCount}
      />
    </ContentContainer>
  );
};

export default ListAddLicence;
