import React, { useState } from "react";
import PropTypes from "prop-types";
import MenuIcon from "@mui/icons-material/Menu";
import { HeaderIconContainer } from "../Header.styled";
import { SwipeableDrawer } from "@mui/material";


// In progress
const HeaderDrawer = () => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <>
      <HeaderIconContainer
        onClick={() => setIsOpened((prevState) => !prevState)}
      >
        <MenuIcon />
      </HeaderIconContainer>
      <SwipeableDrawer
        anchor="left"
        open={isOpened}
        onOpen={() => setIsOpened(true)}
        onClose={() => setIsOpened(false)}
        PaperProps={{
          sx: { width: "90%" },
        }}
      >
        Drawer
      </SwipeableDrawer>
    </>
  );
};

HeaderDrawer.propTypes = {
  children: PropTypes.node,
};

export default HeaderDrawer;
