import React from "react";

const ReportsPage = () => {
  return (
    <>
      <p>Reports page</p>
    </>
  );
};

export default ReportsPage;