/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import SelectInput from "components/Inputs/SelectInput";
import { useExchangeRateDropdownQuery } from "features/exchange-rate/exchangeRateSlice";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import DatePick from "components/Inputs/DatePick";

const ClientFilter = ({ filterForm }) => {
  const { t } = useTranslation();
  const { data: currencies } = useExchangeRateDropdownQuery();


  return (
    <Box component="form">
      <RowContainer>
        <DatePick
           name="OnDate"
           label={t("field.dateFilter")}
           selected={filterForm.values.OnDate ? new Date(filterForm.values.OnDate) : null}
           value={filterForm.values.OnDate}
           onChange={(value) => filterForm.setFieldValue("OnDate", value)}
          />
      </RowContainer>
      <RowContainer>
        <SelectInput
            name="CurrencyId"
            label={t("field.currencyFilter")}
            onChange={filterForm.handleChange}
            type="number"
            fullWidth
            value={filterForm.values.CurrencyId}
            error={
              filterForm.touched.CurrencyId && Boolean(filterForm.errors.CurrencyId)
            }
            helperText={filterForm.touched.CurrencyId && filterForm.errors.CurrencyId}
            items={currencies}
            selectPlaceholder={t("dropdown.currency")}
          />
      </RowContainer>
    </Box>
  );
};

export default ClientFilter;
