export function getNonEmptyProps(obj) {
    const nonEmptyProps = {};
    
    for (const prop in obj) {
      if (obj[prop] !== "") {
        nonEmptyProps[prop] = obj[prop];
      }
    }
  
    return nonEmptyProps;
  }