import * as Yup from "yup";
import i18next from "i18next";

export default Yup.object().shape({
  name: Yup.string().required(i18next.t("field.agencyNameRequired")),
  agencyTypeId: Yup.number().required(i18next.t("field.agencyTypeIdRequired")),
  address: Yup.string().required(i18next.t("field.addressRequired")),
  locationId: Yup.string().required(i18next.t("field.locationIdRequired")),
  countryId: Yup.string().required(i18next.t("field.countryIdRequired")),
  email: Yup.string()
    .email(i18next.t("field.emailFormat"))
    .required(i18next.t("field.emailRequired")),
  phoneNumber: Yup.string().required(i18next.t("field.phoneNumberRequired")),
  pib: Yup.string()
    .required(i18next.t("field.pibRequired"))
    .length(9, i18next.t("field.pibLength")),
  legalIdentificationNumber: Yup.string()
    .required(i18next.t("field.legalIdentificationNumberRequired"))
    .length(8, i18next.t("field.legalIdentificationNumberLength")),
  accountNumber: Yup.string().required(
    i18next.t("field.accountNumberRequired")
  ),
});
