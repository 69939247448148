import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const SidemenuContainer = styled(Box)`
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.05),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  padding-top: 10px;
  padding-bottom: 32px;
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  min-height: 100vh;
  transition: all 0.5s ease;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
`;

export const TopContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const BottomContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled(Box)`
  display: flex;
`;

export const SidebarItemsContainer = styled(Box)`
  margin-top: 10px;
`;

export const SidebarItemContainer = styled(Box)`
  display: flex;
  padding-top: 2px;
  padding-bottom: 2px;
  align-items: center;
  margin-bottom: 4px;
  cursor: pointer;
  border-radius: 6px;
  &:hover {
    background-color: #F5DB914D;
    color: #377CBE;
  }
`;

export const SidebarItem = styled(Typography)`
  margin-left: 8px;
  &:hover {
    color: #377CBE;
    display: flex;
    align-items: center;
  }
`;

export const BottomRowItem = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

