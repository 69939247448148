import { apiSlice } from "features/api/apiSlice";

export const licenceStatusApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["LicenceStatusDropdown"],
  endpoints: (builder) => ({
    licenceStatusDropdown: builder.query({
      query: () => ({
        url: "/licence-status/dropdown",
      }),
      providesTags: ["LicenceStatusDropdown"],
    }),
  }),
});

export const { useLicenceStatusDropdownQuery } = licenceStatusApiSlice;
