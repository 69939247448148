import styled from "styled-components";

import { Box, Typography } from "@mui/material";

export const Title = styled(Typography)`
  text-align: center;
  margin-bottom: 24px;
`;

export const SectionContainer = styled(Box)`
  width: 50%;
  padding-right: 12px;
  @media (max-width: 600px) {
    width: 100%;
    padding-right: 0;
  }
`;

export const SectionTitle = styled(Typography)`
    margin-bottom: 24px;
`
