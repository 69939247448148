import React from "react";

const NotificationsPage = () => {
  return (
    <>
      <p>Notifications page</p>
    </>
  );
};

export default NotificationsPage;