import { apiSlice } from "features/api/apiSlice";

export const agencyTypeApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["AgencyType"],
  endpoints: (builder) => ({
    agencyType: builder.query({
      query: () => ({
        url: "/agency-type/dropdown",
      }),
      providesTags: ["AgencyType"]
    }),
  }),
});

export const { useAgencyTypeQuery } = agencyTypeApiSlice;
