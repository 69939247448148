/* eslint-disable react/prop-types */
import { MenuItem, TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const SelectInputContainer = styled(TextField)`
  @media (max-width: 600px) {
    margin-bottom: 24px;
  }
`

const SelectInput = ({
  name,
  disabled = false,
  label,
  onChange,
  value,
  selectPlaceholder,
  items,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <SelectInputContainer
      name={name}
      select
      disabled={disabled}
      label={label}
      onChange={onChange}
      SelectProps={{
        MenuProps: {
          sx: { maxHeight: 300 },
        },
      }}
      value={value}
      InputLabelProps={{
        shrink: value,
      }}
      {...props}
    >
      <MenuItem value={""}>
        {!selectPlaceholder ? t("common.all") : selectPlaceholder}
      </MenuItem>
      {items &&
        items?.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
    </SelectInputContainer>
  );
};

export default SelectInput;
