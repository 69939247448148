/* eslint-disable react/prop-types */
import { Box, Button } from "@mui/material";
import React from "react";
import { RowContainer } from "styles/SharedStyle.styled";
import CustomDialog from "../CustomDialog/CustomDialog";
import { useTranslation } from "react-i18next";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";
import { useAccommodationUnitTypeDropdownQuery } from "features/accommodationUnitType/accommodationUnitTypeApiSlice";
import MultiSelectInput from "components/Inputs/MultiSelectInput";
import { useUnitPropertyDropdownQuery } from "features/unitProperty/unitPropertyApiSlice";

const AddAccommodationUnitModal = ({
  open,
  handleClose,
  formik,
  handleCloseBtn,
  title,
}) => {
  const { t } = useTranslation();

  const { data: accommodationUnitTypes } =
    useAccommodationUnitTypeDropdownQuery({}, { skip: !open });

  const { data: unitProperty } = useUnitPropertyDropdownQuery();

  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      handleCloseBtn={handleCloseBtn}
      title={title}
    >
      <Box component="form">
        <RowContainer>
          <TextInput
            fullWidth
            name="name"
            label={t("field.accommodationUnitNamePlaceholder")}
            value={formik?.values?.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            style={{marginRight: 24}}
          />
          <SelectInput
            fullWidth
            name="accommodationUnitTypeId"
            label={t("field.accommodationUnitTypeRequiredPlaceholder")}
            onChange={formik.handleChange}
            value={formik?.values?.accommodationUnitTypeId}
            error={
              formik.touched.accommodationUnitTypeId &&
              Boolean(formik.errors.accommodationUnitTypeId)
            }
            helperText={
              formik.touched.accommodationUnitTypeId &&
              formik.errors.accommodationUnitTypeId
            }
            items={accommodationUnitTypes}
          />
        </RowContainer>
        <RowContainer>
          <MultiSelectInput
            names={unitProperty}
            selectedValues={formik?.values?.unitProperties}
            formik={formik}
            name="unitProperties"
            label={"Karakteristike smeštajne jedinice"}
          />
        </RowContainer>

        <RowContainer>
          <TextInput
            fullWidth
            multiline
            minRows={4}
            maxRows={8}
            name="description"
            label={t("field.accommodationUnitDescriptionPlaceholder")}
            value={formik?.values?.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />
        </RowContainer>

        <RowContainer style={{ marginBottom: 0, justifyContent: "center" }}>
          <Button
            variant="outlined"
            onClick={handleCloseBtn}
            style={{ marginRight: "24px" }}
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!formik.dirty}
            onClick={formik.handleSubmit}
          >
            {t("common.save")}
          </Button>
        </RowContainer>
      </Box>
    </CustomDialog>
  );
};

export default AddAccommodationUnitModal;
