export const genders = [
  {
    value: 1,
    text: "Muški",
  },
  {
    value: 2,
    text: "Ženski",
  },
];
