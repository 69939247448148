import React from "react";
import PropTypes from "prop-types";
import {
  HeaderProfileMenu,
  HeaderProfileMenuItem,
  HeaderProfilePopoverContentContainer,
  HeaderProfilePopoverContentHeaderContainer,
  ProfileDetails,
  ProfileInitials,
  ProfileMail,
  ProfileName,
} from "./HeaderProfilePopoverContent.styled";
import { useTranslation } from "react-i18next";
import { logOut } from "features/auth/authSlice";
import { batch, useDispatch } from "react-redux";
import { apiSlice } from "features/api/apiSlice";
import useAuth from "hooks/useAuth";
import { useUserBasicProfileQuery } from "features/user/userApiSlice";

const HeaderProfilePopoverContent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {isSuperAdmin} = useAuth()
  const {data: userProfile} = useUserBasicProfileQuery();

  const handleLogout = () => {
    batch(() => {
      dispatch(apiSlice.util.resetApiState())
      dispatch(logOut());
    })
  };

  return (
    <HeaderProfilePopoverContentContainer>
      <HeaderProfilePopoverContentHeaderContainer>
        <ProfileInitials>{userProfile?.name.charAt(0)}</ProfileInitials>
        <ProfileDetails>
          <ProfileName>{userProfile?.name}</ProfileName>
          <ProfileMail>{userProfile?.email}</ProfileMail>
        </ProfileDetails>
      </HeaderProfilePopoverContentHeaderContainer>
      <HeaderProfileMenu>
        {!isSuperAdmin && <HeaderProfileMenuItem to={"/profile"}>{t("common.showProfile")}</HeaderProfileMenuItem>}
        <HeaderProfileMenuItem onClick={handleLogout}>
          {t("common.logout")}
        </HeaderProfileMenuItem>
      </HeaderProfileMenu>
    </HeaderProfilePopoverContentContainer>
  );
};

HeaderProfilePopoverContent.propTypes = {
  children: PropTypes.node,
};

export default HeaderProfilePopoverContent;
