import React, { useState } from "react";
import {
  BottomContainer,
  BottomRowItem,
  HeaderContainer,
  SidebarItemContainer,
  SidebarItemsContainer,
  SidemenuContainer,
  TopContainer,
} from "./Sidemenu.styled";
import MainIcon from "components/Icon/Icons/MainIcon";
import MainIconSmall from "components/Icon/Icons/MainIconSmall";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { sidebarItems } from "constants/sidebarItemsConstant";
import { useDispatch } from "react-redux";
import {
  selectIsSidebarCollapsed,
  setIsSidebarCollapsed,
} from "features/app/appSlice";
import { useSelector } from "react-redux";
import MenuDesktopVertical from "components/MenuDesktopVertical";
import { COLLAPSED_WIDTH, FULL_WIDTH } from "constants/sidemenu";
import { useTranslation } from "react-i18next";
import { PAGES } from "constants/pages";
import useAuth from "hooks/useAuth";

const Sidemenu = () => {
  const { isManager, isClerk } = useAuth();
  const { role, agencyType } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSidebarCollapsed = useSelector(selectIsSidebarCollapsed);

  const [hoveredItem, setHoveredItem] = useState(null);
  function filterSidebarItemsByRoleAndAgencyType(
    sidebarItems,
    role,
    agencyType
  ) {
    return sidebarItems.reduce((filteredItems, item) => {
      const newItem = { ...item };

      if (item.children) {
        const filteredChildren = filterSidebarItemsByRoleAndAgencyType(
          item.children[0].items,
          role,
          agencyType
        );
        newItem.children = [{ items: filteredChildren }];
      }

      if (
        (newItem.role.length === 0 || newItem.role.includes(role)) &&
        (newItem.agencyType.length === 0 ||
          newItem.agencyType.includes(agencyType))
      ) {
        filteredItems.push(newItem);
      }

      return filteredItems;
    }, []);
  }

  return (
    <SidemenuContainer
      style={{
        width: isSidebarCollapsed === false ? FULL_WIDTH : COLLAPSED_WIDTH,
      }}
    >
      <TopContainer>
        <HeaderContainer
          onClick={() => navigate(PAGES.BASE.route)}
          style={{
            paddingLeft: !isSidebarCollapsed && "12px",
            justifyContent: isSidebarCollapsed && "center",
            cursor: "pointer",
          }}
        >
          {!isSidebarCollapsed ? (
            <MainIcon width={200} height={60} />
          ) : (
            <MainIconSmall width={24} height={24} />
          )}
        </HeaderContainer>
        <SidebarItemsContainer>
          <MenuDesktopVertical
            navConfig={filterSidebarItemsByRoleAndAgencyType(
              sidebarItems,
              role,
              agencyType
            )}
            hoveredItem={hoveredItem}
            setHoveredItem={setHoveredItem}
          />
        </SidebarItemsContainer>
      </TopContainer>
      <BottomContainer
        style={{
          paddingLeft: !isSidebarCollapsed && "12px",
          alignItems: isSidebarCollapsed && "center",
        }}
      >
        <BottomRowItem>
          {isManager || isClerk ? (
            <SidebarItemContainer
              style={{ width: "100%" }}
              onClick={() => navigate(PAGES.SETTINGS.route)}
            >
              <SettingsIcon
                width={24}
                height={24}
                style={{ marginRight: "8px" }}
              />
              {!isSidebarCollapsed && t("pages.settings")}
            </SidebarItemContainer>
          ) : (
            <p></p>
          )}
          {!isSidebarCollapsed && (
            <SidebarItemContainer style={{ padding: "8px" }}>
              <ChevronLeftIcon
                onClick={() =>
                  dispatch(setIsSidebarCollapsed(!isSidebarCollapsed))
                }
                width={24}
                height={24}
              />
            </SidebarItemContainer>
          )}
        </BottomRowItem>
        {isSidebarCollapsed && (
          <SidebarItemContainer
            onClick={() => dispatch(setIsSidebarCollapsed(!isSidebarCollapsed))}
          >
            <ChevronLeftIcon
              width={24}
              height={24}
              style={{ transform: "rotate(180deg)", cursor: "pointer" }}
            />
          </SidebarItemContainer>
        )}
      </BottomContainer>
    </SidemenuContainer>
  );
};

export default Sidemenu;
