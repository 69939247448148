export default {
    name: "",
    surname: "",
    email: "",
    address: "",
    locationId: "",
    countryId: "",
    phoneNumber: "",
    dateOfBirth: null,
    passportNumber: "",
    passportExpirationDate: null,
    genderId: "",
    passportImage: null
  };
  