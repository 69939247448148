/* eslint-disable react/prop-types */
import { Box, Button, Divider } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomDialog from "../CustomDialog/CustomDialog";
import { RowContainer } from "styles/SharedStyle.styled";
import TextInput from "components/Inputs/TextInput";
import SelectInput from "components/Inputs/SelectInput";
import { useCategoryDropdownQuery } from "features/category/categoryApiSlice";
import { useOfferTypeDropdownQuery } from "features/offerType/offerTypeApiSlice";
import { useAccommodationTypeDropdownQuery } from "features/accommodationType/accommodationTypeApiSlice";
import { useAccommodationDropdownQuery } from "features/accommodation/accommodationApiSlice";
import { useCountryDropdownQuery } from "features/country/countryApiSlice";
import { useLocationDropdownQuery } from "features/location/locationApiSlice";
import { useServiceDropdownQuery } from "features/service/serviceApiSlice";
import { useTransportationTypeDropdownQuery } from "features/transportationType/transportationTypeApiSlice";
import { useAccommodationUnitTypeDropdownQuery } from "features/accommodationUnitType/accommodationUnitTypeApiSlice";
import { useAgencyDropdownQuery } from "features/agency/agencyApiSlice";
import {
  ACCOMMODATION,
  ARRANGEMENT,
  TRANSPORT,
} from "constants/offerDetailsLevel";

const PreviewInternalSaleModal = ({
  open,
  handleClose,
  formik,
  title,
  offerDetailsLevelId,
  handleConfirm
}) => {
  const { t } = useTranslation();

  const { data: categories } = useCategoryDropdownQuery({}, { skip: !open });
  const { data: offerTypes } = useOfferTypeDropdownQuery({}, { skip: !open });
  const { data: accommodationTypes } = useAccommodationTypeDropdownQuery(
    {},
    { skip: !open }
  );
  const { data: accommodations } = useAccommodationDropdownQuery(
    {},
    { skip: !open }
  );
  const { data: countries } = useCountryDropdownQuery({}, { skip: !open });
  const { data: locations } = useLocationDropdownQuery(
    { id: formik.values.countryId },
    { skip: !open || !formik.values?.countryId }
  );
  const { data: services } = useServiceDropdownQuery({}, { skip: !open });
  const { data: transportationTypes } = useTransportationTypeDropdownQuery(
    {},
    { skip: !open }
  );
  const { data: accommodationUnitTypes } =
    useAccommodationUnitTypeDropdownQuery({}, { skip: !open });
  const { data: agencies } = useAgencyDropdownQuery({}, { skip: !open });

  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      handleCloseBtn={handleClose}
      title={title}
      maxWidth="lg"
    >
      <Box component="form">
        <RowContainer>
          <TextInput
            fullWidth
            name="name"
            label={t("field.offerNamePlaceholderRequired")}
            value={formik?.values?.name || ""}
            style={{ marginRight: "24px" }}
            disabled
          />
          <TextInput
            fullWidth
            name="number"
            label={t("field.offerNumberPlaceholderRequired")}
            value={formik.values?.number || ""}
            disabled
          />
        </RowContainer>
        <RowContainer>
          <SelectInput
            fullWidth
            name="travelOrganizerId"
            label={t("field.travelOrganizerPlaceholderRequired")}
            value={formik.values?.travelOrganizerId || ""}
            style={{ marginRight: "24px" }}
            items={agencies}
            disabled
          />
          <TextInput
            fullWidth
            name="jid"
            label={t("field.jidPlaceholder")}
            value={formik.values?.jid || ""}
            onChange={formik.handleChange}
            disabled
          />
        </RowContainer>

        <Divider style={{ marginBottom: 24 }} />

        <RowContainer>
          <SelectInput
            fullWidth
            name="categoryId"
            label={t("field.categoryPlaceholderRequired")}
            value={formik.values?.categoryId || ""}
            items={categories}
            style={{ marginRight: "24px" }}
            disabled
          />

          <SelectInput
            fullWidth
            name="offerTypeId"
            label={t("field.offerTypePlaceholderRequired")}
            value={formik.values?.offerTypeId || ""}
            items={offerTypes}
            disabled
          />
        </RowContainer>

        <RowContainer>
          <SelectInput
            fullWidth
            name="countryId"
            label={t("field.countryIdPlaceholder")}
            value={formik.values?.countryId || ""}
            style={{ marginRight: "24px" }}
            items={countries}
            disabled
          />
          <SelectInput
            fullWidth
            name="locationId"
            disabled
            label={t("field.locationIdPlaceholder")}
            value={formik.values?.locationId || ""}
            items={locations}
          />
        </RowContainer>

        {(offerDetailsLevelId === ARRANGEMENT ||
          offerDetailsLevelId === ACCOMMODATION) && (
          <RowContainer>
            <SelectInput
              fullWidth
              name="accommodationTypeId"
              label={t("field.accommodationTypeIdPlaceholder")}
              value={formik.values?.accommodationTypeId || ""}
              items={accommodationTypes}
              style={{ marginRight: "24px" }}
              disabled
            />
            <SelectInput
              fullWidth
              name="accommodationId"
              label={t("field.accommodationIdPlaceholderRequired")}
              value={formik.values?.accommodationId || ""}
              items={accommodations}
              disabled
            />
          </RowContainer>
        )}

        {(offerDetailsLevelId === ARRANGEMENT ||
          offerDetailsLevelId === ACCOMMODATION) && (
          <RowContainer>
            <SelectInput
              fullWidth
              name="accommodationUnitTypeId"
              label={t("field.accommodationUnitTypeRequiredPlaceholder")}
              value={formik.values?.accommodationUnitTypeId || ""}
              items={accommodationUnitTypes}
              style={{ marginRight: "24px" }}
              disabled
            />
            <SelectInput
              fullWidth
              name="serviceId"
              label={t("field.serviceIdPlaceholderRequired")}
              value={formik.values?.serviceId || ""}
              items={services}
              disabled
            />
          </RowContainer>
        )}

        {(offerDetailsLevelId === ARRANGEMENT ||
          offerDetailsLevelId === TRANSPORT) && (
          <RowContainer>
            <SelectInput
              fullWidth
              name="transportationTypeId"
              label={t("field.transportatopnTypeIdPlaceholderRequired")}
              value={formik.values?.transportationTypeId || ""}
              items={transportationTypes}
              style={{ marginRight: "24px" }}
              disabled
            />
            <Box style={{ width: "100%" }}></Box>
          </RowContainer>
        )}

        <Divider style={{ marginBottom: 24 }} />

        <RowContainer>
          <SelectInput
            fullWidth
            name="priceId"
            label={"Cenovnik*"}
            value={formik.values?.accommodationUnitTypeId || ""}
            disabled
          />
        </RowContainer>

        <Divider style={{ marginBottom: 24 }} />

        <RowContainer>
          <TextInput
            fullWidth
            multiline
            minRows={4}
            maxRows={8}
            name="travelProgram"
            label={t("field.travelProgramPlaceholder")}
            value={formik?.values?.travelProgram || ""}
            style={{ marginRight: "24px" }}
            disabled
          />

          <TextInput
            fullWidth
            multiline
            minRows={4}
            maxRows={8}
            name="description"
            label={t("field.descriptionPlaceholder")}
            value={formik?.values?.description || ""}
            disabled
          />
        </RowContainer>

        <RowContainer style={{ marginBottom: 0, justifyContent: "center" }}>
          <Button
            variant="outlined"
            onClick={handleClose}
            style={{ marginRight: "24px" }}
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            type="submit"
            onClick={handleConfirm}
          >
            {t("common.reserve")}
          </Button>
        </RowContainer>
      </Box>
    </CustomDialog>
  );
};

export default PreviewInternalSaleModal;
