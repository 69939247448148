import { Box } from "@mui/material";
import styled from "styled-components";

export const DialogContainer = styled(Box)`
  padding: 24px;
`;

export const DialogMessageContainer = styled(Box)`
  margin-bottom: 24px;
`;

export const DialogActionsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
`;
