/* eslint-disable react/prop-types */
import React from "react";
import { Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Stack } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";

const MultiSelectInput = ({ names, name, label, formik, selectedValues }) => {
  selectedValues = selectedValues ?? []
  return (
    <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
    <Select
      multiple
      fullWidth
      label={label}
      name={name}
      value={selectedValues}
      onChange={(e) => formik.setFieldValue(name, e.target.value)}
      input={<OutlinedInput label={label} />}
      renderValue={(selected) => {
        const filtered = names.filter(obj => selected?.includes(obj.value));
        return (
          <Stack gap={1} direction="row" flexWrap="wrap">
            {filtered.map((value) => (
              <Chip
                key={value.value}
                label={value.text}
                onDelete={() =>
                  formik.setFieldValue(
                    name,
                    selectedValues.filter((item) => item !== value.value)
                  )
                }
                deleteIcon={
                  <CancelIcon
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
              />
            ))}
          </Stack>
        );
      }}
    >
      {names && names.map((name) => (
        <MenuItem
          key={name.value}
          value={name.value}
          sx={{ justifyContent: "space-between" }}
        >
          {name.text}
          {selectedValues?.includes(name) ? <CheckIcon color="info" /> : null}
        </MenuItem>
      ))}
    </Select>
    </FormControl>
  );
};

export default MultiSelectInput;
