import { Box, Button, Typography } from "@mui/material";
import CustomTable from "components/CustomTable/CustomTable";
import AddAccommodationUnitModal from "components/Modals/AddAccommodationUnitModal/AddAccomodationUnitModal";
import ConfirmCloseDialog from "components/Modals/ConfirmCloseDialog/ConfirmCloseDialog";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import {
  useAccommodationUnitDetailsQuery,
  useAccommodationUnitListQuery,
  useAddAccommodationUnitMutation,
  useDeleteAccommodationUnitMutation,
  useEditAccommodationUnitMutation,
} from "features/accommodationUnit/accommodationUnitApiSlice";
import { useFormik } from "formik";
import useAccommodationUnitTable from "hooks/tables/useAccommodationUnitTable";
import accommodationUnitInitialValues from "initialValues/accommodationUnitInitialValues";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PageContainer, PageHeader } from "styles/SharedStyle.styled";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";

const AccommodationUnitPage = () => {
  const { t } = useTranslation();
  let params = useParams();

  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [editData, setEditData] = useState(null);
  const [closeConfirmOpen, setCloseConfirmOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState({ name: "name", direction: "asc" });

  const { data: accommodationUnits, isLoading: isLoadingAccommodationUnits } =
    useAccommodationUnitListQuery({
      accommodationId: params?.id,
      page,
      rowsPerPage,
      sort,
    });

  const {
    data: singleAccommodationUnit,
    isLoading: isLoadingSingleAccommodationUnit,
  } = useAccommodationUnitDetailsQuery(
    { id: editData?.id },
    { skip: !openEdit }
  );

  const [addAccommodationUnit, { isLoading: isLoadingAddAccommodationUnit }] =
    useAddAccommodationUnitMutation();

  const [editAccommodationUnit, { isLoading: isLoadingEditAccommodationUnit }] =
    useEditAccommodationUnitMutation();
  const [
    deleteAccommodationUnit,
    { isLoading: isLoadingDeleteAccommodationUnit },
  ] = useDeleteAccommodationUnitMutation();

  const { columns } = useAccommodationUnitTable({
    setOpenDelete,
    setOpenEdit,
    setEditData,
    page,
    rowsPerPage,
  });

  const handleSubmit = (val) => {
    if (openAdd) {
      addAccommodationUnit({ ...val, accommodationId: Number(params.id) })
        .unwrap()
        .then(() => {
          if (!isLoadingAddAccommodationUnit) {
            formik.resetForm();
            setOpenAdd(false);
            makeToastMessage(t("accommodationUnit.addSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("accommodationUnit.addErrorMessage")));
    }

    if (openEdit) {
      editAccommodationUnit({ ...val, accommodationId: Number(params.id) })
        .unwrap()
        .then(() => {
          if (!isLoadingEditAccommodationUnit) {
            formik.resetForm();
            setEditData(null);
            setOpenEdit(false);
            makeToastMessage(t("accommodationUnit.editSuccessMessage"));
          }
        })
        .catch(() =>
          makeErrorToastMessage(t("accommodationUnit.updateErrorMessage"))
        );
    }
  };

  const handleConfirmDelete = () => {
    if (openDelete) {
      deleteAccommodationUnit({ id: editData.id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteAccommodationUnit) {
            setEditData(null);
            setOpenDelete(false);
            makeToastMessage(t("accommodationUnit.deleteSuccessMessage"));
          }
        })
        .catch(() =>
          makeErrorToastMessage(t("accommodationUnit.deleteErrorMessage"))
        );
    }
  };

  const handleCancelDelete = () => {
    setEditData(null);
    setOpenDelete(false);
  };

  const transformUnitProperties = (unitProperties) => {
    return unitProperties?.map(property => {
      if (typeof property === 'object') {
        return property?.id;
      }
      return property;
    });
  };

  const detailAccommodationUnitValues = {
    ...singleAccommodationUnit,
    unitProperties: transformUnitProperties(singleAccommodationUnit?.unitProperties),
  }

  const formik = useFormik({
    initialValues:openEdit
    ? isLoadingSingleAccommodationUnit
      ? accommodationUnitInitialValues
      : singleAccommodationUnit && detailAccommodationUnitValues
    : accommodationUnitInitialValues,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleClosAddEdit = (_event, action) => {
    if (action === "backdropClick" || action === "escapeKeyDown") {
      if (formik.dirty) {
        setCloseConfirmOpen(true);
      } else {
        if (openAdd) {
          setOpenAdd(false);
          formik.resetForm();
        } else {
          setOpenEdit(false);
          formik.resetForm();
          setEditData(null);
        }
      }
    }
  };

  const handleCloseBtn = () => {
    if (formik.dirty) {
      setCloseConfirmOpen(true);
    } else {
      if (openAdd) {
        setOpenAdd(false);
        formik.resetForm();
      } else {
        setOpenEdit(false);
        setEditData(null);
      }
    }
  };

  const handleCancel = () => {
    setCloseConfirmOpen(false);
  };

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseConfirmDialogAdd = () => {
    setOpenAdd(false);
    setCloseConfirmOpen(false);
    formik.resetForm();
  };

  const handleCloseConfirmDialogEdit = () => {
    setOpenEdit(false);
    setCloseConfirmOpen(false);
    formik.resetForm();
    setEditData(null);
  };

  return (
    <PageContainer>
      <AddAccommodationUnitModal
        open={openEdit ? openEdit : openAdd}
        handleClose={handleClosAddEdit}
        handleCloseBtn={handleCloseBtn}
        formik={formik}
        title={
          openEdit
            ? t("accommodationUnit.editTitle")
            : t("accommodationUnit.addTitle")
        }
      />
      <ConfirmCloseDialog
        open={closeConfirmOpen}
        handleClose={
          openEdit ? handleCloseConfirmDialogEdit : handleCloseConfirmDialogAdd
        }
        handleCancel={handleCancel}
        description={t("accommodationUnit.confirmClose")}
      />
      <ConfirmModal
        open={openDelete}
        handleConfirm={handleConfirmDelete}
        handleCancel={handleCancelDelete}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        description={t("accommodationUnit.confirmDelete")}
      />
      <Typography variant="h4" color="" style={{marginBottom: 24}}>
          {t("accommodationUnit.pageTitle")}
        </Typography>
      <PageHeader>
        <Box />
        <Button variant="contained" onClick={handleOpenAdd}>
          {t("accommodationUnit.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={accommodationUnits?.data}
        isLoading={isLoadingAccommodationUnits}
        count={accommodationUnits?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsName={t("accommodationUnit.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default AccommodationUnitPage;
