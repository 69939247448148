import { apiSlice } from "features/api/apiSlice";

export const categoryApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Category", "CategorySingle"],
  endpoints: (builder) => ({
    categoryList: builder.query({
      query: (args) => {
        const { page, rowsPerPage, search, sort } = args;
        return {
          url:
            "/category/list?" +
            new URLSearchParams({
              PageNumber: page + 1,
              RowsPerPage: rowsPerPage,
              Active: true,
              // Conditionally add to params object if keys exist
              ...(sort && { SortPropertyName: sort.name, Ascending: sort.direction === 'asc' ? true : false }),
              ...(search && { Name: search }),
              //   ...(sortDirection && { orderBy: sortDirection }),
            }),
        };
      },
      providesTags: ["Category"],
    }),
    categoryDropdown: builder.query({
      query: () => ({
        url: `/category/dropdown`,
      }),
      providesTags: ["Category"],
    }),
    categoryDetails: builder.query({
      query: ({ id }) => ({
        url: `/category/${id}`,
      }),
      providesTags: ["CategorySingle"],
    }),
    addCategory: builder.mutation({
      query: (data) => ({
        url: "/category/add",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["Category"],
    }),
    editCategory: builder.mutation({
      query: (data) => ({
        url: "/category/edit",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["Category", "CategorySingle"],
    }),
    deleteCategory: builder.mutation({
        query: (data) => ({
          url: "/category",
          method: "DELETE",
          body: { ...data },
        }),
        invalidatesTags: ["Category"],
      }),
  }),
});

export const {
  useCategoryListQuery,
  useCategoryDropdownQuery,
  useCategoryDetailsQuery,
  useAddCategoryMutation,
  useEditCategoryMutation,
  useDeleteCategoryMutation
} = categoryApiSlice;
