/* eslint-disable react/prop-types */
import { Button, Dialog, Typography } from "@mui/material";
import React from "react";
import {
  DialogActionsContainer,
  DialogContainer,
  DialogMessageContainer,
} from "./ConfirmModal.styled";

const ConfirmModal = ({
  open,
  handleConfirm,
  handleCancel,
  description,
  confirmLabel,
  cancelLabel,
  btnConfirm="error",
  btnCancel="primary"
}) => {
  return (
    <Dialog
      PaperProps={{ sx: { mt: "-300px", verticalAlign: "top" } }}
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "653px",
          },
        },
      }}
    >
      <DialogContainer>
        <DialogMessageContainer>
          <Typography>{description}</Typography>
        </DialogMessageContainer>
        <DialogActionsContainer>
          <Button
            variant="contained"
            onClick={handleConfirm}
            style={{ marginRight: "24px" }}
            color={btnConfirm}
          >
            {confirmLabel}
          </Button>
          <Button
            variant="outlined"
            onClick={handleCancel}
            color={btnCancel}
          >
            {cancelLabel}
          </Button>
        </DialogActionsContainer>
      </DialogContainer>
    </Dialog>
  );
};

export default ConfirmModal;
