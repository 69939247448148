/* eslint-disable react/prop-types */
import AddEditCodebookItemModal from "components/Modals/AddEditCodebookItemModal/AddEditCodebookItemModal";
import ConfirmCloseDialog from "components/Modals/ConfirmCloseDialog/ConfirmCloseDialog";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Codebooks = ({
  formik,
  editTitle,
  addTitle,
  labelTitle,
  confirmCloseDescription,
  confirmDeleteDescription,
  setEditData,
  openDelete,
  setOpenDelete,
  openEdit,
  setOpenEdit,
  openAdd,
  setOpenAdd,
  handleConfirmDelete,
  type,
}) => {
  const { t } = useTranslation();
  const [closeConfirmOpen, setCloseConfirmOpen] = useState(false);

  const handleClosAddEdit = (_event, action) => {
    if (action === "backdropClick" || action === "escapeKeyDown") {
      if (formik.dirty) {
        setCloseConfirmOpen(true);
      } else {
        if (openAdd) {
          setOpenAdd(false);
          formik.resetForm();
        } else {
          setOpenEdit(false);
          formik.resetForm();
          setEditData(null);
        }
      }
    }
  };

  const handleCloseBtn = () => {
    if (formik.dirty) {
      setCloseConfirmOpen(true);
    } else {
      if (openAdd) {
        setOpenAdd(false);
        formik.resetForm();
      } else {
        setOpenEdit(false);
        setEditData(null);
      }
    }
  };

  const handleCancelDelete = () => {
    setEditData(null);
    setOpenDelete(false);
  };

  const handleCloseConfirmDialogAdd = () => {
    setOpenAdd(false);
    setCloseConfirmOpen(false);
    formik.resetForm();
  };

  const handleCloseConfirmDialogEdit = () => {
    setOpenEdit(false);
    setCloseConfirmOpen(false);
    formik.resetForm();
    setEditData(null);
  };

  const handleCancel = () => {
    setCloseConfirmOpen(false);
  };

  return (
    <>
      <AddEditCodebookItemModal
        open={openEdit ? openEdit : openAdd}
        handleClose={handleClosAddEdit}
        handleCloseBtn={handleCloseBtn}
        formik={formik}
        title={openEdit ? editTitle : addTitle}
        labelTitle={labelTitle}
        type={type}
      />
      <ConfirmCloseDialog
        open={closeConfirmOpen}
        handleClose={
          openEdit ? handleCloseConfirmDialogEdit : handleCloseConfirmDialogAdd
        }
        handleCancel={handleCancel}
        description={confirmCloseDescription}
      />
      <ConfirmModal
        open={openDelete}
        handleConfirm={handleConfirmDelete}
        handleCancel={handleCancelDelete}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        description={confirmDeleteDescription}
      />
    </>
  );
};

export default Codebooks;
