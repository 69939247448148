import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import styled from "styled-components";

export const TabPanelContainer = styled(Box)``;

export const StyledTabs = styled(Tabs)({
  borderBottom: "1px solid #E8E8E8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#1D2939",
  },
  "& .MuiTabs-indicatorSpan": {
    backgroundColor: "#1D2939",
  },
});

export const StyledTab = styled((props) => <Tab disableRipple {...props} />)({
  textTransform: "none",
  width: "fit-content",
  marginRight: 15,
  color: "#667085",
  fontSize: "14px",
  lineHeight: "20px",
  "&:hover": {
    color: "#1890FF",
    opacity: 1,
    borderBottom: "3px solid #",
  },
  "&.Mui-selected": {
    color: "#1D2939",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#1D2939",
  },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#1D2939",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    color: "#1D2939",
  },
});
