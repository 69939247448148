import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const ContentContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const InformationContainer = styled(Box)`
  margin-bottom: 24px;
`;

export const InformationMainText = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  color: #344054;
`
export const FormContainer = styled(Box)`
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  padding: 24px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
`;