import { Box } from "@mui/system";
import styled from "styled-components";

export const PageContainer = styled(Box)`
  padding: 32px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled(Box)`
  margin-top: 32px;
`

export const RowContainer = styled(Box)`
  margin-bottom: 24px;
  display: flex;
  // add style for small devices
  @media (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

export const PageHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 24px;
`;