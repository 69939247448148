export default {
    accommodationId: "",
    accommodationTypeId: "",
    accommodationUnitTypeId: "",
    categoryId: "",
    serviceId: "",
    transportationTypeId: "",
    travelOrganizerId: "",
    offerTypeId: "",
    name: "",
    number: "",
    jid: "",
    locationId: "", 
    countryId: "",
  };
  