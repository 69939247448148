/* eslint-disable no-unused-vars */
import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { arrayToObject } from "util/helpers/arrayToObject";
import CreateIcon from "@mui/icons-material/Create";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

const useOffersTable = ({
  setOpenDelete,
  setOpenEdit,
  setOpenActivateDeactivate,
  setEditData,
  page,
  rowsPerPage,
}) => {
  const { t } = useTranslation();
  const columns = [
    {
      name: "",
      label: t("tableCols.agency.ordinalNumberLabel"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { width: "100px" }}),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMetadata, updateValue) => {
          return tableMetadata.rowIndex + 1 + page * rowsPerPage;
        },
      },
    },
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
        name: "number",
        label: "Broj ponude u sistemu",
        options: {
          filter: false,
          sort: false,
        },
      },
    {
      name: "name",
      label: "Naziv ponude",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "offerType",
      label: "Tip ponude",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "accommodation",
      label: "Objekat",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "accommodationUnitType",
      label: "Vrsta smeštajne jedinice",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "transportationType",
      label: "Vrsta prevoza",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "offerActive",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "updatedOn",
      label: "Poslednje izmene",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const rowData = arrayToObject(tableMeta.rowData, columns);
          return rowData.updatedOn ? format(new Date(rowData.updatedOn), "dd.MM.yyyy") : "";
        },
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        print: false,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const offer = arrayToObject(tableMeta.rowData, columns);
          return (
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip
                title="Izmeni"
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    
                    setEditData(offer);
                    setOpenEdit(true);
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  offer?.offerActive === t("tableCols.offer.offerStatusActive")
                    ? t("tableCols.offer.tooltip.deactivateOffer")
                    : t("tableCols.offer.tooltip.activateOffer")
                }
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    setEditData(offer);
                    setOpenActivateDeactivate(true);
                  }}
                >
                  {offer?.offerActive === t("tableCols.offer.offerStatusActive") ? <RemoveCircleIcon /> : <CheckIcon />}
                </IconButton>
              </Tooltip>
              <Tooltip title="Obriši" placement="top">
                <IconButton
                  onClick={() => {
                    setEditData(offer);
                    setOpenDelete(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];
  return { columns };
};

export default useOffersTable;
