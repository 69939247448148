/* eslint-disable react/prop-types */
import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  phoneNumberChars,
  preventNonNumericChars,
} from "util/helpers/preventNonNumericChars";
import CustomDialog from "../CustomDialog/CustomDialog";
import { RowContainer } from "styles/SharedStyle.styled";
import { useAgencyTypeQuery } from "features/agency-type/agencyTypeApiSlice";
import { useCountryDropdownQuery } from "features/country/countryApiSlice";
import { useLocationDropdownQuery } from "features/location/locationApiSlice";
import TextInput from "components/Inputs/TextInput";
import SelectInput from "components/Inputs/SelectInput";
import DatePick from "components/Inputs/DatePick";

const AddAgencyFormModal = ({ open, handleClose, formik, handleCloseBtn }) => {
  const { t } = useTranslation();
  const { data: agencyTypes } = useAgencyTypeQuery({}, { skip: !open });
  const { data: countries } = useCountryDropdownQuery({}, { skip: !open });
  const { data: locations } = useLocationDropdownQuery(
    { id: formik.values.countryId },
    { skip: !open || !formik.values.countryId }
  );

  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      handleCloseBtn={handleCloseBtn}
      title="Dodaj novu agenciju"
    >
      <Box component="form">
        <RowContainer>
          <TextInput
            fullWidth
            name="name"
            label={t("field.agencyNamePlaceholder")}
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            style={{ marginRight: "24px" }}
          />
          <SelectInput
            fullWidth
            name="agencyTypeId"
            label={t("field.agencyTypeIdPlaceholder")}
            onChange={formik.handleChange}
            value={formik.values.agencyTypeId}
            error={
              formik.touched.agencyTypeId && Boolean(formik.errors.agencyTypeId)
            }
            helperText={
              formik.touched.agencyTypeId && formik.errors.agencyTypeId
            }
            items={agencyTypes}
          />
        </RowContainer>

        <RowContainer>
          <TextInput
            fullWidth
            name="address"
            label={t("field.addressPlaceholder")}
            value={formik.values.address}
            onChange={formik.handleChange}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
            style={{ marginRight: "24px" }}
          />

          <SelectInput
            fullWidth
            name="countryId"
            label={t("field.countryIdPlaceholder")}
            onChange={formik.handleChange}
            value={formik.values.countryId}
            error={formik.touched.countryId && Boolean(formik.errors.countryId)}
            helperText={formik.touched.countryId && formik.errors.countryId}
            style={{ marginRight: "24px" }}
            items={countries}
          />
          <SelectInput
            fullWidth
            name="locationId"
            disabled={!formik.values.countryId}
            label={t("field.locationIdPlaceholder")}
            onChange={formik.handleChange}
            value={formik.values.locationId}
            error={
              formik.touched.locationId && Boolean(formik.errors.locationId)
            }
            helperText={formik.touched.locationId && formik.errors.locationId}
            items={locations}
          />
        </RowContainer>

        <RowContainer>
          <TextInput
            fullWidth
            name="email"
            label={t("field.emailPlaceholder")}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            style={{ marginRight: "24px" }}
          />
          <TextInput
            name="phoneNumber"
            fullWidth
            label={t("field.phoneNumberRequiredPlaceholder")}
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
            }
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            onKeyDown={phoneNumberChars}
            style={{ marginRight: "24px" }}
          />
          <TextInput
            fullWidth
            name="webAddress"
            label={t("field.webAddressPlaceholder")}
            value={formik.values.webAddress}
            onChange={formik.handleChange}
          />
        </RowContainer>

        <RowContainer>
          <TextInput
            fullWidth
            name="pib"
            label={t("field.pibPlaceholder")}
            value={formik.values.pib}
            onChange={formik.handleChange}
            error={formik.touched.pib && Boolean(formik.errors.pib)}
            helperText={formik.touched.pib && formik.errors.pib}
            onKeyDown={preventNonNumericChars}
            style={{ marginRight: "24px" }}
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 9);
            }}
          />
          <TextInput
            fullWidth
            name="legalIdentificationNumber"
            label={t("field.legalIdentificationNumberPlaceholder")}
            value={formik.values.legalIdentificationNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.legalIdentificationNumber &&
              Boolean(formik.errors.legalIdentificationNumber)
            }
            helperText={
              formik.touched.legalIdentificationNumber &&
              formik.errors.legalIdentificationNumber
            }
            onKeyDown={preventNonNumericChars}
            onInput={(e) => {
              e.target.value = e.target.value.toString().slice(0, 8);
            }}
            style={{ marginRight: "24px" }}
          />
          <TextInput
            fullWidth
            name="accountNumber"
            label={t("field.accountNumberPlaceholder")}
            value={formik.values.accountNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.accountNumber &&
              Boolean(formik.errors.accountNumber)
            }
            helperText={
              formik.touched.accountNumber && formik.errors.accountNumber
            }
            onKeyDown={preventNonNumericChars}
          />
        </RowContainer>

        <RowContainer>
          <TextInput
            fullWidth
            name="personInCharge"
            label={t("field.personInChargePlaceholder")}
            value={formik.values.personInCharge}
            onChange={formik.handleChange}
          />
        </RowContainer>

        {formik.values.agencyTypeId === 2 && (
          <RowContainer>
            <TextInput
              fullWidth
              name="licenceNumber"
              label={t("field.licenceNumberPlaceholder")}
              value={formik.values.licenceNumber}
              style={{ marginRight: "24px" }}
              onChange={formik.handleChange}
              error={
                formik.touched.licenceNumber &&
                Boolean(formik.errors.licenceNumber)
              }
              helperText={
                formik.touched.licenceNumber && formik.errors.licenceNumber
              }
            />
            <DatePick
              name="licenceNumberExpirationDate"
              label={t("field.licenceNumberExpirationDatePlaceholder")}
              value={formik.values.licenceNumberExpirationDate}
              onChange={(value) =>
                formik.setFieldValue(
                  "licenceNumberExpirationDate",
                  new Date(value),
                  true
                )
              }
              error={
                formik.touched.licenceNumberExpirationDate &&
                Boolean(formik.errors.licenceNumberExpirationDate)
              }
              helperText={
                formik.touched.licenceNumberExpirationDate &&
                formik.errors.licenceNumberExpirationDate
              }
            />
          </RowContainer>
        )}

        <RowContainer style={{ marginBottom: 0, justifyContent: "center" }}>
          <Button
            variant="outlined"
            onClick={handleCloseBtn}
            style={{ marginRight: "24px" }}
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!formik.dirty}
            onClick={formik.handleSubmit}
          >
            {t("common.save")}
          </Button>
        </RowContainer>
      </Box>
    </CustomDialog>
  );
};

export default AddAgencyFormModal;
