import React from "react";

const SalesPage = () => {
  return (
    <>
      <p>Sales page</p>
    </>
  );
};

export default SalesPage;