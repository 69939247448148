import { Box, Button } from "@mui/material";
import {
  FormContainer,
  InformationContainer,
  InformationMainText,
  ContentContainer,
} from "components/Forms/AgencyForm/AgencyForm.styled";
import PasswordInput from "components/Inputs/PasswordInput";
import { useChangePasswordMutation } from "features/auth/authApiSlice";
import { useFormik } from "formik";
import changePasswordInitialValues from "initialValues/changePasswordInitialValues";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import changePasswordValidation from "validations/changePasswordValidation";

const ChangePassword = () => {
  const { t } = useTranslation();
  const [changePassword, { isLoading: isLoadingChangePassword }] =
    useChangePasswordMutation();
  const handleSubmit = async (values) => {
    try {
      await changePassword(values)
        .unwrap()
        .then(() => {
          if (!isLoadingChangePassword) {
            formik.resetForm();
            makeToastMessage(t("profile.changePasswordSuccessMessage"));
          }
        });
    } catch (error) {
      makeErrorToastMessage(error.data.message);
    }
  };
  const formik = useFormik({
    initialValues: changePasswordInitialValues,
    validationSchema: changePasswordValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  return (
    <Box
      style={{ marginTop: 32 }}
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <FormContainer style={{ marginBottom: 24 }}>
        <InformationContainer>
          <InformationMainText>
            {t("profile.tabs.changePassword")}
          </InformationMainText>
        </InformationContainer>
        <RowContainer style={{ marginBottom: 24 }}>
          <PasswordInput
            fullWidth
            name="oldPassword"
            label={t("field.oldPasswordPlaceholder")}
            value={formik.values.oldPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
            }
            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
            style={{ marginRight: "24px" }}
          />
          <PasswordInput
            fullWidth
            name="newPassword"
            label={t("field.newPasswordPlaceholder")}
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
            helperText={formik.touched.newPassword && formik.errors.newPassword}
            style={{ marginRight: "24px" }}
          />
          <PasswordInput
            fullWidth
            name="confirmPassword"
            label={t("field.confirmPasswordPlaceholder")}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
        </RowContainer>
      </FormContainer>
      <ContentContainer>
        <InformationContainer />
        <RowContainer style={{ marginTop: 24, justifyContent: "center" }}>
          <Button
            onClick={() => formik.resetForm()}
            variant="outlined"
            style={{ marginRight: "24px" }}
          >
            {t("common.cancel")}
          </Button>
          <Button type="submit" variant="contained" disabled={!formik.dirty}>
            {t("common.save")}
          </Button>
        </RowContainer>
      </ContentContainer>
    </Box>
  );
};

export default ChangePassword;
