/* eslint-disable react/prop-types */
import { Button, MenuItem, TextField } from "@mui/material";
import AddEditCodebookItemModal from "components/Modals/AddEditCodebookItemModal/AddEditCodebookItemModal";
import { useAddLocationMutation } from "features/location/locationApiSlice";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import * as Yup from "yup";

import styled from "styled-components";

const LocationSelectContainer = styled(TextField)`
  @media (max-width: 600px) {
    margin-bottom: 24px;
  }
`

const LocationSelect = ({
  name,
  disabled = false,
  label,
  onChange,
  value,
  items,
  countryId,
  ...props
}) => {
  const [openAdd, setOpenAdd] = useState(false);
  const { t } = useTranslation();

  const [addLocation, { isLoading: isLoadingAddLocation }] =
    useAddLocationMutation();

  const handleSubmit = async (value) => {
    const { name } = value;
    try {
      await addLocation({ name, countryId })
        .unwrap()
        .then(() => {
          if (!isLoadingAddLocation) {
            formik.resetForm();
            setOpenAdd(false);
            makeToastMessage(t("location.addSuccessMessage"));
          }
        });
    } catch (error) {
      makeErrorToastMessage(error.data.message);
    }
  };

  const formik = useFormik({
    initialValues: { name: "" },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("field.countryRequired")),
    }),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });
  const handleCloseAdd = () => {
    setOpenAdd(false);
    formik.resetForm();
  };

  const handleCloseBtn = () => {
    setOpenAdd(false);
    formik.resetForm();
  };

  return (
    <>
      <AddEditCodebookItemModal
        open={openAdd}
        handleClose={handleCloseAdd}
        handleCloseBtn={handleCloseBtn}
        formik={formik}
        title={t("location.addTitle")}
        labelTitle={t("field.locationNamePlaceholder")}
      />
      <LocationSelectContainer
        name={name}
        select
        disabled={disabled}
        label={label}
        onChange={onChange}
        SelectProps={{
          MenuProps: {
            sx: { maxHeight: 300 },
          },
        }}
        value={value}
        InputLabelProps={{
          shrink: value,
        }}
        {...props}
      >
        <Button
        fullWidth
        variant="contained"
          onClick={() => {
            setOpenAdd(true);
          }}
        >
          Dodaj mesto
        </Button>
        {items &&
          items?.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.text}
            </MenuItem>
          ))}
      </LocationSelectContainer>
    </>
  );
};

export default LocationSelect;
