import TabsComponent from "components/Tab/Tabs";
import React from "react";
import AgencyTab from "./AgencyTab";
import { PageContainer } from "styles/SharedStyle.styled";
import ListAddLicence from "components/List/ListAddLicence";
import { useParams } from "react-router-dom";
import ListAddUser from "components/List/ListAddUser";
import { useTranslation } from "react-i18next";

const AdminAgencyPage = () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const tabs = [
    {
      label: t("admin.tabs.agency"),
      component: <AgencyTab />,
    },
    {
      label: t("admin.tabs.users"),
      component: <ListAddUser id={id} />,
    },
    {
      label: t("admin.tabs.licences"),
      component: <ListAddLicence id={id} />,
    },
  ];

  return (
    <PageContainer>
      <TabsComponent tabs={tabs} />
    </PageContainer>
  );
};

export default AdminAgencyPage;
