import * as Yup from "yup";
import i18next from "i18next";

export default Yup.object().shape({
  name: Yup.string().required(i18next.t("field.nameRequired")),
  surname: Yup.string().required(i18next.t("field.surnameRequired")),
  email: Yup.string()
    .email(i18next.t("field.emailFormat"))
    .required(i18next.t("field.emailRequired")),
  password: Yup.string()
    .required(i18next.t("field.passwordRequired"))
    .min(8, i18next.t("field.passwordMin"))
    .matches(/[A-Z]/, i18next.t("field.passwordUpperCaseRequired"))
    .matches(/[!@#$%^&*]/, i18next.t("field.passwordSpecialCharRequired")),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], i18next.t("field.passwordMatch"))
    .required(i18next.t("field.confirmPasswordRequired")),
  roleId: Yup.number().required(i18next.t("field.roleIdRequired")),
});
