import { useMemo } from "react";
import { useSelector } from "react-redux";
import { CLERK_ROLE, MANAGER_ROLE, SUPERADMIN_ROLE } from "constants/roleConstants";
import { selectCurrentAgencyType, selectCurrentRole, selectCurrentToken } from "features/auth/authSlice";
import { OTP_TYPE, PTP_TYPE } from "constants/agencyType";

const useAuth = () => {
  const jwtToken = useSelector(selectCurrentToken);
  const role = useSelector(selectCurrentRole);
  const agencyType = useSelector(selectCurrentAgencyType);

  const isLoggedIn = useMemo(() => {
    if (jwtToken) return true;
    return false;
  }, [jwtToken]);

  const isSuperAdmin = useMemo(() => {
    if (role === SUPERADMIN_ROLE) return true;
    return false;
  }, [role]);

  const isManager = useMemo(() => {
    if (role === MANAGER_ROLE) return true;
    return false;
  }, [role]);

  const isClerk = useMemo(() => {
    if (role === CLERK_ROLE) return true;
    return false;
  }, [role]);

  const isOtpAgency = useMemo(() => {
    if (agencyType === OTP_TYPE) return true;
    return false;
  }, [agencyType]);

  const isPtpAgency = useMemo(() => {
    if (agencyType === PTP_TYPE) return true;
    return false;
  }, [agencyType]);


  const hasRole = (roleName) => role === roleName;

  const isLoaded = useMemo(() => {
    if (isLoggedIn !== undefined) return true;
    return false;
  }, [isLoggedIn]);

  return {
    isLoggedIn,
    isLoaded,
    isManager,
    isSuperAdmin,
    isClerk,
    isOtpAgency,
    isPtpAgency,
    hasRole,
    role,
    agencyType
  };
};

export default useAuth;