import { apiSlice } from "features/api/apiSlice";

export const locationApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Location, LocationSingle"],
  endpoints: (builder) => ({
    locationDropdown: builder.query({
      query: ({ id }) => ({
        url: `/location/dropdown?countryId=${id}`,
      }),
      providesTags: ["LocationsDropdown"],
    }),
    locationList: builder.query({
      query: (args) => {
        const { page, rowsPerPage, sort, filter, search } = args;
        return {
          url:
            "/location/list?" +
            new URLSearchParams({
              PageNumber: page + 1,
              RowsPerPage: rowsPerPage,
              Active: true,
              // Conditionally add to params object if keys exist
              ...(sort && {
                SortPropertyName: sort.name,
                Ascending: sort.direction === "asc" ? true : false,
              }),
              ...(search && { Name: search }),
              //   ...(sortDirection && { orderBy: sortDirection }),
              ...(filter && filter),
            }),
        };
      },
      providesTags: ["Location"],
    }),
    locationDetails: builder.query({
      query: ({ id }) => ({
        url: `/location/${id}`,
      }),
      providesTags: ["LocationSingle"],
    }),
    addLocation: builder.mutation({
      query: (data) => ({
        url: "/location/add",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["Location", "LocationsDropdown"],
    }),
    editLocation: builder.mutation({
      query: (data) => ({
        url: "/location/edit",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["Location", "LocationSingle"],
    }),
    deleteLocation: builder.mutation({
      query: (data) => ({
        url: "/location",
        method: "DELETE",
        body: { ...data },
      }),
      invalidatesTags: ["Location"],
    }),
  }),
});

export const {
  useLocationDropdownQuery,
  useLocationListQuery,
  useLocationDetailsQuery,
  useAddLocationMutation,
  useEditLocationMutation,
  useDeleteLocationMutation,
} = locationApiSlice;
