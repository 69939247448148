import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import React from "react";

const Search = ({ label, search, handleChange }) => {
  
  return (
    <TextField
      name="search"
      style={{ width: "300px" }}
      size="small"
      label={label}
      value={search}
      onChange={handleChange}
    />
  );
};

Search.propTypes = {
  label: PropTypes.string,
  search: PropTypes.string,
  handleChange: PropTypes.func,
};

export default Search;
