import { Button, Typography } from "@mui/material";
import CustomTable from "components/CustomTable/CustomTable";
import AddOfferModal from "components/Modals/AddOfferModal/AddOfferModal";
import ConfirmCloseDialog from "components/Modals/ConfirmCloseDialog/ConfirmCloseDialog";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import { useFormik } from "formik";
import useOffersTable from "hooks/tables/useOffersTable";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageContainer, PageHeader } from "styles/SharedStyle.styled";
import { getNonEmptyProps } from "util/helpers/objectHelpers";

import {
  useOfferListQuery,
  useOfferDetailsQuery,
  useAddOfferMutation,
  useEditOfferMutation,
  useDeleteOfferMutation,
  useToggleStatusOfferMutation,
} from "features/offer/offerApiSlice";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import Filter from "components/Filter/Filter";
import OfferFilter from "components/Filter/OfferFilter/OfferFilter";
import { useSelector } from "react-redux";
import { selectCurrentAgency } from "features/auth/authSlice";
import { useOfferTypeDropdownQuery } from "features/offerType/offerTypeApiSlice";
import { ACCOMMODATION, ARRANGEMENT, TRANSPORT } from "constants/offerDetailsLevel";
import arrangementOfferValidation from "validations/arrangementOfferValidation";
import accommodationOfferValidation from "validations/accommodationOfferValidation";
import transportationOfferValidation from "validations/transportationOfferValidation";
import { valueUpdate } from "./offerHelpers";
import offerInitialValues from "initialValues/offerInitialValues";

const OffersPage = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState(null);
  const [filter, setFilter] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [editData, setEditData] = useState(null);
  const [closeConfirmOpen, setCloseConfirmOpen] = useState(false);
  const [openActivateDeactivate, setOpenActivateDeactivate] = useState(false);
  const [offerDetailsLevelId, setOfferDetailsLevelId] = useState(null);
  const agencyId = useSelector(selectCurrentAgency);
  const { columns } = useOffersTable({
    setOpenDelete,
    setOpenEdit,
    setEditData,
    setOpenActivateDeactivate,
    page,
    rowsPerPage,
  });
  const { data: offerTypes } =
    useOfferTypeDropdownQuery({}, { skip: openEdit ? !openEdit : !openAdd });

  const { data: offerList, isLoading: isLoadingOfferList } = useOfferListQuery({
    page,
    rowsPerPage,
    sort,
    filter,
  });
  const [toggleStatusOffer, { isLoading: isLoadingToggleStatusOffer }] =
    useToggleStatusOfferMutation();

  let newData = offerList?.data.map((obj) => ({
    ...obj,
    offerActive: obj.active
      ? t("tableCols.offer.offerStatusActive")
      : t("tableCols.offer.offerStatusInactive"),
  }));
  
  const validationSchemas = {
    [ARRANGEMENT]: arrangementOfferValidation,
    [ACCOMMODATION]: accommodationOfferValidation,
    [TRANSPORT]: transportationOfferValidation,
  };

  const [createOffer, { isLoading: isLoadingCreateOffer }] =
    useAddOfferMutation();
  const [deleteOffer, { isLoading: isLoadingDeleteOffer }] =
    useDeleteOfferMutation();
  const [editOffer, { isLoading: isLoadingEditOffer }] = useEditOfferMutation();
  const { data: offerDetails, isLoading: isLoadingOfferDetails } =
    useOfferDetailsQuery({ id: editData?.id }, { skip: !openEdit });


  const handleSubmit = async (values) => {
    if (openAdd) {
      createOffer(valueUpdate(values, offerDetailsLevelId, agencyId))
        .unwrap()
        .then(() => {
          if (!isLoadingCreateOffer) {
            formik.resetForm();
            setOpenAdd(false);
            makeToastMessage(t("offers.addSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("offers.addErrorMessage")));
    }

    if (openEdit) {
      editOffer({...valueUpdate(values, offerDetailsLevelId), id: editData.id})
        .unwrap()
        .then(() => {
          if (!isLoadingEditOffer) {
            formik.resetForm();
            setOpenEdit(false);
            setEditData(null);
            makeToastMessage(t("offers.editSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("offers.editErrorMessage")));
    }
  };

  const handleFilter = (values) => {
    setPage(0);
    setFilter(getNonEmptyProps(values));
  };

  const formik = useFormik({
    initialValues:
      openEdit && !isLoadingOfferDetails
        ? { ...offerDetails, travelOrganizerId: agencyId }
        : {...offerInitialValues, travelOrganizerId: agencyId},
    validationSchema: offerDetailsLevelId !== null ? validationSchemas[offerDetailsLevelId] : arrangementOfferValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (formik.values.offerTypeId) {
      setOfferDetailsLevelId(
        offerTypes?.find((item) => item.value === formik.values.offerTypeId)
          ?.offerDetailsLevelId
      );
    } else {
      setOfferDetailsLevelId(null);
    }
  }, [formik.values.offerTypeId]);

  const filterForm = useFormik({
    initialValues: {
      OfferTypeId: "",
      AccommodationId: "",
      TransportationTypeId: "",
      ServiceId: "",
      CategoryId: "",
      Active: "",
    },
    onSubmit: handleFilter,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleOpenAddClient = () => {
    setOpenAdd(true);
  };

  const handleCloseBtn = () => {
    if (formik.dirty) {
      setCloseConfirmOpen(true);
    } else {
      if (openAdd) {
        setOpenAdd(false);
        formik.resetForm();
      } else if (openEdit) {
        setOpenEdit(false);
        setEditData(null);
      } else if (openActivateDeactivate) {
        setOpenActivateDeactivate(false);
        setEditData(undefined);
      }
    }
  };

  const handleCloseConfirmDialogAdd = () => {
    setOpenAdd(false);
    setCloseConfirmOpen(false);
    formik.resetForm();
  };

  const handleCloseConfirmDialogEdit = () => {
    setOpenEdit(false);
    setCloseConfirmOpen(false);
    formik.resetForm();
    setEditData(null);
  };

  const handleCancel = () => {
    setCloseConfirmOpen(false);
  };

  const handleCancelConfirm = () => {
    if (openDelete) {
      setEditData(null);
      setOpenDelete(false);
    }
    if (openActivateDeactivate) {
      setOpenActivateDeactivate(false);
      setEditData(undefined);
    }
  };

  const handleConfirm = () => {
    if (openDelete) {
      deleteOffer({ id: editData.id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteOffer) {
            setEditData(null);
            setOpenDelete(false);
            makeToastMessage(t("offers.deleteSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("offers.deleteErrorMessage")));
    }

    if (openActivateDeactivate) {
      toggleStatusOffer({
        id: editData.id,
      })
        .unwrap()
        .then(() => {
          if (!isLoadingToggleStatusOffer) {
            setEditData(undefined);
            setOpenActivateDeactivate(false);
            makeToastMessage(t("offers.toggleStatusOfferSuccessMessage"));
          }
        })
        .catch(() =>
          makeErrorToastMessage(t("offers.toggleStatusOfferErrorMessage"))
        );
    }
  };

  return (
    <PageContainer>
      <ConfirmCloseDialog
        open={closeConfirmOpen}
        handleClose={
          openEdit ? handleCloseConfirmDialogEdit : handleCloseConfirmDialogAdd
        }
        handleCancel={handleCancel}
        description={t("offers.confirmClose")}
      />
      <AddOfferModal
        open={openEdit ? openEdit : openAdd}
        formik={formik}
        handleCloseBtn={handleCloseBtn}
        edit={openEdit}
        title={openEdit ? t("offers.editTitle") : t("offers.addTitle")}
        setOfferDetailsLevelId={setOfferDetailsLevelId}
        offerDetailsLevelId={offerDetailsLevelId}
      />
      <ConfirmModal
        open={openDelete || openActivateDeactivate}
        handleCancel={handleCancelConfirm}
        handleConfirm={handleConfirm}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        description={
          openActivateDeactivate
            ? t("offers.confirmOfferStatus", {
                status:
                  editData.offerActive ===
                  t("tableCols.offer.offerStatusActive")
                    ? t("offers.deactivateOffer")
                    : t("offers.activateOffer"),
              })
            : openDelete
            ? t("offers.confirmDelete")
            : ""
        }
      />
      <Typography variant="h4" color="" style={{ marginBottom: 24 }}>
        {t("offers.pageTitle")}
      </Typography>
      <PageHeader>
        <Filter
          filterForm={filterForm}
          handleReset={() => {
            setFilter(null);
            filterForm.resetForm();
          }}
        >
          <OfferFilter filterForm={filterForm} />
        </Filter>
        <Button variant="contained" onClick={handleOpenAddClient}>
          {t("offers.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={newData}
        isLoading={isLoadingOfferList}
        count={offerList?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsName={t("offers.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default OffersPage;
