/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useFormik } from "formik";
import addUserInitialValues from "initialValues/addUserInitialValues";
import addUserValidation from "validations/addUserValidation";
import AddEditUserFormModal from "components/Modals/AddUserModal/AddEditUserModal";
import ConfirmCloseDialog from "components/Modals/ConfirmCloseDialog/ConfirmCloseDialog";
import { Button } from "@mui/material";
import CustomTable from "components/CustomTable/CustomTable";
import editUserValidation from "validations/editUserValidation";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import useUserTable from "hooks/tables/useUserTable";
import ResetPasswordModal from "components/Modals/ResetPasswordModal/ResetPasswordModal";
import resetPasswordInitialValues from "initialValues/resetPasswordInitialValues";
import resetPasswordValidation from "validations/resetPasswordValidation";
import { useTranslation } from "react-i18next";
import {
  useAddUserMutation,
  useDeleteUserMutation,
  useEditPasswordMutation,
  useEditUserMutation,
  useToggleStatusUserMutation,
  useUserDetailsQuery,
  useUserListQuery,
} from "features/user/userApiSlice";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import { ButtonContainer, ContentContainer, PageHeader } from "styles/SharedStyle.styled";
import useAuth from "hooks/useAuth";
import Filter from "components/Filter/Filter";
import UserFilter from "components/Filter/UserFilter/UserFilter";
import { getNonEmptyProps } from "util/helpers/objectHelpers";

const ListAddUser = ({ id }) => {
  const { isClerk } = useAuth();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState(null);
  const [editData, setEditData] = useState(null);
  const [editUserId, setEditUserId] = useState(undefined);
  const [filter, setFilter] = useState(null)
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [closeConfirmOpen, setCloseConfirmOpen] = useState(false);
  const [openBlockUser, setOpenBlockUser] = useState(false);
  const [openDeleteUser, setOpenDeleteUser] = useState(false);
  const [openResetPassword, setOpenResetPassword] = useState(false);

  const { columns } = useUserTable({
    setEditData,
    setEditUserId,
    setOpenBlockUser,
    setOpenDeleteUser,
    setOpenEditUser,
    setOpenResetPassword,
    page,
    rowsPerPage,
  });

  const [addUser, { isLoading: isLoadingAddUser }] = useAddUserMutation();
  const [editUser, { isLoading: isLoadingEditUser }] = useEditUserMutation();
  const [editUserPassword, { isLoading: isLoadingEditUserPassword }] =
    useEditPasswordMutation();
  const [toggleStatusUser, { isLoading: isLoadingToggleStatus }] =
    useToggleStatusUserMutation();

  const [deleteUser, { isLoading: isLoadingDeleteUser }] =
    useDeleteUserMutation();

  const { data: userList, isLoading: isLoadingUserList } = useUserListQuery({
    agencyId: id,
    page,
    rowsPerPage,
    sort,
    filter
  });

  const { data: userDetails, isLoading: isLoadingUserDetails } =
    useUserDetailsQuery({ id: editUserId }, { skip: !openEditUser });

    let newData = userList?.data.map((obj) => ({
      ...obj,
      userActive: obj.userActive
        ? t("tableCols.user.userStatusActive")
        : t("tableCols.user.userStatusInactive"),
    }));

  const handleSubmit = (val) => {
    if (openAddUser) {
      addUser({
        ...val,
        travelAgencyId: Number(id),
      })
        .unwrap()
        .then(() => {
          if (!isLoadingAddUser) {
            addEditUserFormik.resetForm();
            setOpenAddUser(false);
            makeToastMessage(t("users.addSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("users.addErrorMessage")));
    }

    if (openEditUser) {
      editUser({
        id: Number(editUserId),
        travelAgencyId: Number(id),
        roleId: val.roleId,
        name: val.name,
        surname: val.surname,
        phoneNumber: val.phoneNumber,
        office: val.office,
        email: val.email,
      })
        .unwrap()
        .then(() => {
          if (!isLoadingEditUser) {
            addEditUserFormik.resetForm();
            setEditUserId(null);
            setEditData(null);
            setOpenEditUser(false);
            makeToastMessage(t("users.editSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("users.editErrorMessage")));
    }
  };

  const handleSubmitResetPassword = (val) => {
    editUserPassword({
      ...val,
      id: editUserId,
    })
      .unwrap()
      .then(() => {
        if (!isLoadingEditUserPassword) {
          resetPasswordFormik.resetForm();
          setOpenResetPassword(false);
          makeToastMessage(t("users.passwordChangeSuccessMessage"));
        }
      })
      .catch(() =>
        makeErrorToastMessage(t("users.passwordChangeErrorMessage"))
      );
  };

  const handleFilter = (values) => {
    setPage(0);
    setFilter(getNonEmptyProps(values));
  };

  const addEditUserFormik = useFormik({
    initialValues: openEditUser
      ? isLoadingUserDetails
        ? addUserInitialValues
        : userDetails
      : addUserInitialValues,
    validationSchema: openEditUser ? editUserValidation : addUserValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const resetPasswordFormik = useFormik({
    initialValues: resetPasswordInitialValues,
    validationSchema: resetPasswordValidation,
    onSubmit: handleSubmitResetPassword,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const filterForm = useFormik({
    initialValues: { Active: "", Name: "", Surname: "", RoleId: "" },
    onSubmit: handleFilter,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleCloseModal = (_event, action) => {
    if (action === "backdropClick" || action === "escapeKeyDown") {
      if (addEditUserFormik.dirty || resetPasswordFormik.dirty) {
        setCloseConfirmOpen(true);
      } else {
        if (openAddUser) {
          setOpenAddUser(false);
        } else if (openEditUser) {
          setOpenEditUser(false);
        } else {
          setOpenResetPassword(false);
          resetPasswordFormik.resetForm();
        }
      }
    }
  };

  const handleCloseConfirmDialog = () => {
    setOpenAddUser(false);
    setCloseConfirmOpen(false);
    addEditUserFormik.resetForm();
  };

  const handleCloseConfirmDialogEdit = () => {
    setOpenEditUser(false);
    setCloseConfirmOpen(false);
    addEditUserFormik.resetForm();
    setEditData(undefined);
    setEditUserId(null);
  };

  const handleCloseConfrimDialogResetPassword = () => {
    setOpenResetPassword(false);
    setCloseConfirmOpen(false);
    addEditUserFormik.resetForm();
    setEditUserId(undefined);
    resetPasswordFormik.resetForm()
  };

  const handleOpenAddUser = () => {
    setOpenAddUser(true);
  };

  const handleCancel = () => {
    setCloseConfirmOpen(false);
  };

  const handleCloseBtn = () => {
    if (addEditUserFormik.dirty || resetPasswordFormik.dirty) {
      setCloseConfirmOpen(true);
    } else {
      if (openAddUser) {
        setOpenAddUser(false);
      } else if (openEditUser) {
        setOpenEditUser(false);
        setEditData(undefined);
        setEditUserId(null);
      } else if (openBlockUser) {
        setOpenBlockUser(false);
        setEditData(undefined);
      } else {
        setOpenResetPassword(false);
        setEditUserId(null);
        resetPasswordFormik.resetForm()
      }
    }
  };

  const handleCancelConfirm = () => {
    if (openBlockUser) {
      setOpenBlockUser(false);
      setEditData(undefined);
    }

    if (openDeleteUser) {
      setOpenDeleteUser(false);
      setEditUserId(null);
    }
  };

  const handleConfirm = () => {
    if (openBlockUser) {
      toggleStatusUser({
        id: editData.id,
      })
        .unwrap()
        .then(() => {
          if (!isLoadingToggleStatus) {
            setEditData(undefined);
            setOpenBlockUser(false);
            makeToastMessage(t("users.toggleSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("users.toggleErrorMessage")));
    }

    if (openDeleteUser) {
      deleteUser({
        id: editUserId,
      })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteUser) {
            setEditUserId(null);
            setOpenDeleteUser(false);
            makeToastMessage(t("users.deleteSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("users.deleteErrorMessage")));
    }
  };

  

  return (
    <ContentContainer>
      <AddEditUserFormModal
        open={openEditUser ? openEditUser : openAddUser}
        handleClose={handleCloseModal}
        formik={addEditUserFormik}
        handleCloseBtn={handleCloseBtn}
        edit={openEditUser}
        editUserId={editUserId}
        title={
          openEditUser
            ? t("users.editTitle")
            : openAddUser
            ? t("users.addTitle")
            : ""
        }
      />
      <ResetPasswordModal
        open={openResetPassword}
        formik={resetPasswordFormik}
        handleCloseBtn={handleCloseBtn}
        handleClose={handleCloseModal}
      />
      <ConfirmCloseDialog
        open={closeConfirmOpen}
        handleClose={
          openEditUser
            ? handleCloseConfirmDialogEdit
            : openResetPassword
            ? handleCloseConfrimDialogResetPassword
            : handleCloseConfirmDialog
        }
        handleCancel={handleCancel}
        description={t('users.cancelConfirm')}
      />
      <ConfirmModal
        open={openBlockUser || openDeleteUser}
        handleCancel={handleCancelConfirm}
        handleConfirm={handleConfirm}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        btnColor={openBlockUser && editData.userActive === t("tableCols.user.userStatusInactive") ? 'primary' : 'error'}
        description={
          openBlockUser
            ? t("users.confirmUserStatus", {
                status: editData.userActive === t("tableCols.user.userStatusActive")
                  ? t("users.deactivateUser")
                  : t("users.activateUser"),
              })
            : openDeleteUser
            ? t("users.confirmDeleteUser")
            : ""
        }
      />
      <PageHeader>
        <Filter filterForm={filterForm} handleReset={() => {
              setFilter(null);
              filterForm.resetForm();
            }}>
          <UserFilter filterForm={filterForm} />
        </Filter>
      {!isClerk && (
        <ButtonContainer>
          <Button variant="contained" onClick={handleOpenAddUser}>
            {t("users.addButton")}
          </Button>
        </ButtonContainer>
      )}
      </PageHeader>
      <CustomTable
        columns={columns}
        data={newData}
        count={userList?.totalCount}
        isLoading={isLoadingUserList}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setSort={setSort}
        rowsName={t("users.rowsName")}
      />
    </ContentContainer>
  );
};

export default ListAddUser;
