import { Box, Button } from "@mui/material";
import {
  FormContainer,
  InformationContainer,
  InformationMainText,
} from "components/Forms/AgencyForm/AgencyForm.styled";
import TextInput from "components/Inputs/TextInput";
import React from "react";
import { useTranslation } from "react-i18next";
import { ContentContainer, RowContainer } from "styles/SharedStyle.styled";

const PrintTab = () => {
  const { t } = useTranslation();
  return (
    <ContentContainer component="form">
      <FormContainer>
        <InformationContainer>
          <InformationMainText>
            {t("settings.printTab.info")}
          </InformationMainText>
        </InformationContainer>
        <Box style={{display: 'flex', flexDirection: 'column', maxWidth: '100%', overflow: 'hidden'}}>
        <TextInput multiline minRows={5} placeholder="Zaglavlje dokumenta" style={{marginBottom: 24 }} />
        <TextInput multiline minRows={5} placeholder="Podrazumevani opis rezervacije" style={{marginBottom: 24 }} />
        <TextInput multiline minRows={5} placeholder="Podrazumevani opis računa" style={{marginBottom: 24 }} />
        <TextInput multiline minRows={5} placeholder="Podrazumevani opis priznanice" style={{marginBottom: 24 }} />
        </Box>
        
      </FormContainer>
      <ContentContainer>
          <InformationContainer></InformationContainer>
          <RowContainer style={{ marginTop: 24, justifyContent: "flex-end" }}>
            <Button
              onClick={() => {}}
              style={{ marginRight: "24px" }}
              variant="outlined"
            >
              {t("common.cancel")}
            </Button>
            <Button type="submit" variant="contained">
              {t("common.save")}
            </Button>
          </RowContainer>
        </ContentContainer>
    </ContentContainer>
  );
};

export default PrintTab;
