import { ACCOMMODATION, ARRANGEMENT, TRANSPORT } from "constants/offerDetailsLevel";

export const valueUpdate = (values, offerDetailsLevel, agencyId) => {
    if (offerDetailsLevel === ACCOMMODATION) {
      return {
        accommodationId: values.accommodationId,
        accommodationTypeId: values.accommodationTypeId,
        accommodationUnitTypeId: values.accommodationUnitTypeId,
        categoryId: values.categoryId,
        priceId: 1,
        serviceId: values.serviceId,
        travelOrganizerId: agencyId,
        offerTypeId: values.offerTypeId,
        name: values.name,
        number: Number(values.number),
        travelProgram: values.travelProgram,
        description: values.description,
        jid: values.jid,
        locationId: values.locationId,
        countryId: values.countryId,
      };
    }
    if (offerDetailsLevel === ARRANGEMENT) {
      return {
        accommodationId: values.accommodationId,
        accommodationTypeId: values.accommodationTypeId,
        accommodationUnitTypeId: values.accommodationUnitTypeId,
        categoryId: values.categoryId,
        priceId: 1,
        serviceId: values.serviceId,
        transportationTypeId: values.transportationTypeId,
        travelOrganizerId: agencyId,
        offerTypeId: values.offerTypeId,
        name: values.name,
        number: Number(values.number),
        travelProgram: values.travelProgram,
        description: values.description,
        jid: values.jid,
        locationId: values.locationId,
        countryId: values.countryId,
      };
    }
    if (offerDetailsLevel === TRANSPORT) {
      return {
        categoryId: values.categoryId,
        priceId: 1,
        transportationTypeId: values.transportationTypeId,
        travelOrganizerId: agencyId,
        offerTypeId: values.offerTypeId,
        name: values.name,
        number: Number(values.number),
        travelProgram: values.travelProgram,
        description: values.description,
        jid: values.jid,
        locationId: values.locationId,
        countryId: values.countryId,
      };
    }
  }