import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const ButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 24px;
`;

export const FilterSearchContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
