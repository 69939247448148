import React from "react";
import { Route, Routes } from "react-router-dom";

import LoginPage from "./pages/LoginPage/LoginPage";
import HomePage from "pages/HomePage/HomePage";
import ProfilePage from "pages/ProfilePage/ProfilePage";
import SettingsPage from "pages/SettingsPage/SettingsPage";
import Error from "pages/ErrorPage/ErrorPage";
import { PAGES } from "constants/pages";
import ClientsPage from "pages/ClientsPage/ClientsPage";
import PartnersPage from "pages/PartnersPage/PartnersPage";
import OffersPage from "pages/OffersPage/OffersPage";
import SalesPage from "pages/SalesPage/SalesPage";
import PaymentPage from "pages/PaymentPage/PaymentPage";
import ExcerptsPage from "pages/ExcerptsPage/ExcerptsPage";
import ReportsPage from "pages/ReportsPage/ReportsPage";
import NotificationsPage from "pages/NotificationsPage/NotificationsPage";
import AdminAgencyPage from "pages/AdminAgencyPage/AdminAgencyPage";
import CategoryPage from "pages/CategoryPage/CategoryPage";
import CountryPage from "pages/CountryPage/CountryPage";
import LocationPage from "pages/LocationPage/LocationPage";
import AccommodationTypePage from "pages/AccommodationTypePage/AccommodationTypePage";
import AccommodationUnitTypePage from "pages/AccommodationUnitTypePage/AccommodationUnitTypePage";
import ServicesPage from "pages/ServicesPage/ServicesPage";
import AccommodationPage from "pages/AccommodationPage/AccommodationPage";
import ChargePage from "pages/ChargePage/ChargePage";
import ReceiptsPage from "pages/ReceiptsPage/ReceiptsPage";
import VirmanPage from "pages/VirmanPage/VirmanPage";
import SupplierPaymentPage from "pages/SupplierPaymentPage/SupplierPaymentPage";
import ExchangeRatePage from "pages/ExchangeRatePage/ExchangeRatePage";
import AuthRoute from "components/Router/AuthRoute";
import {
  CLERK_ROLE,
  MANAGER_ROLE,
  SUPERADMIN_ROLE,
} from "constants/roleConstants";
import AccommodationUnitPropertyPage from "pages/AccommodationUnitPropertyPage/AccommodationUnitPropertyPage";
import TransportationTypePage from "pages/TransportationTypePage/TransportationTypePage";
import OfferTypePage from "pages/OfferTypePage/OfferTypePage";
import AccommodationUnitPage from "pages/AccommodatiionUnitPage/AccommodationUnitPage";
import { OTP_TYPE } from "constants/agencyType";
import InternalSalePage from "pages/InternalSalePage/InternalSalePage";
import ContractsPage from "pages/ContractsPage/ContractsPage";
import SingleContractPage from "pages/SingleContractPage/SingleContractPage";

const AppRoutes = () => (
  <Routes>
    <Route
      path={PAGES.LOGIN.route}
      element={<LoginPage />}
      errorElement={<Error />}
    />
    <Route
      element={<AuthRoute roles={[SUPERADMIN_ROLE]} />}
      errorElement={<Error />}
    >
      <Route path={PAGES.AGENCY.route} element={<AdminAgencyPage />} />
    </Route>
    <Route
      element={<AuthRoute roles={[MANAGER_ROLE]} />}
      errorElement={<Error />}
    >
      <Route path={PAGES.REPORTS.route} element={<ReportsPage />} />
    </Route>
    <Route
      element={<AuthRoute roles={[MANAGER_ROLE, CLERK_ROLE]} />}
      errorElement={<Error />}
    >
      <Route path={PAGES.REPORTS.route} element={<ReportsPage />} />
      <Route path={PAGES.SETTINGS.route} element={<SettingsPage />} />
      <Route path={PAGES.CLIENTS.route} element={<ClientsPage />} />
      <Route path={PAGES.PARTNERS.route} element={<PartnersPage />} />
      <Route path={PAGES.SALES.route} element={<SalesPage />} />
      <Route path={PAGES.INTERNAL_SALE.route} element={<InternalSalePage />} />
      <Route path={PAGES.EXTERNAL_SALE.route} element={<SingleContractPage />} />
      <Route path={PAGES.CONTRACTS.route} element={<ContractsPage />} />
      <Route path={PAGES.PAYMENT.route} element={<PaymentPage />} />
      <Route path={PAGES.EXCERPTS.route} element={<ExcerptsPage />} />
      <Route path={PAGES.NOTIFICATIONS.route} element={<NotificationsPage />} />
      <Route path={PAGES.PROFILE.route} element={<ProfilePage />} />
      <Route
        path={PAGES.ACCOMMODATION_UNIT.route}
        element={<AccommodationUnitPage />}
      />
      <Route path={PAGES.SINGLE_CONTRACT.route} element={<SingleContractPage />} />
    </Route>

    <Route
      element={
        <AuthRoute roles={[MANAGER_ROLE, CLERK_ROLE]} agencyType={[OTP_TYPE]} />
      }
      errorElement={<Error />}
    >
      <Route path={PAGES.OFFERS.route} element={<OffersPage />} />
      <Route path={PAGES.CONTRACTS.route} element={<ContractsPage />} />
    </Route>

    <Route element={<AuthRoute roles={[SUPERADMIN_ROLE]} />} errorElement={<Error />}>
      <Route path={PAGES.EXCHANGE_RATE.route} element={<ExchangeRatePage />} />
    </Route>

    <Route element={<AuthRoute roles={[]} />} errorElement={<Error />}>
      <Route path={PAGES.BASE.route} element={<HomePage />} />
      <Route path={PAGES.CATEGORY.route} element={<CategoryPage />} />
      <Route path={PAGES.COUNTRY.route} element={<CountryPage />} />
      <Route path={PAGES.CHARGE.route} element={<ChargePage />} />
      <Route path={PAGES.RECEIPTS.route} element={<ReceiptsPage />} />
      <Route path={PAGES.VIRMAN.route} element={<VirmanPage />} />
      <Route path={PAGES.SUPPLIER_PAYMENT.route} element={<SupplierPaymentPage />} />
      <Route path={PAGES.LOCATION.route} element={<LocationPage />} />


      <Route
        path={PAGES.ACCOMMODATION_TYPE.route}
        element={<AccommodationTypePage />}
      />
      <Route path={PAGES.ACCOMMODATION.route} element={<AccommodationPage />} />
      <Route
        path={PAGES.ACCOMMODATION_UNIT_TYPE.route}
        element={<AccommodationUnitTypePage />}
      />
      <Route
        path={PAGES.ACCOMMODATION_UNIT_PROPERTY.route}
        element={<AccommodationUnitPropertyPage />}
      />
      <Route path={PAGES.SERVICES.route} element={<ServicesPage />} />
      <Route
        path={PAGES.TRANSPORTATION_TYPE.route}
        element={<TransportationTypePage />}
      />
      <Route path={PAGES.OFFER_TYPE.route} element={<OfferTypePage />} />
    </Route>

    <Route path="*" element={<Error />} />
  </Routes>
);

export default AppRoutes;
