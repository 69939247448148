/* eslint-disable no-unused-vars */
import { Button, Typography } from "@mui/material";
import CustomTable from "components/CustomTable/CustomTable";
import ReceiptsFilter from "components/Filter/ReceiptsFilter/ReceiptsFilter";
import Filter from "components/Filter/Filter";
import AddReceiptModal from "components/Modals/AddReceiptModal/AddReceiptModal";
import ConfirmCloseDialog from "components/Modals/ConfirmCloseDialog/ConfirmCloseDialog";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import {
  useAddReceiptMutation,
  useReceiptDetailsQuery,
  useReceiptListQuery,
  useDeleteReceiptMutation,
  useEditReceiptMutation,
} from "features/receipts/receiptsSlice";
import { useFormik } from "formik";
import useReceiptsTable from "hooks/tables/useReceiptsTable";
import receiptsInitialValues from "initialValues/receiptsInitialValues";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PageContainer, PageHeader } from "styles/SharedStyle.styled";
import { convertDateToISO } from "util/dateHelpers";
import { getNonEmptyProps } from "util/helpers/objectHelpers";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import receiptsValidation from "validations/receiptsValidation";

const ReceiptsPage = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ name: "number", direction: "asc" });
  const [filter, setFilter] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [editData, setEditData] = useState(null);
  const [closeConfirmOpen, setCloseConfirmOpen] = useState(false);
  const { columns } = useReceiptsTable({
    setOpenDelete,
    setOpenEdit,
    setEditData,
    page,
    rowsPerPage,
  });

  const { data: receiptList, isLoading: isLoadingReceiptList } =
    useReceiptListQuery({
      page,
      rowsPerPage,
      search,
      sort,
      filter
    });

  const [addReceipt, { isLoading: isLoadingAddReceipt }] = useAddReceiptMutation();
  const [editReceipt, { isLoading: isLoadingEditReceipt }] =
    useEditReceiptMutation();
  const [deleteReceipt, { isLoading: isLoadingDeleteReceipt }] =
    useDeleteReceiptMutation();

  const { data: receiptDetails, isLoading: isLoadingReceiptDetails } =
    useReceiptDetailsQuery({ id: editData?.id }, { skip: !openEdit });

  const handleSubmit = async(values) => {
    if (openAdd) {
      try {
        await addReceipt({
          ...values,
          date: convertDateToISO(values.date),
        }).unwrap().then(() => {
          if (!isLoadingAddReceipt) {
            formik.resetForm();
            setOpenAdd(false);
            makeToastMessage(t("payment.addSuccessMessage"));
          }
        })
      } catch(e) {
        makeErrorToastMessage(e.data.message);
      }
    }

    if (openEdit) {
      try {
        await editReceipt({
          ...values,
          dateOfBirth: convertDateToISO(values.dateOfBirth),
          passportExpirationDate: values.passportExpirationDate
            ? convertDateToISO(values.passportExpirationDate)
            : null,
        }).unwrap().then(() => {
          if (!isLoadingEditReceipt) {
            formik.resetForm();
            setOpenEdit(false);
            makeToastMessage(t("payment.editSuccessMessage"));
          }
        })
      } catch(e) {
        makeErrorToastMessage(e.data.message);
      }
    }
  };

  const handleFilter = (values) => {
    setPage(0);
    setFilter(getNonEmptyProps(values));
  };

  const formik = useFormik({
    initialValues:
      openEdit && !isLoadingReceiptDetails ? receiptDetails : receiptsInitialValues,
    validationSchema: receiptsValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const filterForm = useFormik({
    initialValues: { ReceiptNumber: "", ContractNumber: "", DateFrom: null, DateTo: null, Client: "" },
    onSubmit: handleFilter,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleOpenAddReceipt = () => {
    setOpenAdd(true);
  };

  const handleCloseBtn = () => {
    if (formik.dirty) {
      setCloseConfirmOpen(true);
    } else {
      if (openAdd) {
        setOpenAdd(false);
        formik.resetForm();
      } else {
        setOpenEdit(false);
        setEditData(null);
      }
    }
  };

  const handleCloseConfirmDialogAdd = () => {
    setOpenAdd(false);
    setCloseConfirmOpen(false);
    formik.resetForm();
  };

  const handleCloseConfirmDialogEdit = () => {
    setOpenEdit(false);
    setCloseConfirmOpen(false);
    formik.resetForm();
    setEditData(null);
  };

  const handleCancel = () => {
    setCloseConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setEditData(null);
    setOpenDelete(false);
  };

  const handleConfirmDelete = () => {
    if (openDelete) {
      deleteReceipt({ id: editData.id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteReceipt) {
            setEditData(null);
            setOpenDelete(false);
            makeToastMessage(t("payment.deleteSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("payment.deleteErrorMessage")));
    }
  };

  return (
    <PageContainer>
      <ConfirmCloseDialog
        open={closeConfirmOpen}
        handleClose={
          openEdit ? handleCloseConfirmDialogEdit : handleCloseConfirmDialogAdd
        }
        handleCancel={handleCancel}
        description={t("payment.confirmClose")}
      />
      <AddReceiptModal
        open={openEdit ? openEdit : openAdd}
        formik={formik}
        handleCloseBtn={handleCloseBtn}
        edit={openEdit}
      />
      <ConfirmModal
        open={openDelete}
        handleConfirm={handleConfirmDelete}
        handleCancel={handleCancelDelete}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        description={t("payment.confirmDelete")}
      />
      <Typography variant="h4" color="" style={{marginBottom: 24}}>
          {t("payment.receiptsTitle")}
        </Typography>
      <PageHeader>
        <Filter
          filterForm={filterForm}
          handleReset={() => {
            setFilter(null);
            filterForm.resetForm();
          }}
        >
          <ReceiptsFilter filterForm={filterForm} />
        </Filter>
        <Button variant="contained" onClick={handleOpenAddReceipt}>
          {t("payment.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={receiptList?.data}
        isLoading={isLoadingReceiptList}
        count={receiptList?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setSearch={setSearch}
        rowsName={t("payment.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default ReceiptsPage;
