import React, { useState } from "react";
import CustomTable from "components/CustomTable/CustomTable";
import { Box, Button, Typography } from "@mui/material";
import ConfirmCloseDialog from "components/Modals/ConfirmCloseDialog/ConfirmCloseDialog";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { PageContainer, PageHeader } from "styles/SharedStyle.styled";
import Filter from "components/Filter/Filter";
import { FilterSearchContainer } from "./PartnersPage.styled";
import AddPartnerModal from "components/Modals/AddPartnerModal/AddPartnerModal";
import usePartnersTable from "hooks/tables/usePartnersTable";
import { useAgencyDropdownQuery } from "features/agency/agencyApiSlice";
import partnerInitialValues from "initialValues/partnerInitialValues";
import PartnerFilter from "components/Filter/PartnerFilter/PartnerFilter";
import SelectInput from "components/Inputs/SelectInput";
import {
  useCreateExternalPartnerMutation,
  useAgencyPartnerListQuery,
  useCreateExistingPartnerMutation,
  useDeleteExternalMutation,
  useDeleteExistingMutation,
  useAgencyPartnerDetailsQuery,
  useEditMutation,
} from "features/agencyPartner/agencyPartnerApiSlice";
import { getNonEmptyProps } from "util/helpers/objectHelpers";
import agencyPartnerExternalValidation from "validations/agencyPartnerExternalValidation";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";

const PartnersPage = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState(null);
  const [openAddPartner, setOpenAddPartner] = useState(false);
  const [openEditPartner, setOpenEditPartner] = useState(false);
  const [openInternalPartner, setOpenExternalPartner] = useState(false);
  const [editData, setEditData] = useState(null);
  const [openDeletePartner, setOpenDeletePartner] = useState(false);
  const [closeConfirmOpen, setCloseConfirmOpen] = useState(false);
  const [filter, setFilter] = useState(null);
  const [partner, setPartner] = useState(null);
  const { data: agencyPartnerList, isLoading: isLoadingAgencyPartnerList } =
    useAgencyPartnerListQuery({
      page,
      rowsPerPage,
      sort,
      filter,
    });

  const [createExternalPartner, { isLoading: isLoadingCreateExternalPartner }] =
    useCreateExternalPartnerMutation();

  const [editPartner, { isLoading: isLoadingEditPartner }] = useEditMutation();

  const [deleteExternal, { isLoading: isLoadingDeleteExternal }] =
    useDeleteExternalMutation();

  const [deleteExisting, { isLoading: isLoadingDeleteExisting }] =
    useDeleteExistingMutation();

  const { data: partnerDetails, isLoading: isLoadingPartnerDetails } =
    useAgencyPartnerDetailsQuery(
      { id: editData?.id },
      { skip: !openEditPartner }
    );
  const [createExistingPartner, { isLoading: isLoadingCreateExistingPartner }] =
    useCreateExistingPartnerMutation();

  const { columns } = usePartnersTable({
    page,
    rowsPerPage,
    setEditData,
    setOpenEditPartner,
    setOpenDeletePartner,
  });

  const { data: agencies } = useAgencyDropdownQuery();

  const handleSubmit = (values) => {
    if (openAddPartner) {
      createExternalPartner(values)
        .unwrap()
        .then(() => {
          if (!isLoadingCreateExternalPartner) {
            formik.resetForm();
            setOpenAddPartner(false);
            makeToastMessage(t("partners.addSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("partners.addErrorMessage")));
    }

    if (openEditPartner) {
      editPartner(values)
        .unwrap()
        .then(() => {
          if (!isLoadingEditPartner) {
            formik.resetForm();
            setOpenEditPartner(false);
            makeToastMessage(t("partners.editSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("partners.editErrorMessage")));
    }
  };

  const handleFilter = (values) => {
    setPage(0);
    setFilter(getNonEmptyProps(values));
  };

  const formik = useFormik({
    initialValues: openEditPartner
      ? isLoadingPartnerDetails
        ? partnerInitialValues
        : partnerDetails
      : partnerInitialValues,
    validationSchema: agencyPartnerExternalValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const filterForm = useFormik({
    initialValues: { AgencyTypeId: "", Name: "" },
    onSubmit: handleFilter,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleCloseModal = (_event, action) => {
    if (action === "backdropClick" || action === "escapeKeyDown") {
      if (formik.dirty) {
        setCloseConfirmOpen(true);
      } else {
        if (openAddPartner) {
          setOpenAddPartner(false);
        } else if (openEditPartner) {
          setOpenEditPartner(false);
          setEditData(null);
        }
      }
    }
  };

  const handleCloseConfirmDialog = () => {
    if (openAddPartner) {
      setOpenAddPartner(false);
      setCloseConfirmOpen(false);
      formik.resetForm();
    }

    if (openEditPartner) {
      setOpenEditPartner(false);
      setCloseConfirmOpen(false);
      formik.resetForm();
      setEditData(null);
    }
  };

  const handleOpenAddAgency = () => {
    setOpenAddPartner(true);
  };

  const handleCancel = () => {
    setCloseConfirmOpen(false);
  };

  const handleCloseBtn = () => {
    if (formik.dirty) {
      setCloseConfirmOpen(true);
    } else {
      if (openAddPartner) {
        setOpenAddPartner(false);
      } else if (openEditPartner) {
        setOpenEditPartner(false);
        setEditData(null);
      }
    }
  };

  const handleConfirm = () => {
    if (openInternalPartner) {
      createExistingPartner({ partnerId: partner })
        .unwrap()
        .then(() => {
          if (!isLoadingCreateExistingPartner) {
            setPartner(null);
            setOpenExternalPartner(false);
            makeToastMessage(t("partners.addSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("partners.addErrorMessage")));
    }

    if (openDeletePartner) {
      if (editData.partnerOnly) {
        deleteExternal({ id: editData.id })
          .unwrap()
          .then(() => {
            if (!isLoadingDeleteExternal) {
              setEditData(null);
              setOpenDeletePartner(false);
              makeToastMessage(t("partners.deleteSuccessMessage"));
            }
          })
          .catch(() => makeErrorToastMessage(t("partners.deleteErrorMessage")));
      } else {
        deleteExisting({ id: editData.id })
          .unwrap()
          .then(() => {
            if (!isLoadingDeleteExisting) {
              setEditData(null);
              setOpenDeletePartner(false);
              makeToastMessage(t("partners.deleteSuccessMessage"));
            }
          })
          .catch(() =>
            makeErrorToastMessage(
              makeErrorToastMessage(t("partners.deleteErrorMessage"))
            )
          );
      }
    }
  };

  const handleCancelConfirm = () => {
    if (openInternalPartner) {
      setPartner(null);
      setOpenExternalPartner(false);
    }

    if (openDeletePartner) {
      setOpenDeletePartner(false);
      setEditData(null);
    }
  };

  return (
    <PageContainer>
      <ConfirmCloseDialog
        open={closeConfirmOpen}
        handleClose={handleCloseConfirmDialog}
        handleCancel={handleCancel}
        description={t("partners.confirmCancel")}
      />
      <AddPartnerModal
        open={openEditPartner ? openEditPartner : openAddPartner}
        handleClose={handleCloseModal}
        formik={formik}
        handleCloseBtn={handleCloseBtn}
        title={
          openEditPartner ? t("partners.editTitle") : t("partners.addTitle")
        }
      />
      <ConfirmModal
        open={openInternalPartner || openDeletePartner}
        description={
          openDeletePartner
            ? t("partners.confirmDeletePartner")
            : t("partners.addPartnerFromSystem")
        }
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        handleConfirm={handleConfirm}
        handleCancel={handleCancelConfirm}
        btnConfirm={openDeletePartner ? "error" : "primary"}
      />
      <Typography variant="h4" color="" style={{ marginBottom: 24 }}>
        {t("partners.pageTitle")}
      </Typography>
      <PageHeader>
        <FilterSearchContainer>
          <Filter
            filterForm={filterForm}
            handleReset={() => {
              setFilter(null);
              filterForm.resetForm();
            }}
          >
            <PartnerFilter filterForm={filterForm} />
          </Filter>
        </FilterSearchContainer>
        <Box style={{ display: "flex", width: "35%", alignItems: "stretch" }}>
          <SelectInput
            fullWidth
            label={t("partners.dropdownPartnersList")}
            onChange={(e) => {
              if (e.target.value) {
                setPartner(e.target.value);
                setOpenExternalPartner(true);
              }
            }}
            value={partner}
            error={
              formik.touched.agencyTypeId && Boolean(formik.errors.agencyTypeId)
            }
            helperText={
              formik.touched.agencyTypeId && formik.errors.agencyTypeId
            }
            items={agencies}
          />
          <Button
            fullWidth
            variant="contained"
            onClick={handleOpenAddAgency}
            style={{ marginLeft: 24 }}
          >
            {t("partners.addButton")}
          </Button>
        </Box>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={agencyPartnerList?.data}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setSort={setSort}
        rowsName={t("partners.rowsName")}
        isLoading={isLoadingAgencyPartnerList}
      />
    </PageContainer>
  );
};

export default PartnersPage;
