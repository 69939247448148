import { Box } from "@mui/material";
import styled from "styled-components";

export const HomePageCard = styled(Box)`
  border: 1px solid #377CBE;
  background: linear-gradient(
    to bottom,
    #7EBBE3 0%,
    #7EBBE3 3px,
    #8EC8E9 7px,
    #A1D7F1 15px,
    #C3F3FF 30px,
    #FFFFFF 50px,
    #FFFFFF 100%
);
   box-shadow: 0px 2px 5px rgba(16, 24, 40, 0.15), 0px 2px 4px rgba(16, 24, 40, 0.08);
`;
