import * as Yup from "yup";
import i18next from "i18next";

export default Yup.object().shape({
  startDate: Yup.date()
    .required(i18next.t("field.systemLicenceStartDateRequired"))
    .typeError(i18next.t("field.dateFormatTypeError")),
  endDate: Yup.date()
    .required(i18next.t("field.systemLicenceEndDateRequired"))
    .typeError(i18next.t("field.dateFormatTypeError")),
  licenceStatusId: Yup.string().required(
    i18next.t("field.systemLicenceStatusIdRequired")
  ),
});
