/* eslint-disable no-unused-vars */
import { Button, Typography } from "@mui/material";
import CustomTable from "components/CustomTable/CustomTable";
import ClientFilter from "components/Filter/ClientFilter/ClientFilter";
import Filter from "components/Filter/Filter";
import AddClientModal from "components/Modals/AddClientModal/AddClientModal";
import ConfirmCloseDialog from "components/Modals/ConfirmCloseDialog/ConfirmCloseDialog";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import {
  useAddClientMutation,
  useClientDetailsQuery,
  useClientListQuery,
  useDeleteClientMutation,
  useDeletePassportImageMutation,
  useEditClientMutation,
  useGetClientPassportImageQuery,
  useUploadPassportImageMutation,
} from "features/client/clientApiSlice";
import { useFormik } from "formik";
import useClientTable from "hooks/tables/useClientTable";
import clientInitialValues from "initialValues/clientInitialValues";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PageContainer, PageHeader } from "styles/SharedStyle.styled";
import { convertDateToISO } from "util/dateHelpers";
import { getNonEmptyProps } from "util/helpers/objectHelpers";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import clientValidation from "validations/clientValidation";

const ClientsPage = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ name: "surname", direction: "asc" });
  const [filter, setFilter] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [editData, setEditData] = useState(null);
  const [closeConfirmOpen, setCloseConfirmOpen] = useState(false);
  const { columns } = useClientTable({
    setOpenDelete,
    setOpenEdit,
    setEditData,
    page,
    rowsPerPage,
  });

  const { data: clientList, isLoading: isLoadingClientList } =
    useClientListQuery({
      page,
      rowsPerPage,
      search,
      sort,
      filter,
    });

  const [addClient, { isLoading: isLoadingAddClient }] = useAddClientMutation();
  const [editClient, { isLoading: isLoadingEditClient }] =
    useEditClientMutation();
  const [deleteClient, { isLoading: isLoadingDeleteUser }] =
    useDeleteClientMutation();

  const { data: clientDetails, isLoading: isLoadingClientDetails } =
    useClientDetailsQuery({ id: editData?.id }, { skip: !openEdit });

  const { data: clientPassportImage, isLoading: isLoadingClientPassportImage } =
    useGetClientPassportImageQuery({ id: editData?.id }, { skip: !openEdit });

  const [uploadPassportImage, { isLoading: isLoadingUploadPassportImage }] =
    useUploadPassportImageMutation();
  const [deletePassportImage, { isLoading: isLoadingDeletePassportImage }] =
    useDeletePassportImageMutation();

  const handleSubmit = async (values) => {
    if (openAdd) {
      try {
        const client = await addClient({
          ...values,
          dateOfBirth: convertDateToISO(values.dateOfBirth),
          passportExpirationDate: values.passportExpirationDate
            ? convertDateToISO(values.passportExpirationDate)
            : null,
        }).unwrap();
        if (!isLoadingAddClient) {
          if (client.id && formik.values.passportImage !== null) {
            uploadPassportImage({
              id: client.id,
              data: formik.values.passportImage[0],
            })
              .unwrap()
              .then(() => {
                makeToastMessage(t("client.addPassportImageSuccessMessage"));
              })
              .catch(() =>
                makeErrorToastMessage(t("client.addPassportImageErrorMessage"))
              );
          }
        }
        formik.resetForm();
        setOpenAdd(false);
        makeToastMessage(t("client.addSuccessMessage"));
      } catch (e) {
        makeErrorToastMessage(e.data.message);
      }
    }

    if (openEdit) {
      try {
        await editClient({
          ...values,
          dateOfBirth: convertDateToISO(values.dateOfBirth),
          passportExpirationDate: values.passportExpirationDate
            ? convertDateToISO(values.passportExpirationDate)
            : null,
        })
          .unwrap()
          .then(() => {
            if (!isLoadingEditClient) {
              if (formik.values.passportImage !== null) {
                uploadPassportImage({
                  id: editData.id,
                  data: formik.values.passportImage[0],
                })
                  .unwrap()
                  .then(() => {
                    makeToastMessage(
                      t("client.addPassportImageSuccessMessage")
                    );
                  })
                  .catch(() =>
                    makeErrorToastMessage(
                      t("client.addPassportImageErrorMessage")
                    )
                  );
              }
              formik.resetForm();
              setOpenEdit(false);
              makeToastMessage(t("client.editSuccessMessage"));
            }
          });
      } catch (e) {
        makeErrorToastMessage(e.data.message);
      }
    }
  };

  const handleFilter = (values) => {
    setPage(0);
    setFilter(getNonEmptyProps(values));
  };

  const handleDeletePassportImage = () => {
    deletePassportImage({ clientId: editData.id })
      .unwrap()
      .then(() => {
        if (!isLoadingDeletePassportImage) {
          makeToastMessage(t("client.deletePassportImageSuccessMessage"));
        }
      })
      .catch(() =>
        makeErrorToastMessage(t("client.deletePassportImageErrorMessage"))
      );
    formik.setFieldValue("passportImage", null);
  };

  const formik = useFormik({
    initialValues:
      openEdit && !isLoadingClientDetails && !isLoadingClientPassportImage
        ? {
            ...clientDetails,
            passportImage:
              clientPassportImage?.data !== null
                ? [clientPassportImage?.data]
                : null,
          }
        : clientInitialValues,
    validationSchema: clientValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const filterForm = useFormik({
    initialValues: { Name: "", Surname: "", CountryId: "", LocationId: "" },
    onSubmit: handleFilter,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const setPassportImage = (image) => {
    formik.setFieldValue("passportImage", image);
  };

  const handleOpenAddClient = () => {
    setOpenAdd(true);
  };

  const handleCloseBtn = () => {
    if (formik.dirty) {
      setCloseConfirmOpen(true);
    } else {
      if (openAdd) {
        setOpenAdd(false);
        formik.resetForm();
      } else {
        setOpenEdit(false);
        setEditData(null);
      }
    }
  };

  const handleCloseConfirmDialogAdd = () => {
    setOpenAdd(false);
    setCloseConfirmOpen(false);
    formik.resetForm();
  };

  const handleCloseConfirmDialogEdit = () => {
    setOpenEdit(false);
    setCloseConfirmOpen(false);
    formik.resetForm();
    setEditData(null);
  };

  const handleCancel = () => {
    setCloseConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setEditData(null);
    setOpenDelete(false);
  };

  const handleConfirmDelete = () => {
    if (openDelete) {
      deleteClient({ id: editData.id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteUser) {
            setEditData(null);
            setOpenDelete(false);
            makeToastMessage(t("client.deleteSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("client.deleteErrorMessage")));
    }
  };

  return (
    <PageContainer>
      <ConfirmCloseDialog
        open={closeConfirmOpen}
        handleClose={
          openEdit ? handleCloseConfirmDialogEdit : handleCloseConfirmDialogAdd
        }
        handleCancel={handleCancel}
        description={t("client.confirmClose")}
      />
      <AddClientModal
        open={openEdit ? openEdit : openAdd}
        formik={formik}
        handleCloseBtn={handleCloseBtn}
        edit={openEdit}
        title={openEdit ? t("client.editTitle") : t("client.addTitle")}
        passportImage={formik.values.passportImage}
        setPassportImage={setPassportImage}
        handleDeletePassportImage={handleDeletePassportImage}
        isLoadingPassportImage={
          isLoadingClientPassportImage ||
          isLoadingUploadPassportImage ||
          isLoadingDeletePassportImage
        }
      />
      <ConfirmModal
        open={openDelete}
        handleConfirm={handleConfirmDelete}
        handleCancel={handleCancelDelete}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        description={t("client.confirmDelete")}
      />
      <Typography variant="h4" color="" style={{ marginBottom: 24 }}>
        {t("client.pageTitle")}
      </Typography>
      <PageHeader>
        <Filter
          filterForm={filterForm}
          handleReset={() => {
            setFilter(null);
            filterForm.resetForm();
          }}
        >
          <ClientFilter filterForm={filterForm} />
        </Filter>
        <Button variant="contained" onClick={handleOpenAddClient}>
          {t("client.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={clientList?.data}
        isLoading={isLoadingClientList}
        count={clientList?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setSearch={setSearch}
        rowsName={t("client.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default ClientsPage;
