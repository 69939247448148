import { Button, Dialog } from "@mui/material";
import React from "react";
import PropType from "prop-types";
import {
  DialogContainer,
  Title,
  TopContainer,
  ContentContainer,
} from "./CustomDialog.styled";
import CloseIcon from "@mui/icons-material/Close";

const CustomDialog = ({
  children,
  handleClose,
  open,
  title,
  handleCloseBtn,
  maxWidth="md"
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogContainer>
        <TopContainer>
          <Title>{title}</Title>
          <Button onClick={handleCloseBtn}>
            <CloseIcon width={24} height={24} />
          </Button>
        </TopContainer>
        <ContentContainer>{children}</ContentContainer>
      </DialogContainer>
    </Dialog>
  );
};

CustomDialog.propTypes = {
  children: PropType.node,
  handleClose: PropType.func,
  open: PropType.bool,
  title: PropType.string,
  handleCloseBtn: PropType.func,
  maxWidth: PropType.oneOf(["xs", "sm", "md", "lg", "xl"])
};
export default CustomDialog;
