import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const DialogContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: linear-gradient(
    to bottom,
    #7EBBE3 0%,
    #7EBBE3 3px,
    #8EC8E9 7px,
    #A1D7F1 15px,
    #C3F3FF 30px,
    #FFFFFF 50px,
    #FFFFFF 100%
);
`;

export const TopContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const Title = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
`;

export const ContentContainer = styled(Box)`
  margin-top: 24px;
`;
