export default {
 name: "",
 date: null,
 contractNumber: "",
 description: "",
 accomodation: "",
 cashAmount: "",
 cardAmount: "",
 checkAmount: "",
 totalAmount: "",
 nbsCourse: "",
 nbsCurrency: "RSD",
 restAmount: "",
}
