/* eslint-disable no-unused-vars */
import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { arrayToObject } from "util/helpers/arrayToObject";
import CreateIcon from "@mui/icons-material/Create";
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

const useInternalSaleTable = ({
  setSelectedId,
  page,
  rowsPerPage,
  setOpenPreview,
  setOpenReserve
}) => {
  const { t } = useTranslation();
  const columns = [
    {
      name: "",
      label: t("tableCols.agency.ordinalNumberLabel"),
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { width: "100px" } }),
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (value, tableMetadata, updateValue) => {
          return tableMetadata.rowIndex + 1 + page * rowsPerPage;
        },
      },
    },
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "travelOrganizer",
      label: "Organizator",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "country",
      options:{
        filter: false,
        sort: false,
        display: false,
      }
    },
    {
      name: "location",
      options:{
        filter: false,
        sort: false,
        display: false,
      }
    },
    {
      name: "offerDate",
      label: "Datum ponude",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMetadata, updateValue) => {
          const rowData = arrayToObject(tableMetadata.rowData, columns);
          return format(new Date(rowData.offerDate), "dd.MM.yyyy.");
        },
      },
    },
    {
      name: "destination",
      label: "Destinacija (Država, mesto)",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMetadata, updateValue) => {
          const rowData = arrayToObject(tableMetadata.rowData, columns);
          return `${rowData.country}, ${rowData.location}`;
        },
      },
    },
    {
      name: "startDate",
      label: "Datum polaska",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMetadata, updateValue) => {
          const rowData = arrayToObject(tableMetadata.rowData, columns);
          return format(new Date(rowData.startDate), "dd.MM.yyyy.");
        },
      },
    },
    {
      name: "offerType",
      label: "Tip ponude",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "accommodationType",
      label: "Tip objekta",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "accommodation",
      label: "Objekat",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "accommodationUnitType",
      label: "Vrsta smeštajne jedinice",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "serviceType",
      label: "Vrsta usluge",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "offerStatus",
      label: "Status ponude",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMetadata, updateValue) => {
          const rowData = arrayToObject(tableMetadata.rowData, columns);
          return rowData.offerStatus
          ? t("tableCols.offer.offerStatusActive")
          : t("tableCols.offer.offerStatusInactive")
        }
      },
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        print: false,
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (_value, tableMeta, _updateValue) => {
          const currentOffer = arrayToObject(tableMeta.rowData, columns);
          return (
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip
                title="Detalji"
                placement="top"
                style={{ marginRight: 5 }}
              >
                <IconButton
                  onClick={() => {
                    setSelectedId(currentOffer.id);
                    setOpenPreview(true);
                  }}
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Rezerviši" placement="top">
                <IconButton
                  onClick={() => {
                    setSelectedId(currentOffer.id);
                    setOpenReserve(true);
                  }}
                >
                  <BookmarkAddedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];
  return { columns };
};

export default useInternalSaleTable;
