import * as Yup from "yup";
import i18next from "i18next";

export default Yup.object().shape({
  password: Yup.string()
    .required(i18next.t("field.passwordRequired"))
    .matches(/[A-Z]/, i18next.t("field.passwordUpperCaseRequired"))
    .matches(/[!@#$%^&*]/, i18next.t("field.passwordSpecialCharRequired"))
    .min(8, i18next.t("field.passwordMin")),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], i18next.t("field.passwordMatch"))
    .required(i18next.t("field.confirmPasswordRequired")),
});
