/* eslint-disable react/prop-types */
import { Typography } from '@mui/material'
import React from 'react'

const Title = ({title}) => {
  return (
    <Typography variant="h4" style={{marginBottom: 24}}>{title}</Typography>
  )
}

export default Title