export default {
    passengers: [
        {
          passengerName: "",
          passengerAddress: "",
          passengerCountryId: "",
          passengerLocationId: "",
          passengerPhoneNumber: "",
          passengerDateOfBirth: "",
          passengerPassportNumber: "",
          departurePlace: "",
        },
      ],
      calculationService: "",
      calculationQuantity: "",
      calculationPriceDin: "",
      calculationPriceEur: "",
      calculationSubTotal: "",
      calculationSaleDin: "",
      calculationSaleEur: "",
      calculationTotal: "",
      departureDate: null,
      appointmentDate: "",
      description: "",
  };