/* eslint-disable react/prop-types */
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomDialog from "../CustomDialog/CustomDialog";
import { RowContainer } from "styles/SharedStyle.styled";
import TextInput from "components/Inputs/TextInput";
import DatePick from "components/Inputs/DatePick";
import Autocomplete from '@mui/material/Autocomplete';
import { validateNumericInput } from "util/helpers/preventNonNumericChars";


const AddReceiptModal = ({
  open,
  handleClose,
  formik,
  handleCloseBtn,
  edit = false,
  title,
}) => {
  const { t } = useTranslation();

  const formatedValues = {
    dateOfBirth: edit ? new Date(formik.values.dateOfBirth) : null,
    passportExpirationDate:
      edit && formik?.values?.passportExpirationDate
        ? new Date(formik.values.passportExpirationDate)
        : null,
  };

  //TODO Add values for nbs currency and contract numbers

  // const nbsCurrencies = [
  //   { value: 1, text: "RSD" },
  //   { value: 2, text: "EUR" },
  // ]
  const contractNumbers = [
    { value: 1, text: "000-121" },
    { value: 2, text: "000-122" },
    { value: 3, text: "000-123" },
    { value: 4, text: "000-124" },
    { value: 5, text: "000-125" },
  ]

  const safeSum = (...values) => {
    return values.reduce((sum, value) => sum + (Number(value) || 0), 0);
  };

  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      handleCloseBtn={handleCloseBtn}
      title={title}
    >
    <Typography variant="h6" style={{ textAlign: "center", marginBottom: "24px" }}>
      {t("payment.receiptNumber")} 233/2024
    </Typography>
      <Box component="form">
        <RowContainer>
          <TextInput
            fullWidth
            name="name"
            label={t("field.clientNameAndSurnamePlaceholder")}
            value={formik?.values?.name}
            onChange={formik?.handleChange}
            error={formik?.touched?.name && Boolean(formik?.errors?.name)}
            helperText={formik?.touched?.name && formik?.errors?.name}
            style={{ marginRight: "24px" }}
          />
          <Autocomplete
             fullWidth
             id="contractNumber"
             options={contractNumbers}
             getOptionLabel={(option) => option.text}
             value={contractNumbers.find(option => option.value === formik.values.contractNumber) || null}
             onChange={(event, newValue) => {
              formik.setFieldValue('contractNumber', newValue ? newValue.value : '');
             }}
             renderInput={(params) => (
                <TextInput
                   {...params}
                   name="contractNumber"
                   label={t("field.contractNumber")}
                   error={formik.touched.contractNumber && Boolean(formik.errors.contractNumber)}
                   helperText={formik.touched.contractNumber && formik.errors.contractNumber}
                />
              )}
           />
        </RowContainer>

        <RowContainer>
          <TextInput
            fullWidth
            name="description"
            label={t("field.travelDescriptionPlaceholder")}
            value={formik?.values?.description}
            onChange={formik.handleChange}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
            style={{ marginRight: "24px" }}
          />
          <DatePick
            name="date"
            label={t("field.termDate")}
            value={formatedValues?.date}
            onChange={(value) =>
              formik.setFieldValue("date", value, true)
            }
            error={
              formik.touched.date && Boolean(formik.errors.date)
            }
            helperText={formik.touched.date && formik.errors.date}
          />
        </RowContainer>

        <RowContainer>
          <TextInput
            fullWidth
            name="cashAmount"
            label={t("field.cashAmountPlaceholder")}
            value={formik?.values?.cashAmount}
            type="number"
            onKeyDown={validateNumericInput}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={formik.handleChange}
            error={formik.touched.cashAmount && (Boolean(formik.errors.cashAmount) || Boolean(formik.errors.atLeastOneAmountRequired))}
            helperText={
              (formik.touched.cashAmount && formik.errors.cashAmount) ||
              (formik.touched.cashAmount && formik.errors.atLeastOneAmountRequired)
           }
            style={{ marginRight: "24px" }}
          />
          <TextInput
            fullWidth
            name="cardAmount"
            label={t("field.cardAmountPlaceholder")}
            value={formik?.values?.cardAmount}
            type="number"
            onKeyDown={validateNumericInput}
            onChange={formik.handleChange}
            error={formik.touched.cardAmount && (Boolean(formik.errors.cardAmount) || Boolean(formik.errors.atLeastOneAmountRequired))}
            helperText={
             (formik.touched.cardAmount && formik.errors.cardAmount) ||
             (formik.touched.cardAmount && formik.errors.atLeastOneAmountRequired)
            }
            style={{ marginRight: "24px" }}
          />
          <TextInput
            fullWidth
            name="checkAmount"
            type="number"
            onKeyDown={validateNumericInput}
            label={t("field.checkAmountPlaceholder")}
            value={formik?.values?.checkAmount}
            onChange={formik.handleChange}
            error={formik.touched.checkAmount && (Boolean(formik.errors.checkAmount) || Boolean(formik.errors.atLeastOneAmountRequired))}
            helperText={
             (formik.touched.checkAmount && formik.errors.checkAmount) ||
             (formik.touched.checkAmount&& formik.errors.atLeastOneAmountRequired)
            }
          />

        </RowContainer>
        <RowContainer>
          <TextInput
            fullWidth
            disabled
            name="nbsCurrency"
            label={t("field.nbsCurrency")}
            value={formik?.values?.nbsCurrency}
            onChange={formik.handleChange}
            error={formik.touched.nbsCurrency && Boolean(formik.errors.nbsCurrency)}
            helperText={formik.touched.nbsCurrency && formik.errors.nbsCurrency}
            style={{ marginRight: "24px" }}
          />

          <TextInput
            fullWidth
            name="nbsCourse"
            label={t("field.nbsCourse")}
            value={formik?.values?.nbsCourse}
            onChange={formik.handleChange}
            onKeyDown={validateNumericInput}
            error={formik.touched.nbsCourse && Boolean(formik.errors.nbsCourse)}
            helperText={formik.touched.nbsCourse && formik.errors.nbsCourse}
            style={{ marginRight: "24px" }}
          />

          <TextInput
            fullWidth
            disabled
            name="totalAmount"
            label={t("field.totalAmount")}
            value={safeSum(
               formik?.values?.cashAmount,
               formik?.values?.cardAmount,
               formik?.values?.checkAmount
            )}
            onChange={formik.handleChange}
            onKeyDown={validateNumericInput}
            error={formik.touched.totalAmount && Boolean(formik.errors.totalAmount)}
            helperText={formik.touched.totalAmount && formik.errors.totalAmount}
            style={{ marginRight: "24px" }}
          />
          <TextInput
            fullWidth
            name="restAmount"
            label={t("field.restAmount")}
            value={formik?.values?.restAmount}
            type="number"
            onKeyDown={validateNumericInput}
            onChange={formik.handleChange}
            error={formik.touched.restAmount && Boolean(formik.errors.restAmount)}
            helperText={formik.touched.restAmount && formik.errors.restAmount}
          />
        </RowContainer>

        <RowContainer style={{ marginBottom: 0, justifyContent: "center" }}>
          <Button
            variant="outlined"
            onClick={handleCloseBtn}
            style={{ marginRight: "24px" }}
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!formik.dirty}
            onClick={formik.handleSubmit}
          >
            {t("common.save")}
          </Button>
        </RowContainer>
      </Box>
    </CustomDialog>
  );
};

export default AddReceiptModal;
