import { Button } from "@mui/material";
import Codebooks from "components/Codebooks/Codebooks";
import CustomTable from "components/CustomTable/CustomTable";
import Search from "components/Search/Search";
import Title from "components/Title/Title";
import { useAddUnitPropertyMutation, useDeleteUnitPropertyMutation, useEditUnitPropertyMutation, useUnitPropertyListQuery } from "features/unitProperty/unitPropertyApiSlice";
import { useFormik } from "formik";
import useCodebookTable from "hooks/tables/useCodebookTable";
import useDebounce from "hooks/useDebounceHook";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PageHeader, PageContainer } from "styles/SharedStyle.styled";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import * as Yup from "yup";

const AccommodationUnitPropertyPage = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ name: "name", direction: "asc" });
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [editData, setEditData] = useState(null);
  const { columns } = useCodebookTable({
    setOpenDelete,
    setOpenEdit,
    setEditData,
    page,
    rowsPerPage,
  });

  const debouncedSearch = useDebounce(search);

  const { data: unitProperties, isLoading: isLoadingUnitProperties } =
    useUnitPropertyListQuery({
      page,
      rowsPerPage,
      search: debouncedSearch,
      sort,
    });
  const [
    addUnitProperty,
    { isLoading: isLoadingAddUnitProperty },
  ] = useAddUnitPropertyMutation();

  const [
    editUnitProperty,
    { isLoading: isLoadingEditUnitProperty },
  ] = useEditUnitPropertyMutation();

  const [
    deleteUnitProperty,
    { isLoading: isLoadingDeleteUnitProperty },
  ] = useDeleteUnitPropertyMutation();

  const handleSubmit = (val) => {
    if (openAdd) {
      const { name } = val;
      addUnitProperty({ name })
        .unwrap()
        .then(() => {
          if (!isLoadingAddUnitProperty) {
            formik.resetForm();
            setOpenAdd(false);
            makeToastMessage(t("accommodationUnitCharacteristics.addSuccessMessage"));
          }
        })
        .catch(() =>
          makeErrorToastMessage(t("accommodationUnitCharacteristics.addErrorMessage"))
        );
    }

    if (openEdit) {
      const { id, name } = val;

      editUnitProperty({ name, id })
        .unwrap()
        .then(() => {
          if (!isLoadingEditUnitProperty) {
            formik.resetForm();
            setEditData(null);
            setOpenEdit(false);
            makeToastMessage(t("accommodationUnitCharacteristics.editSuccessMessage"));
          }
        })
        .catch(() =>
          makeErrorToastMessage(t("accommodationUnitCharacteristics.editErrorMessage"))
        );
    }
  };

  const handleConfirmDelete = () => {
    if (openDelete) {
      deleteUnitProperty({ id: editData.id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteUnitProperty) {
            setEditData(null);
            setOpenDelete(false);
            makeToastMessage(t("accommodationUnitCharacteristics.deleteSuccessMessage"));
          }
        })
        .catch(() =>
          makeErrorToastMessage(t("accommodationUnitCharacteristics.deleteErrorMessage"))
        );
    }
  };

  const formik = useFormik({
    initialValues: openEdit ? editData : { name: "" },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t("field.accommodationUnitCharacteristicsRequired")),
    }),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <PageContainer>
      <Codebooks
        formik={formik}
        editTitle={t("accommodationUnitCharacteristics.editTitle")}
        addTitle={t("accommodationUnitCharacteristics.addTitle")}
        labelTitle={t("field.accommodationUnitCharacteristicsPlaceholder")}
        confirmCloseDescription={t("accommodationUnitCharacteristics.confirmClose")}
        confirmDeleteDescription={t("accommodationUnitCharacteristics.confirmDelete")}
        setEditData={setEditData}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        openAdd={openAdd}
        setOpenAdd={setOpenAdd}
        handleConfirmDelete={handleConfirmDelete}
      />
      <Title title={t("accommodationUnitCharacteristics.pageTitle")} />
      <PageHeader>
        <Search label={t("accommodationUnitCharacteristics.searchPlaceholder")} handleChange={handleSearch} search={search} />
        <Button variant="contained" onClick={handleOpenAdd}>
          {t("accommodationUnitCharacteristics.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={unitProperties?.data}
        isLoading={isLoadingUnitProperties}
        count={unitProperties?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsName={t("accommodationUnitCharacteristics.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default AccommodationUnitPropertyPage;
