/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";
import { useAgencyTypeQuery } from "features/agency-type/agencyTypeApiSlice";
import { useLicenceStatusDropdownQuery } from "features/licenceStatus/licenceStatusApiSlice";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";

const AgencyFilter = ({ filterForm }) => {
  const { t } = useTranslation();
  const { data: licenceStatuses } = useLicenceStatusDropdownQuery();
  const { data: agencyTypes } = useAgencyTypeQuery();

  const modifiedStatuses = licenceStatuses && licenceStatuses?.map(item => ({
    ...item,
    value: item.value === 1 ? true : false
  }));

  return (
    <Box component="form">
      <RowContainer>
        <TextInput
          fullWidth
          name="Name"
          label={t("field.agencyNameFilterPlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.Name}
        />
      </RowContainer>
      <RowContainer>
        <SelectInput
          fullWidth
          name="Active"
          label={t("field.agencyStatusPlaceholder")}
          style={{ marginRight: 24 }}
          onChange={filterForm.handleChange}
          value={filterForm.values.Active}
          items={modifiedStatuses}
          InputLabelProps={{
            shrink: filterForm.values.Active !== "",
          }}
        />
        <SelectInput
          fullWidth
          name="AgencyTypeId"
          label={t("field.agencyTypePlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.AgencyTypeId}
          items={agencyTypes}
        />
      </RowContainer>
    </Box>
  );
};

export default AgencyFilter;
