import * as Yup from "yup";

export default Yup.object().shape({
  email: Yup.string()
    .email("Pogrešan format email-a.")
    .required("Email je obavezno polje."),
  password: Yup.string()
    .required("Lozinka je obavezno polje.")
    .min(8, "Dužina šifre ne sme biti manja od 8 karaktera"),
});
