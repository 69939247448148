import * as Yup from "yup";
import i18next from "i18next";

export default Yup.object().shape({
  oldPassword: Yup.string().required(i18next.t("field.oldPasswordRequired")),
  newPassword: Yup.string()
    .required(i18next.t("field.newPasswordRequired"))
    .min(8, i18next.t("field.passwordMin"))
    .matches(/[A-Z]/, i18next.t("field.passwordUpperCaseRequired"))
    .matches(/[!@#$%^&*]/, i18next.t("field.passwordSpecialCharRequired")),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], i18next.t("field.passwordMatch"))
    .required(i18next.t("field.confirmPasswordRequired")),
});
