import styled from "styled-components";
import { Box } from "@mui/material";

export const FilterTopContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;
`;

export const FilterActionsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;
