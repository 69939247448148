import React from "react";

const VirmanPage = () => {
  return (
    <>
      <p>Virman Page</p>
    </>
  );
};

export default VirmanPage;
