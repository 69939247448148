import React from "react";
import PropTypes from "prop-types";
import { HeaderContainer, HeaderSideContainer } from "./Header.styled";
import HeaderProfileBar from "./HeaderProfileBar/HeaderProfileBar";
import useDevice from "hooks/useDevice";
import HeaderDrawer from "./HeaderDrawer/HeaderDrawer";

const Header = () => {
  const { isMobile } = useDevice();
  return (
    <HeaderContainer>
      <HeaderSideContainer>
        {isMobile && <HeaderDrawer />}
      </HeaderSideContainer>
      <HeaderSideContainer>
        {!isMobile && <HeaderProfileBar />}
      </HeaderSideContainer>
    </HeaderContainer>
  );
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
