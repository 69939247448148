import { apiSlice } from "features/api/apiSlice";

export const exchangeRateSlice = apiSlice.injectEndpoints({
  tagTypes: ["ExchangeRate, ExchangeRateSingle"],
  endpoints: (builder) => ({
    exchangeRateDropdown: builder.query({
      query: () => ({
        url: `/currency/dropdown`,
      }),
      providesTags: ["ExchangeRatesDropdown"],
    }),
    exchangeRateList: builder.query({
      query: (args) => {
        const { page, rowsPerPage, sort, filter, search } = args;
        console.log(sort)
        return {
          url:
            "/exchange-rate/list?" +
            new URLSearchParams({
              PageNumber: page + 1,
              RowsPerPage: rowsPerPage,
              Active: true,
              // Conditionally add to params object if keys exist
              ...(sort && {
                SortPropertyName: "date",
                Ascending: sort.direction === "desc" ? true : false,
              }),
              ...(search && { Name: search }),
              //   ...(sortDirection && { orderBy: sortDirection }),
              ...(filter && filter),
            }),
        };
      },
      providesTags: ["ExchangeRate"],
    }),
    exchangeRateDetails: builder.query({
      query: ({ id }) => ({
        url: `/exchange-rate/${id}`,
      }),
      providesTags: ["ExchangeRateSingle"],
    }),
    addExchangeRate: builder.mutation({
      query: (data) => ({
        url: "/exchange-rate/add",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["ExchangeRate", "ExchangeRatesDropdown"],
    }),
    editExchangeRate: builder.mutation({
      query: (data) => ({
        url: "/exchange-rate/edit",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["ExchangeRate", "ExchangeRateSingle"],
    }),
    deleteExchangeRate: builder.mutation({
      query: (data) => ({
        url: "/exchange-rate",
        method: "DELETE",
        body: { ...data },
      }),
      invalidatesTags: ["ExchangeRate"],
    }),
  }),
});

export const {
  useExchangeRateDropdownQuery,
  useExchangeRateListQuery,
  useExchangeRateDetailsQuery,
  useAddExchangeRateMutation,
  useEditExchangeRateMutation,
  useDeleteExchangeRateMutation,
} = exchangeRateSlice;
