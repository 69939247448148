/* eslint-disable react/prop-types */
import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styled from "styled-components";

const DatePickContainer = styled(DatePicker)`
  @media (max-width: 600px) {
    margin-bottom: 24px;
  }
`

const DatePick = ({
  name,
  label,
  value,
  onChange,
  disabled = false,
  format = "dd.MM.yyyy.",
  error = false,
  helperText = "",
  style,
  ...props
}) => {
  return (
    <DatePickContainer
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      disabled={disabled}
      format={format}
      slotProps={{
        textField: {
          fullWidth: true,
          error: error,
          style,
          helperText: helperText,
        },
      }}
      {...props}
    />
  );
};

export default DatePick;
