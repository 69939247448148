/* eslint-disable no-unused-vars */
import { Button, Typography } from "@mui/material";
import CustomTable from "components/CustomTable/CustomTable";
import ExchangeRateFilter from "components/Filter/ExchangeRateFilter/ExchangeRateFilter";
import Filter from "components/Filter/Filter";
import AddExchangeRateModal from "components/Modals/AddExchangeRateModal/AddExchangeRateModal";
import ConfirmCloseDialog from "components/Modals/ConfirmCloseDialog/ConfirmCloseDialog";
import ConfirmModal from "components/Modals/ConfirmModal/ConfirmModal";
import {
  useAddExchangeRateMutation,
  useExchangeRateDetailsQuery,
  useExchangeRateListQuery,
  useDeleteExchangeRateMutation,
  useEditExchangeRateMutation,
} from "features/exchange-rate/exchangeRateSlice";
import { useFormik } from "formik";
import useExchangeRateTable from "hooks/tables/useExchangeRateTable";
import exchangeRateInitialValues from "initialValues/exchangeRateInitialValues";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PageContainer, PageHeader } from "styles/SharedStyle.styled";
import { convertDateToISO } from "util/dateHelpers";
import { getNonEmptyProps } from "util/helpers/objectHelpers";
import { makeErrorToastMessage, makeToastMessage } from "util/toastMessage";
import exchangeRateValidation from "validations/exchangeRateValidation";
import { formatDateForFilter } from "util/dateHelpers";

const ExchangeRatePage = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ name: "number", direction: "asc" });
  const [filter, setFilter] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [editData, setEditData] = useState(null);
  const [closeConfirmOpen, setCloseConfirmOpen] = useState(false);
  const { columns } = useExchangeRateTable({
    setOpenDelete,
    setOpenEdit,
    setEditData,
    page,
    rowsPerPage,
  });

  const { data: exchangeRateList, isLoading: isLoadingExchangeRateList } =
    useExchangeRateListQuery({
      page,
      rowsPerPage,
      search,
      sort,
      filter
    });


  const [addExchangeRate, { isLoading: isLoadingAddExchangeRate }] = useAddExchangeRateMutation();
  const [editExchangeRate, { isLoading: isLoadingEditExchangeRate }] =
    useEditExchangeRateMutation();
  const [deleteExchangeRate, { isLoading: isLoadingDeleteExchangeRate }] =
    useDeleteExchangeRateMutation();

  const { data: exchangeRateDetails, isLoading: isLoadingExchangeRateDetails } =
    useExchangeRateDetailsQuery({ id: editData?.id}, { skip: !openEdit });

  const handleSubmit = async(values) => {
    if (openAdd) {
      try {
        await addExchangeRate({
          ...values,
          date: convertDateToISO(values.date),
        }).unwrap().then(() => {
          if (!isLoadingAddExchangeRate) {
            formik.resetForm();
            setOpenAdd(false);
            makeToastMessage(t("exchangeRate.addSuccessMessage"));
          }
        })
      } catch(e) {
        makeErrorToastMessage(e.data.message);
      }
    }

    if (openEdit) {
      try {
        await editExchangeRate({
          ...values,
        }).unwrap().then(() => {
          if (!isLoadingEditExchangeRate) {
            formik.resetForm();
            setOpenEdit(false);
            makeToastMessage(t("exchangeRate.editSuccessMessage"));
          }
        })
      } catch(e) {
        makeErrorToastMessage(e.data.message);
      }
    }
  };

  const handleFilter = (values) => {
    setPage(0);
    const formattedValues = {
    ...values,
    OnDate: formatDateForFilter(values.OnDate)
  };
    setFilter(getNonEmptyProps(formattedValues));
  };

  const formik = useFormik({
    initialValues:
      openEdit && !isLoadingExchangeRateDetails ? exchangeRateDetails : exchangeRateInitialValues,
    validationSchema: exchangeRateValidation,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const filterForm = useFormik({
    initialValues: {  CurrencyId: 0, OnDate: null  },
    onSubmit: handleFilter,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleOpenAddExchangeRate = () => {
    setOpenAdd(true);
  };

  const handleCloseBtn = () => {
    if (formik.dirty) {
      setCloseConfirmOpen(true);
    } else {
      if (openAdd) {
        setOpenAdd(false);
        formik.resetForm();
      } else {
        setOpenEdit(false);
        setEditData(null);
      }
    }
  };

  const handleCloseConfirmDialogAdd = () => {
    setOpenAdd(false);
    setCloseConfirmOpen(false);
    formik.resetForm();
  };

  const handleCloseConfirmDialogEdit = () => {
    setOpenEdit(false);
    setCloseConfirmOpen(false);
    formik.resetForm();
    setEditData(null);
  };

  const handleCancel = () => {
    setCloseConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setEditData(null);
    setOpenDelete(false);
  };

  const handleConfirmDelete = () => {
    if (openDelete) {
      deleteExchangeRate({ id: editData.id })
        .unwrap()
        .then(() => {
          if (!isLoadingDeleteExchangeRate) {
            setEditData(null);
            setOpenDelete(false);
            makeToastMessage(t("payment.deleteSuccessMessage"));
          }
        })
        .catch(() => makeErrorToastMessage(t("payment.deleteErrorMessage")));
    }
  };

  return (
    <PageContainer>
      <ConfirmCloseDialog
        open={closeConfirmOpen}
        handleClose={
          openEdit ? handleCloseConfirmDialogEdit : handleCloseConfirmDialogAdd
        }
        handleCancel={handleCancel}
        description={t("payment.confirmClose")}
      />
      <AddExchangeRateModal
        open={openEdit ? openEdit : openAdd}
        formik={formik}
        handleCloseBtn={handleCloseBtn}
        edit={openEdit}
      />
      <ConfirmModal
        open={openDelete}
        handleConfirm={handleConfirmDelete}
        handleCancel={handleCancelDelete}
        confirmLabel={t("common.confirm")}
        cancelLabel={t("common.cancel")}
        description={t("payment.confirmDelete")}
      />
      <Typography variant="h4" color="" style={{marginBottom: 24}}>
          {t("pages.exchangeRate")}
        </Typography>
      <PageHeader>
        <Filter
          filterForm={filterForm}
          handleReset={() => {
            setFilter(null);
            filterForm.resetForm();
          }}
        >
          <ExchangeRateFilter filterForm={filterForm} />
        </Filter>
        <Button variant="contained" onClick={handleOpenAddExchangeRate}>
          {t("exchangeRate.addButton")}
        </Button>
      </PageHeader>
      <CustomTable
        columns={columns}
        data={exchangeRateList?.data}
        isLoading={isLoadingExchangeRateList}
        count={exchangeRateList?.totalCount}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setSearch={setSearch}
        rowsName={t("payment.rowsName")}
        setSort={setSort}
      />
    </PageContainer>
  );
};

export default ExchangeRatePage;
