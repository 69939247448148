import * as Yup from "yup";
import i18next from "i18next";

//const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export default Yup.object().shape({
  name: Yup.string().required(i18next.t("field.nameRequired")),
  surname: Yup.string().required(i18next.t("field.surnameRequired")),
  email: Yup.string().email(i18next.t("field.emailFormat")),
  address: Yup.string().required(i18next.t("field.addressRequired")),
  locationId: Yup.string().required(i18next.t("field.locationIdRequired")),
  countryId: Yup.string().required(i18next.t("field.countryIdRequired")),
  //phoneNumber: Yup.string().matches(phoneRegExp, "neispravan format"),
  dateOfBirth: Yup.date()
    .required(i18next.t("field.dateOfBirthRequired"))
    .typeError(i18next.t("field.dateFormatTypeError")),
  passportNumber: Yup.string(),
  genderId: Yup.string().required(i18next.t("field.genderIdRequired")),
});
