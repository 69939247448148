import * as Yup from "yup";
import i18next from "i18next";

export default Yup.object().shape({
  travelOrganizerId: Yup.string().required(
    i18next.t("field.travelOrganizerIdRequired")
  ),
  categoryId: Yup.string().required(i18next.t("field.categoryIdRequired")),
  transportationTypeId: Yup.string().required(
    i18next.t("field.transportationTypeIdRequired")
  ),
  locationId: Yup.string().required(i18next.t("field.locationIdRequired")),
  countryId: Yup.string().required(i18next.t("field.countryIdRequired")),
  offerTypeId: Yup.string().required(i18next.t("field.offerTypeIdRequired")),
  name: Yup.string().required(i18next.t("field.offerNameRequired")),
  number: Yup.string().required(i18next.t("field.offerNumberRequired")),
  passengers: Yup.array().of(
    Yup.object().shape({
      passengerName: Yup.string().required(
        i18next.t("field.passengerNameRequired")
      ),
      departurePlace: Yup.string().required(
        i18next.t("field.departurePlaceRequired")
      ),
    })
  ),
  calculationService: Yup.string().required(
    i18next.t("field.serviceIdRequired")
  ),
  calculationQuantity: Yup.number().required(
    i18next.t("field.quantityRequired")
  ),
  calculationPriceDin: Yup.number()
    .required(i18next.t("field.calculationPriceDinRequired"))
    .positive(i18next.t("field.positiveNumber"))
    .min(0, i18next.t("field.minNumber")),
  calculationPriceEur: Yup.number()
    .required(i18next.t("field.calculationPriceEurRequired"))
    .positive(i18next.t("field.positiveNumber"))
    .min(0, i18next.t("field.minNumber")),
  calculationSubTotal: Yup.number()
    .required(i18next.t("field.calculationSubTotalRequired"))
    .positive(i18next.t("field.positiveNumber"))
    .min(0, i18next.t("field.minNumber")),
  calculationSaleDin: Yup.number()
    .required(i18next.t("field.calculationSaleDinRequired"))
    .positive(i18next.t("field.positiveNumber"))
    .min(0, i18next.t("field.minNumber")),
  calculationSaleEur: Yup.number()
    .required(i18next.t("field.calculationSaleEurRequired"))
    .positive(i18next.t("field.positiveNumber"))
    .min(0, i18next.t("field.minNumber")),
  calculationTotal: Yup.number()
    .required(i18next.t("field.calculationTotalRequired"))
    .positive(i18next.t("field.positiveNumber"))
    .min(0, i18next.t("field.minNumber")),
  departureDate: Yup.date()
    .required(i18next.t("field.departureDateRequired"))
    .typeError(i18next.t("field.dateFormatTypeError")),
});
