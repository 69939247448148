import * as Yup from "yup";
import i18next from "i18next";

export default Yup.object().shape({
  name: Yup.string().required(i18next.t("field.nameRequired")),
  surname: Yup.string().required(
    i18next.t("field.surnameRequired")
  ),
  email: Yup.string()
    .email(i18next.t("field.emailFormat"))
    .required(i18next.t("field.emailRequired")),
  roleId: Yup.string().required(
    i18next.t("field.roleRequired")
  ),
});
