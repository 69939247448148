import { PAGES } from "./pages";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DescriptionIcon from "@mui/icons-material/Description";
import ReceiptIcon from '@mui/icons-material/Receipt';

export const headerNavigationConstants = [
  PAGES.HOME,
  PAGES.DASHBOARD,
  PAGES.PROFILE,
  PAGES.SETTINGS,
];

export const homePageNavigationLinks = [
  {
    title: PAGES.CLIENTS.title,
    path: PAGES.CLIENTS.route,
    PTP: true,
    icon: PeopleAltIcon
  },
  {
    title: PAGES.OFFERS.title,
    path: PAGES.OFFERS.route,
    PTP: false,
    icon: LocalOfferIcon
  },
  {
    title: PAGES.SALES.title,
    path: PAGES.SALES.route,
    PTP: true,
    icon: ShoppingCartIcon
  },
  {
    title: PAGES.PAYMENT.title,
    path: PAGES.PAYMENT.route,
    PTP: false,
    icon: AttachMoneyIcon
  },
  {
    title: PAGES.EXCERPTS.title,
    path: PAGES.EXCERPTS.route,
    PTP: true,
    icon: DescriptionIcon
  },
  {
    title: PAGES.RECEIPTS.title,
    path: PAGES.RECEIPTS.route,
    PTP: true,
    icon: ReceiptIcon
  }
]
