import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import i18next from "i18next";
import AppRoutes from "./AppRoutes";
import { useTranslation } from "react-i18next";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { StyledEngineProvider } from "@mui/material";
import { authScopeStringGetHelper } from "util/authScopeHelpers";
import { LANGUAGE } from "constants/localStorage";
import "./App.css";

const App = () => {
  const { i18n } = useTranslation();

  // const token = useSelector(selectCurrentToken);
  // const { isMobile } = useDevice();


  useEffect(() => {
    const lang = authScopeStringGetHelper(LANGUAGE);

    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{i18next.t("app.title")}</title>
      </Helmet>
      <StyledEngineProvider injectFirst>
        <ToastContainer bodyClassName="ToastBody" />
        {/* {token && !isMobile && <Header />} */}
        <AppRoutes />
      </StyledEngineProvider>
    </>
  );
};

export default App;
