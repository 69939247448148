/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";
import { useCountryDropdownQuery } from "features/country/countryApiSlice";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";

const LocationFilter = ({ filterForm }) => {
  const { t } = useTranslation();
  const { data: countries } = useCountryDropdownQuery();
  return (
    <Box component="form">
      <RowContainer></RowContainer>
      <RowContainer>
        <SelectInput
          name="CountryId"
          label={t("field.countryPlaceholder")}
          onChange={filterForm.handleChange}
          value={filterForm.values.CountryId}
          fullWidth
          style={{ marginRight: "24px" }}
          error={
            filterForm.touched.CountryId && Boolean(filterForm.errors.CountryId)
          }
          helperText={
            filterForm.touched.CountryId && filterForm.errors.CountryId
          }
          items={countries}
          selectPlaceholder={t("dropdown.country")}
        />
        <TextInput
          fullWidth
          name="Name"
          label={t("field.locationPlaceholder")}
          style={{ marginRight: 24 }}
          onChange={filterForm.handleChange}
          value={filterForm.values.Name}
        />
      </RowContainer>
    </Box>
  );
};

export default LocationFilter;
