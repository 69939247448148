import { apiSlice } from "features/api/apiSlice";

export const accommodationUnitTypeApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["AccommodationUnitType", "AccommodationUnitTypeSingle"],
  endpoints: (builder) => ({
    accommodationUnitTypeDropdown: builder.query({
      query: () => ({
        url: "/accommodation-unit-type/dropdown",
      }),
      providesTags: ["AccommodationUnitType"],
    }),
    accommodationUnitTypeList: builder.query({
      query: (args) => {
        const { page, rowsPerPage, search, sort } = args;
        return {
          url:
            "/accommodation-unit-type/list?" +
            new URLSearchParams({
              PageNumber: page + 1,
              RowsPerPage: rowsPerPage,
              Active: true,
              // Conditionally add to params object if keys exist
              ...(sort && { SortPropertyName: sort.name, Ascending: sort.direction === 'asc' ? true : false }),
              ...(search && { Name: search }),
              //   ...(sortDirection && { orderBy: sortDirection }),
            }),
        };
      },
      providesTags: ["AccommodationUnitType"],
    }),
    accommodationUnitTypeDetails: builder.query({
      query: ({ id }) => ({
        url: `/accommodation-unit-type/${id}`,
      }),
      providesTags: ["AccommodationUnitTypeSingle"],
    }),
    addAccommodationUnitType: builder.mutation({
      query: (data) => ({
        url: "/accommodation-unit-type/add",
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["AccommodationUnitType"],
    }),
    editAccommodationUnitType: builder.mutation({
      query: (data) => ({
        url: "/accommodation-unit-type/edit",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["AccommodationUnitType", "AccommodationUnitTypeSingle"],
    }),
    deleteAccommodationUnitType: builder.mutation({
      query: (data) => ({
        url: "/accommodation-unit-type",
        method: "DELETE",
        body: { ...data },
      }),
      invalidatesTags: ["AccommodationUnitType"],
    }),
  }),
});

export const {
  useAccommodationUnitTypeDropdownQuery,
  useAccommodationUnitTypeListQuery,
  useAccommodationUnitTypeDetailsQuery,
  useAddAccommodationUnitTypeMutation,
  useEditAccommodationUnitTypeMutation,
  useDeleteAccommodationUnitTypeMutation,
} = accommodationUnitTypeApiSlice;
