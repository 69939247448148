/* eslint-disable react/prop-types */
import { Box } from "@mui/material";
import SelectInput from "components/Inputs/SelectInput";
import React from "react";
import { useTranslation } from "react-i18next";
import { RowContainer } from "styles/SharedStyle.styled";
import DatePick from "components/Inputs/DatePick";

const ContractFilter = ({ filterForm }) => {
  const { t } = useTranslation();

  return (
    <Box component="form">
      <RowContainer>
        <SelectInput
          name="Number"
          label={t("field.contractNumberPlaceholder")}
          onChange={filterForm.handleChange}
          fullWidth
          value={filterForm.values.Number}
          items={[]}
          style={{ marginRight: "24px" }}
        />
        <DatePick
          name="OnDate"
          label={t("field.travelDatePlaceholder")}
          selected={
            filterForm.values.OnDate ? new Date(filterForm.values.OnDate) : null
          }
          value={filterForm.values.OnDate}
          onChange={(value) => filterForm.setFieldValue("OnDate", value)}
        />
      </RowContainer>
      <RowContainer>
        <SelectInput
          name="ClientId"
          label={t("field.clientSurnamePlaceholder")}
          onChange={filterForm.handleChange}
          fullWidth
          value={filterForm.values.ClientId}
          items={[]}
          style={{ marginRight: "24px" }}
        />
        <SelectInput
          name="TravelOrganizerId"
          label={t("field.travelOrganizerPlaceholder")}
          onChange={filterForm.handleChange}
          fullWidth
          value={filterForm.values.TravelOrganizerId}
          items={[]}
        />
      </RowContainer>
    </Box>
  );
};

export default ContractFilter;
